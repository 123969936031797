import React from "react";
import {Button, Col, Form, FormGroup} from "reactstrap";
import {User} from "../../../../services/users";
import {allDigits, allLetters, isEmail, isRequired, maxLength, minLength,} from "../../../ui/form/fields/validators";
import {PageTitle} from "../../../ui/layout/PageTitle";
import {Formik, FormikProps} from "formik";
import {RadioInputRow, TextInputRow} from "../../../ui/form/fields/input/Input";

export type UserDataFormValues = Omit<User, 'password' | 'roles' | 'dbRole'>;

export type UserDataFormProps = {
  onSubmit: (values: UserDataFormValues) => void;
  user?: UserDataFormValues;
}

export const UserDataForm: React.VFC<UserDataFormProps> = ({onSubmit, user}) => {
  const genders = [
    {label: "Femmina", value: "F"},
    {label: "Maschio", value: "M"},
  ];

  const defaultValues = user || {name: '', surname: '', email: ''};

  return (
    <Formik
      enableReinitialize={true}
      initialValues={defaultValues}
      onSubmit={onSubmit}
      validate={validate}
      validateOnChange={true}
    >
      {(props: FormikProps<UserDataFormValues>) => (
        <Form onSubmit={props.handleSubmit}>
          <FormGroup>
            <PageTitle>Dati personali</PageTitle>
          </FormGroup>
          <FormGroup row>
            <TextInputRow label={'Nome'} name='name' maxLength={16} required={true}/>
            <TextInputRow label={'Cognome'} name='surname' maxLength={16} required={true}/>
          </FormGroup>

          <FormGroup row>
            <TextInputRow label={'Codice Fiscale'} name='fiscalCode' maxLength={16}/>
            <RadioInputRow label={'Sesso'} name='gender' values={genders}/>
          </FormGroup>

          <FormGroup row>
            <TextInputRow label={'Data nascita'} name='birthday' type="date" />
            <TextInputRow label={'Luogo nascita'} name='birthplace' maxLength={16} />
          </FormGroup>

          <FormGroup row>
            <TextInputRow label={'Telefono'} name='telephone' maxLength={16} />
            <TextInputRow label={'E-Mail'} name='email' maxLength={40} required={true} />
          </FormGroup>

          <FormGroup row>
            <TextInputRow label={'Indirizzo'} name='address' maxLength={16} />
            <TextInputRow label={'Città'} name='city' maxLength={16} />
          </FormGroup>

          <FormGroup row>
            <TextInputRow label={'Codice postale'} name='postalCode' maxLength={5} />
            <TextInputRow label={'Provincia'} name='province' maxLength={2} />
          </FormGroup>

          <FormGroup className={"d-flex justify-content-end"}>
            <Col sm={"auto"} className={"p-0"}>
              <Button
                disabled={!props.dirty || props.isSubmitting || !props.isValid}
                color={"primary"}
                type={"submit"}
              >
                Salva
              </Button>
            </Col>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

const validate = (user: UserDataFormValues) => {
  const errors = {};

  isRequired(user, "name", errors);
  minLength(user, "name", 2, errors);
  maxLength(user, "name", 16, errors);

  isRequired(user, "surname", errors);
  minLength(user, "surname", 2, errors);
  maxLength(user, "surname", 16, errors);

  minLength(user, "fiscalCode", 16, errors);
  maxLength(user, "fiscalCode", 16, errors);

  isRequired(user, "email", errors);
  isEmail(user, "email", errors);

  allDigits(user, "telephone", errors);

  allDigits(user, "postalCode", errors);
  minLength(user, "postalCode", 5, errors);
  maxLength(user, "postalCode", 5, errors);

  allLetters(user, "province", errors);
  minLength(user, "province", 2, errors);
  maxLength(user, "province", 2, errors);

  return errors;
};

