import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faCircle,
  faHome,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useInterval, useResizeObserver} from "beautiful-react-hooks";
import classNames from "classnames";
import add from "date-fns/add";
import React, {
  CSSProperties,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {Button} from "reactstrap";
import {Resource} from "../../../services/resources/resource";
import {CELL_HEIGHT} from "./consts";
import styles from "./Scheduler.module.scss";
import {SchedulerEvent} from "./SchedulerEvent";
import {SchedulerHeading} from "./SchedulerHeading";
import {
  endOfPeriod,
  getTimeSpanUnits,
  minCellWidth,
  startOfPeriod,
  unitDuration,
} from "./shared";
import {Event, Period, Scale} from "./types";

interface SchedulerProps<E> {
  events?: Event<E>[];
  onEventClick?: (event: Event<E>) => void;
  onPeriodChange?: (ends: {startDate: Date; endDate: Date}) => void;
  period: Period;
  resources: Resource[];
  scale: Scale;
}

const getNowPosition = (startDate: Date, endDate: Date) => {
  const now = new Date();
  const total = endDate.getTime() - startDate.getTime();
  const current = now.getTime() - startDate.getTime();

  return current / total >= 0 && current / total < 1
    ? current / total
    : undefined;
};

function usePeriod(period: Period) {
  const [periodEnds, setPeriodEnds] = useState({
    startDate: startOfPeriod[period](),
    endDate: endOfPeriod[period](),
  });

  const methods = useMemo(
    () => ({
      nextPeriod: () => {
        setPeriodEnds((prev) => ({
          startDate: startOfPeriod[period](
            add(prev.startDate, {[unitDuration[period]]: 1})
          ),
          endDate: endOfPeriod[period](
            add(prev.endDate, {[unitDuration[period]]: 1})
          ),
        }));
      },
      prevPeriod: () => {
        setPeriodEnds((prev) => ({
          startDate: startOfPeriod[period](
            add(prev.startDate, {[unitDuration[period]]: -1})
          ),
          endDate: endOfPeriod[period](
            add(prev.endDate, {[unitDuration[period]]: -1})
          ),
        }));
      },
      resetPeriod: () => {
        setPeriodEnds({
          startDate: startOfPeriod[period](),
          endDate: endOfPeriod[period](),
        });
      },
    }),
    [period]
  );

  return [periodEnds, methods] as const;
}

export const Scheduler = <E,>({
  events,
  onEventClick,
  onPeriodChange,
  period,
  resources,
  scale,
}: SchedulerProps<E>) => {
  const [{startDate, endDate}, methods] = usePeriod(period);
  const [, setScrollLeft] = useState(0);

  const [nowPosition, setNowPosition] = React.useState(
    getNowPosition(startDate, endDate)
  );
  const timeSpanUnits = getTimeSpanUnits(startDate, endDate, scale);

  useInterval(() => {
    setNowPosition(getNowPosition(startDate, endDate));
  }, 1000 * 60);
  useEffect(() => {
    setNowPosition(getNowPosition(startDate, endDate));
  }, [startDate, endDate]);

  const wrapperStyles = {
    "--min-cell-width": minCellWidth[scale] + "px",
    "--cell-height": CELL_HEIGHT + "px",
  } as CSSProperties;
  const mainGridStyles = {
    gridTemplateColumns: `repeat(${timeSpanUnits}, 1fr)`,
  };

  const ref = useRef<HTMLDivElement>(null);
  const DOMRect = useResizeObserver(ref);
  const cellWidth = Math.max(
    minCellWidth[scale],
    (DOMRect?.width ?? 0) / timeSpanUnits
  );

  useEffect(() => {
    onPeriodChange?.({startDate, endDate});
  }, [startDate, endDate, onPeriodChange]);

  return (
    <>
      <div className={styles.navigation}>
        <Button color="link" className="p-0" onClick={methods.prevPeriod}>
          <FontAwesomeIcon
            icon={faArrowCircleLeft}
            size="2x"
            className="d-block"
          />
        </Button>
        <Button color="link" className="p-0" onClick={methods.resetPeriod}>
          <span className="fa-layers fa-fw fa-2x">
            <FontAwesomeIcon icon={faCircle} />
            <FontAwesomeIcon icon={faHome} transform="shrink-6" inverse />
          </span>
        </Button>
        <Button color="link" className="p-0" onClick={methods.nextPeriod}>
          <FontAwesomeIcon
            icon={faArrowCircleRight}
            size="2x"
            className="d-block"
          />
        </Button>
      </div>

      <div className={styles.wrapper} style={wrapperStyles}>
        <div className={styles.resources}>
          <div className={styles.heading} />
          {resources.map(({name}) => (
            <div key={name}>{name}</div>
          ))}
        </div>
        <div
          className={styles.mainGrid}
          style={mainGridStyles}
          ref={ref}
          onScroll={() => {
            setScrollLeft(ref.current?.scrollLeft ?? 0);
          }}
        >
          {DOMRect?.width && (
            <div
              id="now"
              className={styles.now}
              style={
                {
                  "--now-position": `${
                    (nowPosition ?? 0) * cellWidth * timeSpanUnits
                  }px`,
                  display: nowPosition ? "block" : "none",
                } as CSSProperties
              }
            />
          )}
          <SchedulerHeading
            startDate={startDate}
            endDate={endDate}
            scale={scale}
            mainGridRef={ref}
          />
          {resources.map((_, rowIndex) => (
            <Fragment key={rowIndex}>
              {Array.from(Array(timeSpanUnits)).map((_, index) => (
                <div
                  key={index}
                  className={classNames(styles.singleCell, {
                    [styles.today]:
                      nowPosition &&
                      Math.floor(nowPosition * timeSpanUnits) === index,
                  })}
                />
              ))}
            </Fragment>
          ))}
          {DOMRect?.width &&
            events &&
            events.map((event) => (
              <SchedulerEvent
                cellWidth={cellWidth}
                event={event}
                key={event.id}
                onEventClick={onEventClick}
                resources={resources}
                scale={scale}
                schedulerWidth={cellWidth * timeSpanUnits}
                startDate={startDate}
                endDate={endDate}
              />
            ))}
        </div>
      </div>
    </>
  );
};
