import {DbRole, Role, UserAuthentication, UserId} from "../authentication/userAuthentication";
import {User} from "./user";
import {api, Tags} from "../api";

export type UsersPage = {
  users: User[];
  pageNumber: number;
  total: number;
  pageSize: number;
};


const usersApi = api.injectEndpoints({

  endpoints: (build) => ({

    currentUser: build.query<User, void>({
      query: () => ({
        url: `/users/me`,
        method: 'GET'
      }),
      providesTags: (user) => [
        {type: Tags.user, id: user?.id},
        {type: Tags.account, id: user?.id},
        Tags.currentUser],
    }),

    getUser: build.query<User, number>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, id) =>
        [{type: Tags.user, id}, {type: Tags.account, id}],
    }),

    getAllUsers: build.query<User[], void>({
      query: () => ({
        url: `/users`,
        method: 'GET'
      }),
      providesTags: [{type: Tags.user, id: 'LIST'}],
    }),

    getUsersByRole: build.query<User[], Role>({
      query: (role) => ({
        url: `/users?role=${role}`,
        method: 'GET'
      }),
    }),

    usersPage: build.query<UsersPage, { pageNumber: number, pageSize: number, sort: { field: string; order: "asc" | "desc" }[] }>({
        query: ({pageNumber, pageSize, sort}) => ({
          url: `/users/page?page=${pageNumber - 1}&size=${pageSize}&` +
            sort.map(s => `sort=${s.field},${s.order}`).join('&'),
          method: 'GET'
        }),
        providesTags: [{type: Tags.user, id: 'LIST'}],
        transformResponse: (response: {
          content: User[];
          number: number;
          totalElements: number;
          size: number;
        }) => ({
          users: response.content,
          pageNumber: response.number,
          pageSize: response.size,
          total: response.totalElements,
        } as UsersPage)
      },
    ),

    createUser: build.mutation<number, UserAuthentication>({
      query: (user) => ({
        url: `/users`,
        method: 'POST',
        body: user
      }),
    }),

    updateUser: build.mutation<void, User>({
      query: (user) => ({
        url: `/users/${user.id}`,
        method: 'PUT',
        body: user
      }),
      invalidatesTags: (result, error, {id}) => [{type: Tags.user, id}],
    }),

    updateUserPassword: build.mutation<void, { id: UserId, password: string }>({
      query: ({id, password}) => ({
        url: `/users/${id}/password`,
        method: 'PUT',
        body: {password}
      })
    }),

    updateUserRoles: build.mutation<void, { id: UserId, roles: Role[] }>({
      query: ({id, roles}) => ({
        url: `/users/${id}/roles`,
        method: 'PUT',
        body: {roles}
      }),
      invalidatesTags: (result, error, {id}) => [{type: Tags.account, id}],
    }),

    updateDbRole: build.mutation<void, { id: UserId, role: DbRole }>({
      query: ({id, role}) => ({
        url: `/users/${id}/dbRole`,
        method: 'PUT',
        body: {role}
      }),
      invalidatesTags: (result, error, {id}) => [{type: Tags.account, id}],
    }),

    createUserAccount: build.mutation<void, { id: UserId, password: string, roles: Role[] }>({
      query: ({id, password, roles}) => ({
        url: `/users/${id}/account`,
        method: 'POST',
        body: {password, roles}
      }),
      invalidatesTags: (result, error, {id}) => [{type: Tags.account, id}],
    }),

    userHasAccount: build.query<boolean, number>({
      query: (id) => ({
        url: `/users/${id}/account`,
        method: 'HEAD'
      }),
      transformResponse: (response, meta) =>
        meta?.response?.status === 200,
      providesTags: (_result, _error, id) => [{type: Tags.account, id}, Tags.currentUser],
    }),

  }),
  overrideExisting: false,
});

export const {
  useCurrentUserQuery,
  useLazyCurrentUserQuery,
  useGetAllUsersQuery,
  useGetUsersByRoleQuery,
  useGetUserQuery,
  useCreateUserAccountMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
  useUserHasAccountQuery,
  useUsersPageQuery,
  useUpdateUserRolesMutation,
  useUpdateDbRoleMutation,
} = usersApi;
