import {faInfoCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Alert, Card} from "reactstrap";
import {useQuery} from "../../../helpers/routeConfig";
import {
  hydrateReservation,
  Reservation,
  ResourceReservationState,
} from "../../../services/resources/reservation";
import {
  useEndResourceReservationMutation,
  useGetActiveResourceReservationQuery,
  useGetCalendarQuery,
  useStartResourceReservationMutation,
} from "../../../services/resources/reservations-api";
import {
  ResourceId,
  translateCategory,
} from "../../../services/resources/resource";
import {PageTitle} from "../../ui/layout/PageTitle";
import {SortEntry} from "../../ui/table/Table";
import {StartReservationDialog} from "./start-reservation-dialog";
import {useAppDispatch} from "../../../helpers/store";
import {errorAlert, successAlert} from "../../ui/alerts/reducer";
import {EndReservationDialog} from "./end-reservation-dialog";
import {ReservationsTable} from "./components/reservations-table";
import {addDays} from "../../../services/date";
import {formatISO} from "date-fns";
import {
  ConfirmLongDistanceReservationDialog,
  isReservationDistanceOverAlertThreshold
} from "./confirm-long-distance-reservation-dialog";

export type ResourcesListProps = {};

export const useStartResourceReservation = (callback: () => void) => {
  const dispatch = useAppDispatch();

  const [update] = useStartResourceReservationMutation();
  return (reservation: Reservation, newDistance: number) =>
    update({reservation, distance: newDistance})
      .unwrap()
      .then(() => {
        dispatch(successAlert(`Corsa iniziata`));
        callback();
      })
      .catch((e) =>
        dispatch(errorAlert(`Errore durante l'apertura della corsa: ${e}`))
      );
};

export const useEndResourceReservation = (callback: () => void) => {
  const dispatch = useAppDispatch();

  const [update] = useEndResourceReservationMutation();
  return (reservation: Reservation, newDistance: number, message?: string) =>
    update({reservation, distance: newDistance, message})
      .unwrap()
      .then(() => {
        dispatch(successAlert(`Corsa terminata`));
        callback();
      })
      .catch((e) => dispatch(errorAlert(`Errore durante la chiusura: ${e}`)));
};

const useActiveReservation = (resourceId?: ResourceId) => {
  const {data: reservation} = useGetActiveResourceReservationQuery(
    resourceId ?? -1,
    {skip: !resourceId}
  );
  return reservation ? hydrateReservation(reservation) : undefined;
};

export const ResourceReservationsList: React.VFC<ResourcesListProps> = () => {
  const resourceParam = Number.parseInt(useQuery().get("resource") ?? "");
  const [selectedResourceId, setSelectedResourceId] = useState(
    Number.isNaN(resourceParam) ? undefined : resourceParam
  );

  const selectedActiveReservation = useActiveReservation(selectedResourceId);

  const [showStartReservationDialog, setShowStartReservationDialog] =
    useState<Reservation>();
  const [showEndReservationDialog, setShowEndReservationDialog] =
    useState<Reservation>();
  const [showConfirmReservationDistanceDialog, setshowConfirmReservationDistanceDialog] =
    useState<{reservation: Reservation, distance: number, message?: string}>();

  const startResourceReservation = useStartResourceReservation(() =>
    setShowStartReservationDialog(undefined)
  );
  const endResourceReservation = useEndResourceReservation(() => {
      setShowEndReservationDialog(undefined);
      setshowConfirmReservationDistanceDialog(undefined)
  });

  useEffect(() => {
    if (
      selectedResourceId &&
      selectedActiveReservation &&
      !selectedActiveReservation.startedAt &&
      !showStartReservationDialog
    ) {
      setShowStartReservationDialog(selectedActiveReservation);
      setSelectedResourceId(undefined);
    }
  }, [
    selectedActiveReservation,
    setShowStartReservationDialog,
    showStartReservationDialog,
    selectedResourceId,
    setSelectedResourceId,
  ]);

  useEffect(() => {
    if (
      selectedResourceId &&
      selectedActiveReservation &&
      selectedActiveReservation.startedAt &&
      !selectedActiveReservation.endedAt &&
      !showEndReservationDialog
    ) {
      setShowEndReservationDialog(selectedActiveReservation);
      setSelectedResourceId(undefined);
    }
  }, [
    selectedActiveReservation,
    setShowEndReservationDialog,
    showEndReservationDialog,
    selectedResourceId,
    setSelectedResourceId,
  ]);

  const [settings, setSettings] = useState({
    sort: [{field: "resource.name", order: "asc"} as SortEntry],
  });

  const sort = (s: any) => setSettings({sort: s});

  return (
    <Card body>
      <PageTitle>Le mie prenotazioni</PageTitle>

      <h4>Prenotazioni in corso</h4>
      <ReservationsTable
        states={[
          ResourceReservationState.Present,
          ResourceReservationState.InProgress,
        ]}
        reservationActions={true}
        startReservation={(reservation) =>
          setShowStartReservationDialog(reservation)
        }
        endReservation={(reservation) =>
          setShowEndReservationDialog(reservation)
        }
      />
      <h4 className="mt-5">Le mie future prenotazioni</h4>
      <ReservationsTable states={[ResourceReservationState.Future]} />
      <h4 className="mt-5">Le mie prenotazioni concluse</h4>
      <ReservationsTable states={[ResourceReservationState.Past]} />

      {showStartReservationDialog ? (
        <StartReservationDialog
          reservation={showStartReservationDialog}
          onCancel={() => setShowStartReservationDialog(undefined)}
          onConfirm={(distance) =>
            startResourceReservation(showStartReservationDialog, distance)
          }
        />
      ) : null}
      {showEndReservationDialog ? (
        <EndReservationDialog
          reservation={showEndReservationDialog}
          onCancel={() => setShowEndReservationDialog(undefined)}
          onConfirm={(distance, message) => {
              if (isReservationDistanceOverAlertThreshold(showEndReservationDialog, distance))
                setshowConfirmReservationDistanceDialog({
                  reservation: showEndReservationDialog,
                  distance: distance,
                  message: message
                });
              else
                return endResourceReservation(showEndReservationDialog, distance, message)
            }
          }
        />
      ) : null}
      {showConfirmReservationDistanceDialog ? (
        <ConfirmLongDistanceReservationDialog
          reservation={showConfirmReservationDistanceDialog.reservation}
          distance={showConfirmReservationDistanceDialog.distance}
          onCancel={() => {
            setshowConfirmReservationDistanceDialog(undefined);
          }}
          onConfirm={() =>
            endResourceReservation(
              showConfirmReservationDistanceDialog!.reservation,
              showConfirmReservationDistanceDialog!.distance,
              showConfirmReservationDistanceDialog!.message
            )
          }
        />
      ) : null}
    </Card>
  );
};
