import React, {VFC} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink as RRNAvLink} from "react-router-dom";
import {Navbar, NavbarBrand} from "reactstrap";
import {useMediaUp} from "../../../helpers/useMediaUp";
import logo from "../../../img/logo-white.png";
import styles from "./nav-bar/NavBar.module.scss";
import {NavBarMenu} from "./nav-bar/NavBarMenu";
import {NavbarToggler} from "./nav-bar/NavbarToggler";
import {actions, selectors} from "./slice";

export const NavBar: VFC = () => {
  const dispatch = useDispatch();
  const isSmUp = useMediaUp("sm");
  const isOpen = useSelector(selectors.selectIsNavOpen);

  const toggleMenu = () => dispatch(actions.toggleNav());

  return (
    <Navbar dark fixed="top" expand="sm" className={styles.navBar}>
      <NavbarBrand tag={RRNAvLink} to="/" className={styles.navbarBrand}>
        <img
          src={logo}
          height="30"
          alt="Il Calabrone logo"
          className="d-block"
        />
      </NavbarBrand>
      <NavbarToggler isOpen={isOpen} onClick={toggleMenu}/>
      {isSmUp && <NavBarMenu className="ml-auto"/>}
    </Navbar>
  );
};
