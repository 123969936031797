import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {Reservation} from "../../../services/resources/reservation";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";
import classNames from "classnames";
import {Message} from "primereact/message";

export type EndReservationDialogProps = {
  reservation: Reservation;
  onCancel: () => void;
  onConfirm: (distance: number, message?: string) => void;
};

export const EndReservationDialog: React.VFC<EndReservationDialogProps> =
  ({reservation, onCancel, onConfirm}) => {

    const [distance, setDistance] = useState<number>();
    const [message, setMessage] = useState<string>();

    const disabled = !distance || distance < reservation.resource.km;

    const formError = distance && distance < reservation.resource.km;

    const Footer = () => <div>
      <Button label="Annulla" icon="pi pi-times" onClick={onCancel} className="p-button-danger"/>
      <Button label="Conferma chiusura" icon="pi pi-check" onClick={() => distance && onConfirm(distance, message)}
              disabled={disabled}
              className="p-button-success"/>
    </div>;

    return <Dialog header="Chiusura della corsa" visible={true} onHide={onCancel} footer={Footer} className={"text-center"}>
        <p>
          Inserisci i Km del veicolo {reservation.resource.name}:
        </p>
        <InputText type={"number"} value={distance}
                   className={classNames({['p-invalid']: formError})}
                   onChange={e => setDistance(Number(e.target.value))}/>

        {formError ? <Message className={'mt-2 block'} severity={'error'}
                              text={'I Km inseriti sono inferiori a quelli attualmente registrati sul veicolo.'}
        ></Message> : null}

        <p className={"mt-4"}>
          Se devi fare una segnalazione inserisci il messaggio <br/>
          all'interno della casella di testo prima di confermare <br/>
          la chiusura della corsa:
        </p>
        <InputTextarea value={message}
                       onChange={e => setMessage(e.target.value)}/>
    </Dialog>;
  };
