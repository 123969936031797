import React from "react";
import {Pagination as BootstrapPagination, PaginationItem, PaginationLink,} from "reactstrap";
import styles from "./Pagination.module.scss";
import {Link} from "react-router-dom";
import {ConfigRoute} from "../../../helpers/routeConfig";

export type PaginationProps = {
  currentPage?: number;
  totalPages: number;
  displayPages?: number;
  readonly baseRoute?: ConfigRoute;
};

export const Pagination: React.FC<PaginationProps> = ({
                                                        currentPage,
                                                        totalPages,
                                                        displayPages,
                                                        baseRoute,
                                                      }) => {
  const pages = Array.from(
    new Array(displayPages).keys(),
    (x: number) => currentPage! + x - Math.floor(displayPages! / 2)
  ).filter((x) => x > 0 && x <= totalPages);
  const previous = currentPage! - 1;
  const next = currentPage! + 1;

  const hasPrevious = previous > 0;
  const hasNext = next <= totalPages;

  return (
    <BootstrapPagination className={styles.pagination}>
      <PaginationItem key={"first"} disabled={!hasPrevious}>
        <PaginationLink
          className={styles.link}
          previous
          tag={Link}
          authorizations={baseRoute?.roles}
          to={baseRoute?.route({}, {page: previous})}
        />
      </PaginationItem>
      {pages.map((x) => (
        <PaginationItem key={x} active={currentPage === x}>
          <PaginationLink
            className={styles.link}
            authorizations={baseRoute?.roles}
            to={baseRoute?.route({}, {page: x})}
            tag={Link}
          >
            {x}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem key={"last"} disabled={!hasNext}>
        <PaginationLink
          className={styles.link}
          next
          tag={Link}
          authorizations={baseRoute?.roles}
          to={baseRoute?.route({}, {page: next})}
        />
      </PaginationItem>
    </BootstrapPagination>
  );
};

Pagination.defaultProps = {
  currentPage: 1,
  displayPages: 3,
};
