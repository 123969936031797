import {faCheck, faSquareFull, faTimes} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {Card, Col, ListGroup, Row} from "reactstrap";
import {useAppDispatch} from "../../../helpers/store";
import {toLocalDate} from "../../../services/date";
import {
  Configuration,
  Reservation,
  ReservationId,
} from "../../../services/mealReservation";
import {ReservationStatus} from "../../../services/mealReservation/reservation";
import {actions as alertActions} from "../../ui/alerts";
import {Calendar} from "../../ui/calendar/Calendar";
import {PageTitle} from "../../ui/layout/PageTitle";
import {SectionTitle} from "../../ui/layout/SectionTitle";
import {ReservationConfirm} from "./ReservationConfirm";
import {ReservationsViewCell} from "./ReservationsViewCell";
import {
  useConfirmReservationMutation,
  useGetReservationsByDateQuery
} from "../../../services/mealReservation/reservationService";
import {useGetConfigurationsBetweenDatesQuery} from "../../../services/mealReservation/configurationService";
import {isAuthorizedSelector} from "../../login/selectors";
import {useSelector} from "react-redux";

function addDays(date: Date, days: number) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
}

const useGetReservations = (date: Date) => {
  const {data: reservations} = useGetReservationsByDateQuery(toLocalDate(date));
  return reservations;
};

const useGetConfigurations = (date: Date) => {
  const {data: configurations} = useGetConfigurationsBetweenDatesQuery({
    from: toLocalDate(date), to: toLocalDate(date)
  });
  return new Map<string, Configuration>(Object.entries(configurations ?? {}))
    .get(toLocalDate(date)) as Configuration;
}

const useConfirmReservationStatus = () => {
  const [confirm] = useConfirmReservationMutation();
  const dispatch = useAppDispatch();

  return confirm && ((id: ReservationId, s: ReservationStatus) => confirm({id, confirm: s}).unwrap()
    .catch(error => dispatch(alertActions.errorAlert(`Errore durante la conferma della prenotazione: ${error.data.message}`))));
};

export type ReservationsViewProps = {
  rows?: number;
  view?: "week" | "day";
};

function isWeekend(date: Date) {
  return date.getDay() === 6 || date.getDay() === 0;
}

export const ReservationsView: React.FC<ReservationsViewProps> = ({rows = 3, view = 'week'}) => {
  const daysPerRow = view === "week" ? 7 : 1;
  const today = new Date();

  const [startingDate, setStartingDate] = useState(today);
  const [calendar] = useState(new Map<string, boolean>());
  const [selectedDate, setSelectedDate] = useState(today);

  const reservations = useGetReservations(selectedDate);

  const moveCalendar = (offset: number) => {
    setStartingDate(addDays(startingDate, offset));
  };

  const configuration = useGetConfigurations(selectedDate);

  const confirmReservation = useConfirmReservationStatus();

  const removeConfirmation = (id: ReservationId) => (() => confirmReservation(id, ReservationStatus.TO_CONFIRM));

  const confirmPresence = (id: ReservationId) => (() => confirmReservation(id, ReservationStatus.CONFIRMED));

  const confirmAbsence = (id: ReservationId) => (() => confirmReservation(id, ReservationStatus.ABSENT));

  const isAuthorized = useSelector(isAuthorizedSelector);

  const reservationsMap = new Map<string, Reservation[]>(
    configuration?.slots &&
      configuration.slots.map((slot) => [
        slot.slot,
        reservations?.filter(
          (reservation) => reservation.reservationSlot === slot.slot
        ) ?? [],
      ])
  );

  return (
    <Card body>
      { isAuthorized ? <>
      <PageTitle>Prenotazione mensa</PageTitle>

      <Row>
        <Col>
          <p>
            Seleziona la data desiderata per visualizzare l'elenco delle
            prenotazioni.
          </p>
        </Col>
        <Col className="text-right mb-4">
          Oggi:{" "}
          <FontAwesomeIcon
            icon={faSquareFull}
            className="text-primary border"
          />
          <br />
          Selezionato:{" "}
          <FontAwesomeIcon
            icon={faSquareFull}
            className="text-secondary border"
          />
        </Col>
      </Row>

      <Calendar
        from={startingDate}
        data={calendar}
        rows={rows}
        daysPerRow={daysPerRow}
        onSelect={setSelectedDate}
        selectedDate={selectedDate}
        previous={() => moveCalendar(-7)}
        next={() => moveCalendar(7)}
        cell={ReservationsViewCell}
        disableCell={isWeekend}
      />
</> : null}
      <PageTitle mt>
        Dettaglio prenotazioni per il{" "}
        {selectedDate.toLocaleDateString("it-IT", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </PageTitle>

      { isAuthorized
        ? <p>
          Qui sotto sono elencate le prenotazioni per il giorno selezionato.
          <br />
          Per i giorni passai è possibile confermare la presenza o l'assenza dei
          commensali cliccando sui relativi bottoni{" "}
          <FontAwesomeIcon icon={faCheck} /> e <FontAwesomeIcon icon={faTimes} />.
          </p>
        : <p>
          Qui sotto sono elencate le prenotazioni di oggi.
          </p>
      }
      <Row>
        {Array.from(reservationsMap.entries()).map(
          ([slot, slotReservations]) => {
            const totalSeats = slotReservations.reduce(
              (total, slotReservations) => total + slotReservations.seats,
              0
            );

            return (
              <Col key={slot} xs={12} sm={12} md={6} lg={6}>
                <Row>
                  <Col>
                    <SectionTitle mt>
                      Pasto delle {slot}: {totalSeats}{" "}
                      {totalSeats === 1 ? "posto prenotato" : "posti prenotati"}
                    </SectionTitle>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <ListGroup>
                      {slotReservations.map((reservation) => (
                        <ReservationConfirm
                          key={reservation.id}
                          reservation={reservation}
                          disabled={!isAuthorized || selectedDate > new Date()}
                          handleConfirm={
                            reservation.confirmed ===
                            ReservationStatus.CONFIRMED
                              ? removeConfirmation(reservation.id!)
                              : confirmPresence(reservation.id!)
                          }
                          handleRemove={
                            reservation.confirmed === ReservationStatus.ABSENT
                              ? removeConfirmation(reservation.id!)
                              : confirmAbsence(reservation.id!)
                          }
                        />
                      ))}
                    </ListGroup>
                  </Col>
                </Row>
              </Col>
            );
          }
        )}
      </Row>
    </Card>
  );
};
