import React from "react";
import csx from "classnames";
import {Spinner} from "reactstrap";
import styles from "./LoadingOverlay.module.scss";
import {useSelector} from "react-redux";
import {isLoadingSelector} from "./selectors";

export const LoadingOverlay: React.VFC = () => {
  const isLoading = useSelector(isLoadingSelector);

  return (
    <div
      className={csx(styles.overlay, {
        [styles.loading]: isLoading,
      })}
    >
      <Spinner className={styles.spinner} color={"primary"}/>
    </div>
  );
};
