import React from "react";
import {Button, Col, Container, Row} from "reactstrap";
import {faCaretLeft, faCaretRight} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useMediaUp} from "../../../helpers/useMediaUp";
import styles from "./Calendar.module.scss";
import csx from "classnames";
import {toLocalDate} from "../../../services/date";

type CalendarProps = {
  from: Date;
  data?: Map<string, any>;
  rows?: number;
  daysPerRow?: number;
  next?: () => void;
  previous?: () => void;
  onSelect?: (date: Date) => void;
  selectedDate?: Date;
  disableCell?: (day: Date) => boolean;
  cell: React.ComponentType<any>;
};

const normalizeDayOfWeek = (day: number): number => (day + 6) % 7;

export const firstDayOfWeek = (date: Date) => {
  const day = new Date();
  day.setFullYear(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - normalizeDayOfWeek(date.getDay())
  );
  return day;
};

export const lastDayOfWeek = (date: Date) => {
  const day = new Date();
  day.setFullYear(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + (6 - normalizeDayOfWeek(date.getDay()))
  );
  return day;
};

export const Calendar: React.FC<CalendarProps> = ({
                                                    from,
                                                    rows,
                                                    daysPerRow,
                                                    next,
                                                    previous,
                                                    disableCell,
                                                    cell: Cell,
                                                    data,
                                                    onSelect,
                                                    selectedDate,
                                                  }) => {
  const isMdUp = useMediaUp("md");

  const today = new Date();
  const startingDay = firstDayOfWeek(from);

  const days: Date[][] = Array.from(new Array(rows).keys()).map((row) =>
    Array.from(new Array(daysPerRow!).keys()).map(
      (day) =>
        new Date(
          startingDay.getFullYear(),
          startingDay.getMonth(),
          startingDay.getDate() + day + row * daysPerRow!
        )
    )
  );

  const endingDay = days[rows! - 1][daysPerRow! - 1];

  const dayMonthFormatter = new Intl.DateTimeFormat("it-IT", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <Container fluid className="mb-3">
      <Row>
        <Col>
          <Button
            className={csx("float-left", styles.buttonPrevious)}
            color={"link"}
            onClick={previous}
          >
            <FontAwesomeIcon icon={faCaretLeft}/>
          </Button>
        </Col>
        <Col className={"text-center"}>
          <span>
            {dayMonthFormatter.format(startingDay)} -{" "}
            {dayMonthFormatter.format(endingDay)}
          </span>
        </Col>
        <Col>
          <Button
            className={csx("float-right", styles.buttonNext)}
            color={"link"}
            onClick={next}
          >
            <FontAwesomeIcon icon={faCaretRight}/>
          </Button>
        </Col>
      </Row>

      <Row>
        <Col className={styles.calendar}>
          <Row>
            <Col className={styles.thDay}>{isMdUp ? "Lunedì" : "Lun"}</Col>
            <Col className={styles.thDay}>{isMdUp ? "Martedì" : "Mar"}</Col>
            <Col className={styles.thDay}>{isMdUp ? "Mercoledì" : "Mer"}</Col>
            <Col className={styles.thDay}>{isMdUp ? "Giovedì" : "Gio"}</Col>
            <Col className={styles.thDay}>{isMdUp ? "Venerdì" : "Ven"}</Col>
            <Col className={styles.thDay}>{isMdUp ? "Sabato" : "Sab"}</Col>
            <Col className={styles.thDay}>{isMdUp ? "Domenica" : "Dom"}</Col>
          </Row>
          {days.map((row, index) => (
            <Row key={index} className={styles.row}>
              {row.map((day) => (
                <Cell
                  key={day.getDate()}
                  day={day}
                  onSelect={onSelect}
                  data={data?.get(toLocalDate(day))}
                  isSelected={
                    toLocalDate(day) === toLocalDate(selectedDate ?? new Date())
                  }
                  isDisabled={disableCell?.(day) ?? false}
                  isToday={toLocalDate(day) === toLocalDate(today)}
                />
              ))}
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

Calendar.defaultProps = {
  daysPerRow: 7,
  rows: 3,
};
