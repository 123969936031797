export interface CostCenter {
  id: number;
  name: string;
  active: boolean;
  costClass: CostClass;
  code: number;
}

export enum CostClass {
  M = "M",
  C = "C",
  A = "A",
}
