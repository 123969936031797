import React from "react";
import styles from "./AlertList.module.scss";
import {Alert as BSAlert} from "reactstrap";
import {useAppDispatch, useAppSelector} from "../../../helpers/store";
import {closeAlert} from "./reducer";

export const AlertList: React.VFC = () => {
  const dispatch = useAppDispatch();
  const alerts = useAppSelector(state => state.alerts.alerts);

  return (
    <div className={styles.alertSandbox}>
      {alerts?.map((alert, index) => (
        <BSAlert
          key={index}
          color={alert.type}
          toggle={() => dispatch(closeAlert(alert.id))}
        >
          {alert.message}
        </BSAlert>
      ))}
    </div>
  );
};

