import {faCheck, faTimes} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Button, ButtonGroup, ListGroupItem} from "reactstrap";
import {Reservation} from "../../../services/mealReservation";
import styles from "./Reservation.module.scss";
import {ReservationStatus} from "../../../services/mealReservation/reservation";
import {Guard} from "../../ui/router/Guard";
import {Role} from "../../../services/authentication/userAuthentication";

export type ReservationConfirmProps = {
  disabled?: boolean;
  reservation: Reservation;
  handleConfirm?: () => void;
  handleRemove?: () => void;
};

export const ReservationConfirm: React.FC<ReservationConfirmProps> = ({
  disabled,
  reservation,
  handleConfirm,
  handleRemove,
}) => {
  return (
    <ListGroupItem className={styles.entry}>
      <Guard requiredRoles={[Role.COOK]}>
      <ButtonGroup size="sm" className="mr-3">
        <Button
          disabled={disabled}
          color={
            reservation.confirmed === ReservationStatus.CONFIRMED
              ? "success"
              : "dark"
          }
          onClick={handleConfirm}
        >
          <FontAwesomeIcon fixedWidth icon={faCheck} />
        </Button>
        <Button
          disabled={disabled}
          color={
            reservation.confirmed === ReservationStatus.ABSENT
              ? "danger"
              : "dark"
          }
          onClick={handleRemove}
        >
          <FontAwesomeIcon fixedWidth icon={faTimes} />
        </Button>
      </ButtonGroup>
      </Guard>
      {reservation.user?.name} {reservation.user?.surname}
      {reservation.seats === 2
        ? " (+1 ospite)"
        : reservation.seats > 2
        ? ` (+${reservation.seats - 1} ospiti)`
        : null}
    </ListGroupItem>
  );
};
