import {IconProp} from "@fortawesome/fontawesome-svg-core";
import React from "react";
import {Collapse, Nav, NavItem, NavLink} from "reactstrap";
import styles from "./Menu.module.scss";
import csx from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {AppDispatch, RootState} from "../../../../../helpers/store";

type SubMenuProps = {
  title: string;
  icon?: IconProp;
  isOpen?: boolean;
  onClick?: React.MouseEventHandler<any>;
  children?: React.ReactNode;
};

export const _SubMenu: React.FC<SubMenuProps> = ({
                                                   title,
                                                   icon,
                                                   isOpen,
                                                   onClick,
                                                   children,
                                                 }) => {
  return (
    <NavItem>
      <NavLink
        className={csx(styles.subMenuTitle, styles.menuItem)}
        onClick={onClick}
      >
        <span className={styles.menuItemText}> {title}</span>
        <span className={styles.subMenuIcon}>
          {icon ? <FontAwesomeIcon icon={icon}/> : <>&nbsp;</>}
        </span>
      </NavLink>
      <Collapse className={styles.subMenuContent} isOpen={isOpen}>
        <Nav vertical={true}>{children}</Nav>
      </Collapse>
    </NavItem>
  );
};

_SubMenu.defaultProps = {
  isOpen: false,
};

const makeStateToProps = (state: RootState, props: SubMenuProps) => ({
  // isOpen: makeIsOpenSubMenuSelector(props.title)(state, props),
});

const dispatchToProps = (dispatch: AppDispatch, props: SubMenuProps) => ({
  onClick: () => {
    // dispatch(trigger(props.title));
    // dispatch(sidePanelActions.open());
  },
});

export const SubMenu = connect(makeStateToProps, dispatchToProps)(_SubMenu);
