import React from "react";
import csx from "classnames";
import styles from "./Calendar.module.scss";
import {Col} from "reactstrap";

export type CellProps = {
  day: Date;
  isDisabled?: boolean;
  isToday?: boolean;
  isSelected?: boolean;
  onSelect?: (day: Date) => void;
};

const dayFormatter = new Intl.DateTimeFormat("it-IT", {day: "numeric"});

export const Cell: React.FC<CellProps> = ({
                                            day,
                                            isToday,
                                            isSelected,
                                            isDisabled,
                                            onSelect,
                                            children,
                                          }) => {
  return (
    <Col
      className={csx(styles.cell, {
        [styles.disabled]: isDisabled,
        [styles.today]: isToday,
        [styles.selected]: isSelected,
      })}
      onClick={() => {
        if (!isDisabled) onSelect?.(day);
      }}
    >
      <div className={styles.dayContainer}>
        <span className={styles.dayNumber}>{dayFormatter.format(day)}</span>
      </div>
      <div className={styles.childrenContainer}>{children}</div>
    </Col>
  );
};

Cell.defaultProps = {
  isToday: false,
  isDisabled: false,
};
