import {faCar, faIdCard, faUtensils} from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import React, {VFC} from "react";
import {useSelector} from "react-redux";
import {Nav} from "reactstrap";
import {routeConfig} from "../../../helpers/routes";
import {useMediaUp} from "../../../helpers/useMediaUp";
import {NavBarMenu} from "./nav-bar/NavBarMenu";
import {MenuLink} from "./shared/MenuLink";
import {SubMenu} from "./shared/SubMenu";
import styles from "./side-nav/SideNav.module.scss";
import {selectors} from "./slice";

const {
  news,
  manageWork,
  mealConfiguration,
  mealReservation,
  newResource,
  resourcesList,
  usersList,
  viewMealReservations,
  reserveResource,
  reservationsList,
  reservationsScheduler,
} = routeConfig;

const softwareVersion = process.env.REACT_APP_VERSION ?? "";

export const SideNav: VFC = () => {
  const isOpen = useSelector(selectors.selectIsNavOpen);
  const isXs = !useMediaUp("sm");

  return (
    <div
      className={classNames({[styles.navIsVisible]: isOpen}, styles.sideNav)}
    >
      <div>
        <nav>
          <Nav vertical>
            <MenuLink route={news} />
            <SubMenu
              name="users"
              label="Utenti"
              icon={faIdCard}
              routes={[usersList]}
            />
            <SubMenu
              name="meals"
              label="Pasti"
              icon={faUtensils}
              routes={[
                mealReservation,
                viewMealReservations,
                mealConfiguration,
              ]}
            />
            <MenuLink route={manageWork} className={"disabled"} />
            <SubMenu
              name="resources"
              label="Risorse"
              icon={faCar}
              routes={[
                resourcesList,
                newResource,
                reserveResource,
                reservationsList,
                reservationsScheduler,
              ]}
            />
          </Nav>
          {isXs && <NavBarMenu navItemClassName={styles.navItem} />}
        </nav>
        <span className={styles.footerNav}>Versione {softwareVersion}</span>
      </div>
    </div>
  );
};
