import {
  faCoffee,
  faEnvelope,
  faLink,
  faPencil,
  faPhone,
  faPlus,
  faStickyNote,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {VFC} from "react";
import {Button, Card, Col, Row} from "reactstrap";
import {PageTitle} from "../ui/layout/PageTitle";
import {SectionTitle} from "../ui/layout/SectionTitle";

export const UserSettings: VFC = () => {
  return (
    <Card body>
      <PageTitle>Impostazioni utente</PageTitle>
      <p className="card-text">
        Sed pharetra quis lacus et maximus. Suspendisse nulla sem, tempor sit
        amet dolor quis, dapibus faucibus orci. Quisque sodales nulla mauris,
        sit amet semper ex rutrum id. Sed quis imperdiet risus. Maecenas
        placerat malesuada vehicula. Cras pharetra vel purus in imperdiet.
        Integer nibh neque, ultrices eget nisi sed, dignissim euismod magna.
      </p>
      <Row>
        <Col>
          <SectionTitle mt>
            <span>
              <FontAwesomeIcon icon={faLink} className="mr-2" /> Collegamenti
              utili
            </span>
            <Button
              size="sm"
              color="primary"
              className="ml-auto rounded-circle"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </SectionTitle>
          <div>
            <p className="d-flex">
              <span>
                <FontAwesomeIcon fixedWidth icon={faPhone} className="mr-2" />
                <strong>3281743792</strong> Tecnico
              </span>
              <Button size="sm" color="primary" outline className="ml-auto">
                <FontAwesomeIcon icon={faPencil} />
              </Button>
              <Button size="sm" color="danger" outline className="ml-2">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </p>
            <p className="d-flex">
              <span>
                <FontAwesomeIcon fixedWidth icon={faPhone} className="mr-2" />
                <strong>3204852837</strong> Consulente personali
              </span>
              <Button size="sm" color="primary" outline className="ml-auto">
                <FontAwesomeIcon icon={faPencil} />
              </Button>
              <Button size="sm" color="danger" outline className="ml-2">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </p>
            <p className="d-flex">
              <span>
                <FontAwesomeIcon fixedWidth icon={faPhone} className="mr-2" />
                <strong>3374637283</strong> Dottore
              </span>
              <Button size="sm" color="primary" outline className="ml-auto">
                <FontAwesomeIcon icon={faPencil} />
              </Button>
              <Button size="sm" color="danger" outline className="ml-2">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </p>
            <hr className="border-gray" />
            <p className="d-flex">
              <span>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faEnvelope}
                  className="mr-2"
                />
                <strong>email@comune.brescia.it</strong> contatto Comune Bs
              </span>
              <Button size="sm" color="primary" outline className="ml-auto">
                <FontAwesomeIcon icon={faPencil} />
              </Button>
              <Button size="sm" color="danger" outline className="ml-2">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </p>
            <p className="d-flex">
              <span>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faEnvelope}
                  className="mr-2"
                />
                <strong>amministrazione@calabrone.org</strong> amministrazione
                Calabrone
              </span>
              <Button size="sm" color="primary" outline className="ml-auto">
                <FontAwesomeIcon icon={faPencil} />
              </Button>
              <Button size="sm" color="danger" outline className="ml-2">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </p>
            <p className="d-flex">
              <span>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faEnvelope}
                  className="mr-2"
                />
                <strong>info@jeflab.it</strong> Consulenti informatici
              </span>
              <Button size="sm" color="primary" outline className="ml-auto">
                <FontAwesomeIcon icon={faPencil} />
              </Button>
              <Button size="sm" color="danger" outline className="ml-2">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </p>
          </div>
        </Col>
        <Col>
          <SectionTitle mt>
            <span>
              <FontAwesomeIcon icon={faCoffee} className="mr-2" /> Note
              personali
            </span>
            <Button
              size="sm"
              color="primary"
              className="ml-auto rounded-circle"
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </SectionTitle>
          <div>
            <p className="d-flex align-items-center">
              <span>
                <FontAwesomeIcon fixedWidth icon={faStickyNote} /> Ricordarsi le
                chiavi Piastra Pendolina
              </span>
              <Button size="sm" color="primary" outline className="ml-auto">
                <FontAwesomeIcon icon={faPencil} />
              </Button>
              <Button size="sm" color="danger" outline className="ml-2">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </p>{" "}
            <p className="d-flex align-items-center">
              <span>
                <FontAwesomeIcon fixedWidth icon={faStickyNote} /> Suspendisse
                tincidunt ut purus vitae semper. Fusce luctus felis quis leo
                facilisis lobortis. Vivamus dictum eros et viverra sodales. In
                nec erat ac velit sagittis ultrices a non nibh. Nullam facilisis
                scelerisque massa, sit amet viverra nulla vehicula vulputate.
                Proin gravida, tortor non mollis rhoncus, libero enim suscipit
                turpis, sed consequat dolor risus egestas dolor.
              </span>
              <Button size="sm" color="primary" outline className="ml-auto">
                <FontAwesomeIcon icon={faPencil} />
              </Button>
              <Button size="sm" color="danger" outline className="ml-2">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </p>
            <p className="d-flex align-items-center">
              <span>
                <FontAwesomeIcon fixedWidth icon={faStickyNote} /> Ogni Martedì
                mattina h 11:00 riunione equipe - stanza don piero
              </span>
              <Button size="sm" color="primary" outline className="ml-auto">
                <FontAwesomeIcon icon={faPencil} />
              </Button>
              <Button size="sm" color="danger" outline className="ml-2">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </p>
          </div>
        </Col>
      </Row>
    </Card>
  );
};
