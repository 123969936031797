import {faCheck, faSearch, faTimes} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatISO} from "date-fns";
import React, {useCallback, useMemo, useState, VFC} from "react";
import {Link} from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {routeConfig} from "../../../helpers/routes";
import {
  hydrateReservation,
  Reservation,
} from "../../../services/resources/reservation";
import {
  isoWithMilliseconds,
  useLazyGetCalendarQuery,
} from "../../../services/resources/reservations-api";
import {translateCategory} from "../../../services/resources/resource";
import {useGetAllResourcesQuery} from "../../../services/resources/resources-api";
import {useCurrentUserQuery} from "../../../services/users/userService";
import {PageTitle} from "../../ui/layout/PageTitle";
import {Scheduler} from "../../ui/scheduler";
import {Event} from "../../ui/scheduler/types";
import {
  formatDate,
  reservationSeverity,
  reservationState,
} from "./components/reservations-table";
import {EndReservationDialog} from "./end-reservation-dialog";
import {
  useEndResourceReservation,
  useStartResourceReservation,
} from "./ResourceReservationsList";
import styles from "./ResourceReservationsScheduler.module.scss";
import {StartReservationDialog} from "./start-reservation-dialog";

interface ResourceReservationsSchedulerProps {}

export const ResourceReservationsScheduler: VFC<ResourceReservationsSchedulerProps> =
  () => {
    const {resourceReservationDetails} = routeConfig;
    const [modalOpen, setModalOpen] = useState<number>();
    const [selectedEvent, setSelectedEvent] = useState<Event<Reservation>>();
    const {data: loggedUser} = useCurrentUserQuery();
    const [getCalendar, {currentData: reservations}] = useLazyGetCalendarQuery({
      pollingInterval: 60 * 1000,
    });
    const [getCalendarDaily, {currentData: reservationsDaily}] =
      useLazyGetCalendarQuery({pollingInterval: 60 * 1000});
    const {data: resources} = useGetAllResourcesQuery();

    const events = useMemo(
      () =>
        reservations?.map((reservation) => ({
          id: reservation.id!,
          start: new Date(reservation.beginReservation),
          end: new Date(reservation.endReservation),
          label: `${reservation.user.name} ${reservation.user.surname}`,
          color: `var(--${reservationSeverity(
            hydrateReservation(reservation)
          )})`,
          resourceId: reservation.resource.id!,
          payload: hydrateReservation(reservation),
        })),
      [reservations]
    );
    const eventsDaily = useMemo(
      () =>
        reservationsDaily?.map((reservation) => ({
          id: reservation.id!,
          start: new Date(reservation.beginReservation),
          end: new Date(reservation.endReservation),
          label: `${reservation.user.name} ${reservation.user.surname}`,
          color: `var(--${reservationSeverity(
            hydrateReservation(reservation)
          )})`,
          resourceId: reservation.resource.id!,
          payload: hydrateReservation(reservation),
        })),
      [reservationsDaily]
    );

    const [showStartReservationDialog, setShowStartReservationDialog] =
      useState<Reservation>();
    const [showEndReservationDialog, setShowEndReservationDialog] =
      useState<Reservation>();
    const startResourceReservation = useStartResourceReservation(() => {
      setShowStartReservationDialog(undefined);
      setModalOpen(undefined);
    });
    const endResourceReservation = useEndResourceReservation(() => {
      setShowEndReservationDialog(undefined);
      setModalOpen(undefined);
    });

    const handleChangePeriod = useCallback(
      (periodEnds) => {
        getCalendar({
          from: isoWithMilliseconds(formatISO(periodEnds.startDate)),
          to: isoWithMilliseconds(formatISO(periodEnds.endDate)),
        });
      },
      [getCalendar]
    );
    const handleChangePeriodDaily = useCallback(
      (periodEnds) => {
        getCalendarDaily({
          from: isoWithMilliseconds(formatISO(periodEnds.startDate)),
          to: isoWithMilliseconds(formatISO(periodEnds.endDate)),
        });
      },
      [getCalendarDaily]
    );

    const handleEventClick = useCallback((event) => {
      setModalOpen(event.id);
      setSelectedEvent(event);
    }, []);
    const toggleModal = useCallback(() => {
      setModalOpen(undefined);
    }, []);

    return (
      <>
        <Card body className="mb-3">
          <PageTitle>Panoramica risorse</PageTitle>
          {resources && (
            <Scheduler
              scale="day"
              period="week"
              events={events}
              resources={resources}
              onEventClick={handleEventClick}
              onPeriodChange={handleChangePeriod}
            />
          )}
        </Card>
        <Card body>
          <PageTitle>Vista giornaliera</PageTitle>
          {resources && (
            <Scheduler
              scale="hour"
              period="day"
              events={eventsDaily}
              resources={resources ?? []}
              onEventClick={handleEventClick}
              onPeriodChange={handleChangePeriodDaily}
            />
          )}
        </Card>
        {selectedEvent && (
          <Modal
            isOpen={!!modalOpen}
            toggle={toggleModal}
            centered
            className={styles.eventModal}
          >
            <ModalHeader toggle={toggleModal}>
              Prenotazione risorsa - {selectedEvent.label}
            </ModalHeader>
            <ModalBody>
              <Row tag="dl">
                <Col tag="dt" sm={5}>
                  Stato:
                </Col>
                <Col tag="dd" sm={7}>
                  {reservationState(selectedEvent.payload)}
                </Col>
                <Col tag="dt" sm={5}>
                  Categoria:
                </Col>
                <Col tag="dd" sm={7}>
                  {translateCategory(selectedEvent.payload.category)}
                </Col>
                <Col tag="dt" sm={5}>
                  Risorsa assegnata:
                </Col>
                <Col tag="dd" sm={7}>
                  {selectedEvent.payload.resource.name} ({selectedEvent.payload.resource.licensePlate})
                </Col>
                <Col tag="dt" sm={5}>
                  Alimentazione:
                </Col>
                <Col tag="dd" sm={7}>
                  {selectedEvent.payload.resource.powerSource}
                </Col>
                <Col tag="dt" sm={5}>
                  Dal:
                </Col>
                <Col tag="dd" sm={7}>
                  {formatDate(selectedEvent.payload.beginReservation)}
                </Col>
                <Col tag="dt" sm={5}>
                  Al:
                </Col>
                <Col tag="dd" sm={7}>
                  {formatDate(selectedEvent.payload.endReservation)}
                </Col>
                <Col tag="dt" sm={5}>
                  Centro di costo:
                </Col>
                <Col tag="dd" sm={7}>
                  {selectedEvent.payload.costCenter.name}
                </Col>
              </Row>
              {selectedEvent.payload.user.id === loggedUser?.id && (
                <div className={styles.actions}>
                  <Button
                    tag={Link}
                    to={resourceReservationDetails.route({
                      id: selectedEvent.payload.id,
                    })}
                    color="primary"
                    className="flex-1"
                  >
                    <FontAwesomeIcon icon={faSearch} /> Dettagli
                  </Button>
                  {selectedEvent.payload.startedAt &&
                  !selectedEvent.payload.endedAt ? (
                    <Button
                      className="flex-1"
                      color="danger"
                      onClick={() =>
                        setShowEndReservationDialog(selectedEvent.payload)
                      }
                    >
                      <FontAwesomeIcon icon={faTimes} /> Chiudi corsa
                    </Button>
                  ) : null}
                  {!selectedEvent.payload.startedAt ? (
                    <Button
                      className="flex-1"
                      color="success"
                      icon="pi pi-check"
                      onClick={() =>
                        setShowStartReservationDialog(selectedEvent.payload)
                      }
                    >
                      <FontAwesomeIcon icon={faCheck} /> Apri corsa
                    </Button>
                  ) : null}
                </div>
              )}
            </ModalBody>
          </Modal>
        )}
        {showStartReservationDialog &&
        loggedUser?.id === showStartReservationDialog.user.id ? (
          <StartReservationDialog
            reservation={showStartReservationDialog}
            onCancel={() => setShowStartReservationDialog(undefined)}
            onConfirm={(distance) =>
              startResourceReservation(showStartReservationDialog, distance)
            }
          />
        ) : null}
        {showEndReservationDialog &&
        loggedUser?.id === showEndReservationDialog.user.id ? (
          <EndReservationDialog
            reservation={showEndReservationDialog}
            onCancel={() => setShowEndReservationDialog(undefined)}
            onConfirm={(distance, message) =>
              endResourceReservation(
                showEndReservationDialog,
                distance,
                message
              )
            }
          />
        ) : null}
      </>
    );
  };
