import {
  differenceInDays,
  differenceInHours,
  endOfDay,
  endOfWeek,
  getDate,
  getHours,
  isSameHour,
  isToday,
  startOfDay,
  startOfWeek,
} from "date-fns";
import {Period, Scale} from "./types";

const timeDifferenceFunction: Record<
  Scale,
  (dateLeft: Date, dateRight: Date) => number
> = {
  day: differenceInDays,
  hour: differenceInHours,
};
const startUnitFunction: Record<Scale, (startDate: Date) => number> = {
  day: getDate,
  hour: getHours,
};

export const isNowCell: Record<Scale, (startDate: Date) => boolean> = {
  day: isToday,
  hour: (date) => isSameHour(date, new Date()),
};

export const minCellWidth: Record<Scale, number> = {
  day: 40,
  hour: 24,
};

export const minuteInAScale: Record<Scale, number> = {
  day: 24 * 60,
  hour: 60,
};

export const unitDuration: Record<Scale | Period, keyof Duration> = {
  hour: "hours",
  day: "days",
  week: "weeks",
};

export const titleFormat: Record<Scale, string> = {
  hour: "PPPP",
  day: "MMMM yyyy",
};
export const titleFormatShort: Record<Scale, string> = {
  hour: "dd MMM",
  day: "MMM yy",
};
export const unitFormat: Record<Scale, string> = {hour: "HH", day: "EEEE dd"};
export const unitFormatShort: Record<Scale, string> = {hour: "HH", day: "dd"};

export function getStartUnit(startDate: Date, scale: Scale) {
  return startUnitFunction[scale](startDate);
}

export function getTimeSpanUnits(startDate: Date, endDate: Date, scale: Scale) {
  return timeDifferenceFunction[scale](endDate, startDate) + 1;
}

// Period
export const startOfPeriod: Record<Period, (date?: Date) => Date> = {
  day: (date) => startOfDay(date ?? new Date()),
  week: (date) => startOfWeek(date ?? new Date(), {weekStartsOn: 1}),
};

export const endOfPeriod: Record<Period, (date?: Date) => Date> = {
  day: (date) => endOfDay(date ?? new Date()),
  week: (date) => endOfWeek(date ?? new Date(), {weekStartsOn: 1}),
};
