import {Reservation, ReservationId, ReservationStatus} from "./reservation";
import {UserId} from "../authentication/userAuthentication";
import {api, Tags} from "../api";

const reservationsApi = api.injectEndpoints({

  endpoints: (build) => ({

    getReservationsByDate: build.query<Reservation[], string>({
      query: (date) => ({
        url: `/mealReservations/ofDay?date=${date}`,
        method: 'GET'
      }),
      providesTags: [{type: Tags.mealReservation, id: 'LIST'}],
    }),

    getReservationCapacities: build.query<{[key: string]: number}, string>({
      query: (date) => ({
        url: `/mealReservations/capacitiesOfDay?date=${date}`,
        method: 'GET'
      }),
      providesTags: [{type: Tags.mealReservation, id: 'LIST'}],
    }),

    getReservationsByUserAndDate: build.query<Reservation[], {userId: UserId, from?: string, to?: string}>({
      query: ({userId, from, to}) => ({
        url: `/users/${userId}/reservations?from=${from ?? ''}&to=${to ?? ''}`,
        method: 'GET'
      }),
      providesTags: [{type: Tags.mealReservation, id: 'LIST'}],
    }),

    getReservation: build.query<Reservation, ReservationId>({
      query: (id) => ({
        url: `/mealReservations/${id}`,
        method: 'GET'
      }),
      providesTags: (_response, _error, id) => [{type: Tags.mealReservation, id: id}],
    }),

    createReservation: build.mutation<ReservationId, Reservation>({
      query: (reservation) => ({
        url: `/mealReservations`,
        method: 'POST',
        body: reservation
      }),
      invalidatesTags: [{type: Tags.mealReservation, id: 'LIST'}],
    }),

    updateReservation: build.mutation<void, Reservation>({
      query: (reservation) => ({
        url: `/mealReservations/${reservation.id}`,
        method: 'PUT',
        body: reservation
      }),
      invalidatesTags: [{type: Tags.mealReservation, id: 'LIST'}],
    }),

    deleteReservation: build.mutation<void, ReservationId>({
      query: (id) => ({
        url: `/mealReservations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: Tags.mealReservation, id: 'LIST'}],
    }),

    confirmReservation: build.mutation<void, {id: ReservationId, confirm: ReservationStatus}>({
      query: ({id, confirm}) => ({
        url: `/mealReservations/${id}/confirm`,
        method: 'PUT',
        body: {confirmed: confirm.valueOf()}
      }),
      invalidatesTags: [{type: Tags.mealReservation, id: 'LIST'}],
    })

  })

});

export const {
  useGetReservationsByDateQuery,
  useGetReservationsByUserAndDateQuery,
  useGetReservationCapacitiesQuery,
  useGetReservationQuery,
  useDeleteReservationMutation,
  useCreateReservationMutation,
  useUpdateReservationMutation,
  useConfirmReservationMutation
} = reservationsApi;
