import {UserId} from "../authentication/userAuthentication";
import {CostCenter} from "../accounting/cost-center";
import {ResourcePermanentUnavailability, ResourceTemporaryUnavailability} from "./resource-permanent-unavailability";

export type ResourceId = number;

export enum ResourceCategory {
  BICYCLE = "BICYCLE",
  CAR = "CAR",
  VAN = "VAN"
}

export enum ResourcePriority {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export enum ResourceState {
  AVAILABLE = "AVAILABLE",
  MAINTENANCE = "MAINTENANCE",
  UNAVAILABLE = "UNAVAILABLE",
}

export interface Resource {
  id?: ResourceId;
  name: string;
  description?: string;
  supervisorId: UserId;
  category: ResourceCategory;
  state: ResourceState;
  km: number;
  licensePlate: string;
  priority: ResourcePriority;
  optionals: string[];
  costCenter: CostCenter;

  powerSource: string;
  permanentUnavailabilities: ResourcePermanentUnavailability[];
  temporaryUnavailabilities: ResourceTemporaryUnavailability[];
}

export type Optional = string;

const Category_IT: {[k in ResourceCategory]: string} = {
  [ResourceCategory.CAR]: "Auto",
  [ResourceCategory.BICYCLE]: "Bicicletta",
  [ResourceCategory.VAN]: "Furgone",
};

export const translateCategory = (category: ResourceCategory) =>
  Category_IT[category];

const State_IT: {[k in ResourceState]: string} = {
  [ResourceState.AVAILABLE]: "Disponibile",
  [ResourceState.MAINTENANCE]: "Manutenzione",
  [ResourceState.UNAVAILABLE]: "Non disponibile",
};

export const translateState = (state: ResourceState) => State_IT[state];

const Priority_IT: {[k in ResourcePriority]: string} = {
  [ResourcePriority.HIGH]: "Alta",
  [ResourcePriority.MEDIUM]: "Media",
  [ResourcePriority.LOW]: "Bassa",
};

export const translatePriority = (priority: ResourcePriority) =>
  Priority_IT[priority];


export const translateOptional = (optional: string) => {
  switch(optional) {
    case "AIR_CONDITIONING": return "Aria condizionata";
    case "TELEPASS": return "Telepass";
    case "SPACIOUS": return "Spazioso";
    default: return optional;
  }
};
