import {faHatChef, faSignOut, faUser, faUserCrown, faUserSecret,} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {VFC} from "react";
import {NavLink as RRNAvLink} from "react-router-dom";
import {DropdownItem, DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown,} from "reactstrap";
import {routeConfig} from "../../../../helpers/routes";
import {Role} from "../../../../services/authentication/userAuthentication";
import {useCurrentUserQuery} from "../../../../services/users/userService";
import {useLogoutMutation} from "../../../../services/authentication/authentication";
import {clear} from "../../../../helpers/local-storage";
import {useAppDispatch} from "../../../../helpers/store";
import {unauthorized} from "../../../login/reducer";

interface NavBarMenuProps {
  navItemClassName?: string;
  className?: string;
}

const {userPersonalPage, userSettings} = routeConfig;

const useLogout = () => {
  const [logout] = useLogoutMutation();
  const dispatch = useAppDispatch();

  return () => logout().unwrap()
    .then(() => {
      clear();
      dispatch(unauthorized());
    });
};

export const NavBarMenu: VFC<NavBarMenuProps> = ({
                                                   className,
                                                   navItemClassName,
                                                 }) => {
  const {data: loggedUser} = useCurrentUserQuery();
  const logout = useLogout();

  const roles = loggedUser?.roles ?? [];

  const userIcon = roles.includes(Role.ADMIN)
    ? faUserCrown
    : roles.includes(Role.COOK)
      ? faHatChef
      : roles.includes(Role.USER)
        ? faUser
        : faUserSecret;

  return (
    <Nav navbar className={className}>
      <UncontrolledDropdown nav inNavbar className={navItemClassName}>
        <DropdownToggle nav caret>
          <FontAwesomeIcon fixedWidth size="lg" icon={userIcon}/> Ciao{" "}
          {loggedUser?.name}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag={RRNAvLink} to={userPersonalPage.path} exact>
            {userPersonalPage.icon && (
              <FontAwesomeIcon
                fixedWidth
                icon={userPersonalPage.icon}
                className="mr-2"
              />
            )}
            Profilo
          </DropdownItem>
          <DropdownItem tag={RRNAvLink} to={userSettings.path} exact className={'disabled'}>
            {userSettings.icon && (
              <FontAwesomeIcon
                fixedWidth
                icon={userSettings.icon}
                className="mr-2"
              />
            )}
            Impostazioni
          </DropdownItem>
          <DropdownItem divider/>
          <DropdownItem onClick={() => logout()}>
            <FontAwesomeIcon fixedWidth icon={faSignOut}/> Logout
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
};
