import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {reducer as authentication,} from "../components/login/reducer";
import {reducer as alerts} from "../components/ui/alerts";
import {reducer as layout} from "../components/ui/layout/slice";
import {reducer as loading} from "../components/ui/loading-overlay";
import {api} from "../services/api";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

const appReducers = combineReducers({
  loading,
  authentication,
  alerts,
  layout,
  [api.reducerPath]: api.reducer
});

export const store = configureStore({
  reducer: appReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({immutableCheck: {ignoredPaths: ["form"]}}).concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
