import {faSpinner} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import {Formik} from "formik";
import {FormikHelpers} from "formik/dist/types";
import React, {useEffect} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  NavLink,
} from "reactstrap";
import * as Yup from "yup";
import {routeConfig} from "../../helpers/routes";
import {useAppDispatch} from "../../helpers/store";
import logo from "../../img/logo-white.png";
import {useLoginMutation} from "../../services/authentication/authentication";
import {useLazyCurrentUserQuery} from "../../services/users/userService";
import {errorAlert} from "../ui/alerts/reducer";
import {TextInputGroup} from "../ui/form/fields/input/Input";
import styles from "./login.module.scss";
import {authorized, currentUser} from "./reducer";

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().required("Inserisci il nome utente"),
  password: Yup.string().required("Inserisci la password"),
});

const useLogin = (loadUser: () => void) => {
  const [login] = useLoginMutation();
  const dispatch = useAppDispatch();

  return (credentials: LoginFormValues) => {
    credentials.email = credentials.email?.split('@')[0];
    return login(credentials)
      .unwrap()
      .then((tokens) => {
        dispatch(authorized(tokens));
        loadUser();
      })
      .catch((error: FetchBaseQueryError) =>
        dispatch(
          errorAlert(
            error.status === 401
              ? "Username o password errati"
              : "Il servizio ha riscontrato un errore. Riprovare più tardi o contattare l'amministratore"
          )
        )
      );
  }
};

const useLoadCurrentUser = () => {
  const [loadUser, {data: user}] = useLazyCurrentUserQuery();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation<{from: {pathname: string, search?: string}}>();

  const {from} = (location.state || {from: {pathname: '/'}});

  useEffect(() => {
    if (user) {
      dispatch(currentUser(user));
      let newLocation = from.pathname;
      if (from.search)
        newLocation += from.search;
      history.push(newLocation);
    }
  }, [dispatch, history, user]);

  return loadUser;
};

export const Login: React.VFC = () => {
  const loadUser = useLoadCurrentUser();
  const login = useLogin(loadUser);

  const doLogin = async (
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>
  ) => {
    await login(values);
    formikHelpers.setSubmitting(false);
  };

  const {requestResetPassword} = routeConfig;

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginFormWrapper}>
        <img className="mb-5 img-fluid" src={logo} alt="Il Calabrone" />
        <h2 className="mb-3 text-center text-light">Gestionale</h2>
        <Formik
          initialValues={{email: "", password: ""}}
          onSubmit={doLogin}
          validationSchema={LoginSchema}
        >
          {(formikProps) => (
            <Form onSubmit={formikProps.handleSubmit}>
              <Card className={"form-groups"}>
                <CardBody>
                  <p>Entra nell'area riservata</p>
                  <TextInputGroup
                    label="Username"
                    name="email"
                    type="text"
                  />
                  <TextInputGroup
                    label="Password"
                    name="password"
                    type="password"
                  />
                  <FormGroup>
                    <Button
                      color="primary"
                      type="submit"
                      block
                      disabled={
                        !formikProps.dirty ||
                        formikProps.isSubmitting ||
                        !formikProps.isValid
                      }
                    >
                      {formikProps.isSubmitting ? (
                        <>
                          <FontAwesomeIcon icon={faSpinner} spin /> Accesso in
                          corso...
                        </>
                      ) : (
                        "Accedi"
                      )}
                    </Button>
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <p className={styles.resetPassword}>
                    <NavLink
                      tag={Link}
                      to={requestResetPassword.route(
                        {},
                        {email: formikProps.values.email}
                      )}
                    >
                      Password dimenticata?
                    </NavLink>
                  </p>
                </CardFooter>
              </Card>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
