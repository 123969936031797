import {useFormik} from "formik";
import React, {useEffect} from "react";
import {Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input} from "reactstrap";
import {useQuery} from "../../../helpers/routeConfig";
import {useAppDispatch} from "../../../helpers/store";
import logo from "../../../img/logo-white.png";
import {UserId} from "../../../services/authentication/userAuthentication";
import {useUpdateUserPasswordMutation} from "../../../services/users/userService";
import styles from "./ResetPassword.module.scss";
import {authorized} from "../reducer";

export type ResetPasswordFormValues = {
  password: string;
  confirmPassword: string;
};

export const ResetPassword: React.VFC = () => {
  const userId: UserId = Number.parseInt(useQuery().get("id") ?? "-1");
  const token: string = useQuery().get("token") ?? "";

  const [update, {isError}] = useUpdateUserPasswordMutation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(authorized({token: token, refreshToken: ''}));
  }, [dispatch, token])

  const formik = useFormik<ResetPasswordFormValues>({
    initialValues: {password: "", confirmPassword: ""},
    onSubmit: (values) => update({id: userId, password: values.password}),
    validate: (values) => {
      return {};
    },
  });

  return (
    <div className={styles.resetPasswordPage}>
      <div className={"col-12 col-sm-8 col-md-8 col-lg-4 col-xl-3"}>
        <img className="mb-5 img-fluid" src={logo} alt="Il Calabrone" />
        <h2 className="mb-3 text-center text-light">Gestionale</h2>
        <Form onSubmit={formik.handleSubmit}>
          <Card className={"form-groups"}>
            <CardBody>
              <p>Modifica la password</p>
              <FormGroup row>
                <Col>
                  <Input
                    type={"password"}
                    placeholder="Nuova password"
                    {...formik.getFieldProps("password")}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Input
                    type={"password"}
                    placeholder="Conferma password"
                    {...formik.getFieldProps("confirmPassword")}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col>
                  <Button
                    color="primary"
                    block
                    type="submit"
                    disabled={
                      !formik.dirty || !formik.isValid || formik.isSubmitting
                    }
                  >
                    Modifica password
                  </Button>
                </Col>
              </FormGroup>
              {isError ? (
                <FormGroup row>
                  <Col>
                    <FormFeedback>
                      Il servizio ha riscontrato un errore. Riprovare più tardi
                      o contattare l'amministratore
                    </FormFeedback>
                  </Col>
                </FormGroup>
              ) : null}
            </CardBody>
          </Card>
        </Form>
      </div>
    </div>
  );
};
