import {User} from "../users";

export type ReservationId = number;

export enum ReservationStatus {
  TO_CONFIRM = 'TO_BE_CONFIRMED',   // presenza ancora da confermare
  CONFIRMED = 'CONFIRMED',          // presenza confermata
  ABSENT = 'ABSENT'                 // assenza confermata
}

export interface Reservation {
  id?: ReservationId;
  date: string;
  reservationSlot: string;
  seats: number;
  confirmed?: ReservationStatus;
  user?: User;
}
