import {faUtensils} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Cell, CellProps} from "../../ui/calendar/Cell";
import styles from "./Reservation.module.scss";

export type ReservationCellProps = {
  data?: boolean;
} & CellProps;

export const ReservationsViewCell: React.FC<ReservationCellProps> = ({
  data,
  ...rest
}) => {
  return (
    <Cell {...rest}>
      <span className={styles.status}>
        {data ? <FontAwesomeIcon icon={faUtensils} /> : null}
      </span>
    </Cell>
  );
};

ReservationsViewCell.defaultProps = {
  data: false,
};
