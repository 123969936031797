import React from "react";
import {Cell, CellProps} from "../../ui/calendar/Cell";
import {faUtensils} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styles from "./Reservation.module.scss";
import {Reservation} from "../../../services/mealReservation";

export type ReservationCellProps = {
  data?: Reservation;
} & CellProps;

export const ReservationCell: React.FC<ReservationCellProps> = ({
  data,
  ...rest
}) => {
  return (
    <Cell {...rest}>
      <span className={styles.status}>
        {data ? <FontAwesomeIcon icon={faUtensils} /> : null}
      </span>
    </Cell>
  );
};
