import React, {useEffect, useState} from "react";
import {Button, Col, Form, FormGroup, Label, Row} from "reactstrap";
import {Configuration, Reservation} from "../../../../services/mealReservation";
import {PageTitle} from "../../../ui/layout/PageTitle";
import csx from "classnames";
import {Formik, FormikProps} from "formik";
import {SelectInput, TextInput} from "../../../ui/form/fields/input/Input";

export type ReservationFormValues = {
  timeSlot: string;
  guests: number;
  availableSeats: number;
};

export type ReservationFormProps = {
  handleCreate?: (data: ReservationFormValues) => void;
  handleUpdate?: (data: ReservationFormValues) => void;
  handleDelete?: (reservation: Reservation) => void;
  date: Date;
  reservation?: Reservation;
  configuration?: Configuration;
  capacities?: Map<string, number>;
  enableReservation?: boolean;
  enableDeleteReservation?: boolean;
  enableModifyReservation?: boolean;
};

const dateFormatter = new Intl.DateTimeFormat("it-IT", {
  day: "numeric",
  month: "long",
  year: "numeric",
});

export const ReservationForm: React.FC<ReservationFormProps> =
  ({
     reservation,
     date,
     configuration,
     capacities,
     handleCreate,
     handleDelete,
     handleUpdate,
     enableDeleteReservation = true,
     enableModifyReservation = true,
     enableReservation = true
   }) => {
    const onSubmit = reservation ? handleUpdate : handleCreate;
    const lunchTimeSlots = configuration?.slots.map((s) => s.slot);

    const [initialValues, setInitialValues] = useState({availableSeats: 0, guests: 0, timeSlot: ""});
    const [hasCapacity, setHasCapacity] = useState(true);

    useEffect(() => {
      const defaultTimeSlot = configuration?.slots[0].slot ?? "";
      const selectedTimeSlot = reservation?.reservationSlot ?? defaultTimeSlot;

      setInitialValues({
        availableSeats: (capacities && capacities.get(selectedTimeSlot)) ?? 0,
        guests: (reservation?.seats ?? 1) - 1,
        timeSlot: reservation?.reservationSlot ?? defaultTimeSlot,
      });
    }, [date, reservation, capacities, configuration, setInitialValues]);

    useEffect(() => setHasCapacity(initialValues.availableSeats > 0),
      [initialValues, setHasCapacity]);

    return <Formik
      onSubmit={onSubmit || (() => {})}
      initialValues={initialValues}
      enableReinitialize={true}
    >
        {(props: FormikProps<ReservationFormValues>) =>
          <Form onSubmit={props.handleSubmit}>
            <FormGroup>
                <PageTitle mt>
                  {reservation ? "Modifica la tua " : "Nuova "} prenotazione per il {dateFormatter.format(date)}
                </PageTitle>
            </FormGroup>
            <p>
              Compila i campi per{" "}
              {reservation
                ? "modificare la tua prenotazione"
                : "effettuare la prenotazione"}
              :
            </p>
            <Row>
              <Col xs={12} lg={6} xl={4}>
                <FormGroup row>
                  <Label xs="auto">Seleziona orario pranzo:</Label>
                  <Col>
                    <SelectInput
                      name={"timeSlot"}
                      type={"select"}
                      disabled={!enableReservation}
                      onChange={(e: any) => {
                        const value = e.target.value;
                        props.setFieldValue("timeSlot", value);
                        const capacity = capacities?.get(value) ?? 0;
                        props.setFieldValue("availableSeats", capacity);
                        setHasCapacity(capacity > 0);
                      }}
                      required={true}
                    >
                      {lunchTimeSlots?.map((slot) => (
                        <option value={slot} key={slot}>
                          {slot}
                        </option>
                      ))}
                    </SelectInput>
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12} lg={6} xl={5} className={csx({
                'd-none': configuration?.maxGuests === 0
              })}>
                <FormGroup row>
                  <Label xs="auto">
                    Numero ospiti aggiuntivi (max. {configuration?.maxGuests}):
                  </Label>
                  <Col>
                    <TextInput
                      name={"guests"}
                      type={"number"}
                      disabled={!enableReservation}
                      required={true}
                      onChange={(e: any) => {
                        const maxGuests = configuration?.maxGuests ?? 0;
                        let val = Number.parseInt(e.target.value);
                        if (val < 0) val = 0;
                        if (val > maxGuests) val = maxGuests;
                        props.setFieldValue("guests", val);
                      }}
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col xs={12} lg={6} xl={3}>
                <FormGroup row>
                  <Label xs="auto">Posti rimanenti:</Label>
                  <Col>
                    <TextInput name={"availableSeats"} disabled={true} type="text"/>
                  </Col>
                </FormGroup>
              </Col>
            </Row>

            <FormGroup row className={"justify-content-end"}>
              <Col sm={"auto"}>
                {reservation && handleDelete && enableDeleteReservation && (
                  <Button type="button" color="danger" className="mr-2" onClick={() => handleDelete(reservation)}>
                    Cancella prenotazione
                  </Button>
                )}
                {
                  (
                    (!reservation && enableReservation) ||
                    (reservation && enableModifyReservation)
                  ) &&
                  <Button
                    disabled={!props.isValid || props.isSubmitting || (!hasCapacity && !reservation)}
                    color="primary"
                    type="submit"
                  >
                    {reservation ? "Modifica" : "Prenota"}
                  </Button>
                }
              </Col>
            </FormGroup>
          </Form>
        }
      </Formik>;
  };
