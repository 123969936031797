import React from "react";
import {Form, FormGroup} from "reactstrap";
import {DbRole, Role, translateDbRole, translateRole,} from "../../../../services/authentication/userAuthentication";
import {isRequired, maxLength, minLength, validPassword,} from "../../../ui/form/fields/validators";
import {PageTitle} from "../../../ui/layout/PageTitle";
import {Formik, FormikProps} from "formik";
import {CheckboxInputRow, FormActions, SelectInputRow, TextInputRow} from "../../../ui/form/fields/input/Input";
import {ResourcePriority, translatePriority} from "../../../../services/resources/resource";

export type DbRoleFormValues = {
  role: string;
};

export type DbRoleFormProps = {
  role: DbRole;
  onSubmit: (values: DbRoleFormValues) => void;
}

export const DbRoleForm: React.VFC<DbRoleFormProps> = ({onSubmit, role}) => {
  return <Formik
    initialValues={{
      role: role,
    }}
    onSubmit={onSubmit}
  >
    {(props: FormikProps<DbRoleFormValues>) =>
      <Form onSubmit={props.handleSubmit}>
        <FormGroup>
          <PageTitle>Imposta un ruolo per l'accesso diretto al database CalabroneDati</PageTitle>
        </FormGroup>
        <FormGroup row>
          <SelectInputRow
            name={"role"}
            label={"Ruolo CalabroneDati"}
            type={"select"}
          >
            {Object.values(DbRole).map(key => <option key={key} value={key}>
              {translateDbRole(key)}
            </option>)}
          </SelectInputRow>
        </FormGroup>
        <FormActions submitLabel="Imposta ruolo"/>
      </Form>
    }
  </Formik>;
};

