export const toLocalDate = (date: Date) => {
  const d = new Date(date);
  d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
  return d.toISOString().split("T", 1)[0];
};

export const toISODate = (date: Date | string) => {
  if (!date) return date;
  if (typeof date === 'string') date = new Date(date);

  const offset = new Date().getTimezoneOffset();

  const hours = (-Math.floor(offset / 60)).toLocaleString('it-IT',{
    minimumIntegerDigits: 2,
    signDisplay: 'always'
  });
  const minutes = (offset % 60).toLocaleString('it-IT',{
    minimumIntegerDigits: 2,
    signDisplay: 'never'
  });

  const timezone: string = `${hours}:${minutes}`;
  console.log((date as Date).toISOString().replace('Z', ''), timezone)
  return (date as Date).toISOString().replace('Z', '') + timezone;
}
