import {Role} from "../../../../services/authentication/userAuthentication";

export function isAuthorized(
  requiredRoles: Role[],
  userRoles: Role[]
): boolean {
  return (
    requiredRoles.length === 0 ||
    requiredRoles.some((requiredRole) => userRoles.includes(requiredRole))
  );
}
