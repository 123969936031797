import {IconDefinition} from "@fortawesome/pro-solid-svg-icons";
import {Role} from "../services/authentication/userAuthentication";
import {RouteComponentProps, useLocation} from "react-router-dom";

interface ConfigRouteInterface {
  readonly path: string;
  readonly label: string;
  readonly roles: Role[];
  readonly defaultParams?: { [key: string]: any };
  readonly defaultQuery?: { [key: string]: any };
  readonly component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  readonly render?: (props: RouteComponentProps<any>) => React.ReactNode;
  readonly icon?: IconDefinition;
}

export class ConfigRoute implements ConfigRouteInterface {
  readonly path: string = "";
  readonly label: string = "";
  readonly roles: Role[] = [];
  readonly defaultParams?: { [key: string]: any };
  readonly defaultQuery?: { [key: string]: any };
  readonly component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  readonly render?: (props: RouteComponentProps<any>) => React.ReactNode;
  readonly icon?: IconDefinition;

  route(params?: { [key: string]: any }, query?: { [key: string]: any }): string {
    params = {...this.defaultParams, ...params};
    let route = this.path;
    for (const key in params) route = this.path.replace(`:${key}`, params[key]);
    if (this.defaultQuery || query)
      route +=
        "?" +
        Object.entries({...this.defaultQuery, ...query})
          .map(([field, value]) => `${field}=${value}`)
          .join("&");
    return route;
  }
}

export const createRouteConfig = <T extends { [key: string]: ConfigRouteInterface }>(
  config: T
) => {
  const routeConfig: { [key in keyof T]: ConfigRoute } = Object.assign(
    {},
    ...Object.keys(config).map((key) => ({
      [key]: Object.assign(new ConfigRoute(), config[key]),
    }))
  );
  return routeConfig;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
