import React, {useState} from "react";
import {Formik} from "formik";
import {Form, FormGroup} from "reactstrap";
import {CheckboxInputRow, FormActions, SelectInputRow, TextInputRow} from "../../../ui/form/fields/input/Input";
import {
  Resource,
  ResourceCategory,
  ResourcePriority,
  ResourceState,
  translateCategory, translateOptional,
  translatePriority,
  translateState
} from "../../../../services/resources/resource";
import {useGetUsersByRoleQuery} from "../../../../services/users/userService";
import {useGetResourceOptionalsQuery} from "../../../../services/resources/resources-api";
import * as yup from 'yup';
import {Role} from "../../../../services/authentication/userAuthentication";
import {useGetCostCentersQuery} from "../../../../services/accounting/cost-center-api";
import {CostClass} from "../../../../services/accounting/cost-center";
import {InputRow} from "../../../ui/form/fields/input/input-row";
import {CostCentersInput} from "../../reservations/forms/CostCentersInput";

export const useSupervisors = () => {
  const {data: users} = useGetUsersByRoleQuery(Role.RESOURCE_SUPERVISOR);
  return users;
};

export const useOptionals = (category: ResourceCategory) => {
  const {data: optionals} = useGetResourceOptionalsQuery(category);
  return optionals;
};

const useCostCenters = () => {
  const {data: costCenters} = useGetCostCentersQuery({activeOnly: true, costClass: CostClass.M});
  return costCenters;
}

export type ResourceFormValues = Exclude<Resource, 'id'>;

const schemaValidation = yup.object({
  name: yup.string().required('Il campo Nome è obbligatorio'),
  description: yup.string().nullable(),
  costCenter: yup.mixed(),
  supervisorId: yup.number().required('Il campo Responsabile è obbligatorio').integer(),
  category: yup.mixed().oneOf(Object.values(ResourceCategory)),
  state: yup.mixed().oneOf(Object.values(ResourceState)),
  km: yup.number().min(0),
  licensePlate: yup.string().nullable(),
  priority: yup.mixed().oneOf(Object.values(ResourcePriority)),
  optionals: yup.array().ensure(),
  powerSource: yup.string().required('Il campo Alimentazione è obbligatorio'),
});

export type ResourceFormProps = {
  resource?: Resource;
  onSubmit?: (values: ResourceFormValues) => void;
};

export const ResourceForm: React.VFC<ResourceFormProps> = ({resource, onSubmit}) => {

    const [category, setCategory] = useState<ResourceCategory>(ResourceCategory.CAR);

    const supervisors = useSupervisors();
    const costCenters = useCostCenters();
    const optionals = useOptionals(category);

    const initialValues = (resource || {
      name: '', state: ResourceState.AVAILABLE, km: 0, category: ResourceCategory.CAR, optionals: [],
      priority: ResourcePriority.MEDIUM, supervisorId: null
    }) as ResourceFormValues;

    return <Formik
      enableReinitialize={true}
      onSubmit={values => onSubmit?.(schemaValidation.cast(values) as ResourceFormValues)}
      initialValues={initialValues}
      validationSchema={schemaValidation}
    >
      {(props) =>
        <Form onSubmit={props.handleSubmit}>

          <FormGroup row>

            <TextInputRow label={'Nome'} name={'name'}/>
            <SelectInputRow label='Categoria' name={'category'} type='select'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setCategory(e.target.value as ResourceCategory);
                              props.handleChange(e);
                            }}>
              {Object.values(ResourceCategory).map(category => <option key={category} value={category}>
                {translateCategory(category)}
              </option>)}
            </SelectInputRow>

          </FormGroup>

          <FormGroup row>

            <SelectInputRow label='Stato' name={'state'} type='select'>
              {Object.values(ResourceState).map(state => <option key={state} value={state}>
                {translateState(state)}
              </option>)}
            </SelectInputRow>
            <SelectInputRow label='Priorità' name={'priority'} type='select'>
              {Object.values(ResourcePriority).map(priority => <option key={priority} value={priority}>
                {translatePriority(priority)}
              </option>)}
            </SelectInputRow>

          </FormGroup>

          <FormGroup row>

            <SelectInputRow label='Responsabile' name={'supervisorId'} type='select'>
              <option key={''} value=''/>
              {supervisors?.map(({id, name, surname}) => <option key={id} value={id}>
                {name} {surname}
              </option>)}
            </SelectInputRow>
            <TextInputRow label={'Kilometraggio'} name={'km'} type={'number'} />

          </FormGroup>

          <FormGroup row>
            <InputRow label={"Cdc"} forField={"costCenter"}>
              <CostCentersInput name={"costCenter.id"} costCenters={costCenters}/>
            </InputRow>
            <TextInputRow label={'Targa'} name={'licensePlate'} maxLength={10}/>
          </FormGroup>

          <FormGroup row>
            <TextInputRow label={'Alimentazione'} name={'powerSource'} maxLength={20}/>
            <CheckboxInputRow label={'Optional'} name={'optionals'} values={
              optionals?.map(optional => ({value: optional, label: translateOptional(optional)})) ?? []
            }/>
          </FormGroup>

          <FormGroup row>
            <TextInputRow label={"Descrizione"}
                          labelWidth={2} colWidth={12}
                          maxLength={200}
                          name={'description'} type={"textarea"} rows={5}/>
          </FormGroup>
          <FormActions submitLabel={resource ? 'Modifica risorsa' : 'Crea risorsa'}/>
        </Form>
      }
    </Formik>;
  }
;
