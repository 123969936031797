import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../helpers/store";
import {getAllRoles, Role, UserAuthentication,} from "../../services/authentication/userAuthentication";

export const getLoggedUser = (state: RootState) =>
  state.authentication.loggedUser;
export const getRoles = (state: RootState) =>
  getAllRoles(state.authentication.loggedUser?.roles) ?? [];

export const isAuthenticated = createSelector(getLoggedUser, user => !!user);
export const currentUser = createSelector(getLoggedUser, user => user);

export const makeAuthorizationSelector = () => {
  return createSelector([getRoles], (roles?: Role[]) => roles);
};

export const makeLoggedUserSelector = () => {
  return createSelector([getLoggedUser], (user?: UserAuthentication) => user);
};

export const makeLoggedUserIdSelector = () => {
  return createSelector(
    [getLoggedUser],
    (user?: UserAuthentication) => user?.id
  );
};

export const makeIsAuthorizedSelector = (requiredRoles: Role[]) => {
  return createSelector([getRoles], (roles?: Role[]) => {
    return (
      requiredRoles.length === 0 ||
      requiredRoles.filter((role) => roles?.includes(role)).length > 0
    );
  });
};


export const isAuthorizedSelector = createSelector([getRoles],
  (requiredRoles: Role[]) => requiredRoles.length === 0 ||
    requiredRoles.filter((role) => requiredRoles?.includes(role)).length > 0
);

export const canChangeRolesSelector = createSelector([getRoles],
  (roles?: Role[]) => roles?.includes(Role.ADMIN)
);
