import React from "react";
import {useSelector} from "react-redux";
import {Card} from "reactstrap";
import {canChangeRolesSelector} from "../../login/selectors";
import {
  useCurrentUserQuery,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
  useUpdateUserRolesMutation
} from "../../../services/users/userService";
import {User} from "../../../services/users";
import {UserUpdatePasswordForm, UserUpdateRolesForm} from "./forms/user-account-form";
import {UserDataForm, UserDataFormValues} from "./forms/user-data-form";

export type UserPersonalPageProps = {};

export const UserPersonalPage: React.VFC<UserPersonalPageProps> = () => {
  const canModifyRoles = useSelector(canChangeRolesSelector);

  const {data: user} = useCurrentUserQuery();

  const [updatePassword] = useUpdateUserPasswordMutation();
  const [updateRoles] = useUpdateUserRolesMutation();
  const [updateUser] = useUpdateUserMutation();

  const updateUserData = (values: UserDataFormValues) => user && updateUser({...values, id: user.id} as User);

  const changePassword = (values: any) => user && updatePassword({...values, id: user.id});

  const changeRoles = (values: any) => user && updateRoles({...values, id: user.id});

  return (
    <Card body>
      {user ? <UserDataForm onSubmit={updateUserData} user={user}/> : null}
      {user?.roles
        ? <>
          <UserUpdatePasswordForm onSubmit={changePassword}/>
          {canModifyRoles ? <UserUpdateRolesForm onSubmit={changeRoles} roles={user.roles}/> : null}
        </>
        : null}
    </Card>
  );
};
