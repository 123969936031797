import React from "react";
import {Form, FormGroup} from "reactstrap";
import {Role, translateRole,} from "../../../../services/authentication/userAuthentication";
import {isRequired, maxLength, minLength, validPassword,} from "../../../ui/form/fields/validators";
import {PageTitle} from "../../../ui/layout/PageTitle";
import {Formik, FormikProps} from "formik";
import {CheckboxInputRow, FormActions, TextInputRow} from "../../../ui/form/fields/input/Input";

export type PasswordFormValues = {
  password: string;
  confirm: string;
};

export type RolesFormValues = {
  roles: string[];
};

export type NewAccountFormValues = PasswordFormValues & RolesFormValues;

export type NewAccountFormProps = {
  onSubmit: (values: NewAccountFormValues) => void;
}

const PasswordForm: React.VFC = () =>
  <FormGroup row>
    <TextInputRow
      name={"password"}
      type={"password"}
      label={"Password"}
      maxLength={16}
    />
    <TextInputRow
      name={"confirm"}
      type={"password"}
      label={"Conferma pasword"}
      maxLength={16}
    />
  </FormGroup>;

const RolesForm: React.VFC = () =>
  <FormGroup row>
    <CheckboxInputRow
      name={"roles"}
      label={"Ruolo"}
      values={Object.values(Role).map(key => ({label: translateRole(key), value: key}))}
    />
  </FormGroup>;

export const UserAccountForm: React.VFC<NewAccountFormProps> = ({onSubmit}) => {
  return <Formik
    initialValues={{
      roles: [],
      password: '',
      confirm: ''
    }}
    onSubmit={onSubmit}
    validate={validate}
    validateOnChange={true}
  >
    {(props: FormikProps<NewAccountFormValues>) =>
      <Form onSubmit={props.handleSubmit}>
        <FormGroup>
          <PageTitle>Crea un nuovo account</PageTitle>
        </FormGroup>
        <PasswordForm/>
        <RolesForm/>
        <FormActions submitLabel="Crea nuovo account"/>
      </Form>
    }
  </Formik>;
};

export type UpdatePasswordFormProps = {
  onSubmit: (values: PasswordFormValues) => void;
};

export const UserUpdatePasswordForm: React.VFC<UpdatePasswordFormProps> = ({onSubmit}) => {

  return <Formik
    initialValues={{password: '', confirm: ''}}
    onSubmit={onSubmit}
    validate={validatePassword}
    validateOnChange={true}
  >
    {(props: FormikProps<PasswordFormValues>) =>
      <Form onSubmit={props.handleSubmit}>
        <FormGroup>
          <PageTitle>Modifica password utente</PageTitle>
        </FormGroup>
        <PasswordForm/>
        <FormActions submitLabel="Modifica password"/>
      </Form>
    }
  </Formik>;
};

export type UpdateRolesFormProps = {
  onSubmit: (values: RolesFormValues) => void;
  roles: Role[]
};

export const UserUpdateRolesForm: React.VFC<UpdateRolesFormProps> = ({onSubmit, roles}) => {
  return <Formik
    initialValues={{roles: roles.map(role => role.valueOf())}}
    onSubmit={onSubmit}
    validateOnChange={true}
  >
    {(props: FormikProps<RolesFormValues>) =>
      <Form onSubmit={props.handleSubmit}>
        <FormGroup>
          <PageTitle>Modifica ruoli utente</PageTitle>
        </FormGroup>
        <RolesForm/>
        <FormActions submitLabel="Modifica ruoli"/>
      </Form>
    }
  </Formik>;
};

const validatePassword = (values: PasswordFormValues) => {
  const errors: Record<string, any> = {};

  isRequired(values, "password", errors);
  minLength(values, "password", 6, errors);
  maxLength(values, "password", 16, errors);
  validPassword(values, "password", errors);

  isRequired(values, "confirm", errors);
  if (values.confirm !== values.password)
    errors["confirm"] =
      "La password di conferma deve essere uguale alla password.";

  return errors;
};

export const validate = (values: NewAccountFormValues) => validatePassword(values as PasswordFormValues);
