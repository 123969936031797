import React from "react";
import {Card} from "reactstrap";
import {PageTitle} from "../../ui/layout/PageTitle";
import {ResourceForm, ResourceFormValues} from "./forms/resource-form";
import {useCreateResourceMutation} from "../../../services/resources/resources-api";
import {useAppDispatch} from "../../../helpers/store";
import {errorAlert, successAlert} from "../../ui/alerts/reducer";
import {useHistory} from "react-router-dom";
import {routeConfig} from "../../../helpers/routes";

const useCreateResource = () => {
  const history = useHistory();
  const [create] = useCreateResourceMutation();
  const dispatch = useAppDispatch();

  const {resourceDetails} = routeConfig;

  return (resource: ResourceFormValues) => {
    create(resource).unwrap()
      .then(id => {
        dispatch(successAlert('Risorsa creata con successo'));
        history.push(resourceDetails.route({id}));
      })
      .catch(error => dispatch(errorAlert(`Errore durante la creazione di una nuova risorsa: ${error.data.message}`)));
  }
};


export const NewResource: React.VFC = () => {

  const createResource = useCreateResource();

  return (
    <Card body>
      <PageTitle>Nuova risorsa</PageTitle>
      <ResourceForm onSubmit={createResource}/>
    </Card>
  );
};
