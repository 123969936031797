import {faSquareFull, faUtensils} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {Card, Col, Row} from "reactstrap";
import {useAppDispatch} from "../../../helpers/store";
import {addDays, isWeekend, toLocalDate} from "../../../services/date";
import {Configuration, Reservation} from "../../../services/mealReservation";
import {actions as alertActions} from "../../ui/alerts";
import {Calendar, firstDayOfWeek, lastDayOfWeek,} from "../../ui/calendar/Calendar";
import {PageTitle} from "../../ui/layout/PageTitle";
import {ReservationForm, ReservationFormValues} from "./forms/ReservationForm";
import {ReservationCell} from "./ReservationCell";
import {useGetConfigurationsBetweenDatesQuery} from "../../../services/mealReservation/configurationService";
import {useCurrentUserQuery} from "../../../services/users/userService";
import {
  useCreateReservationMutation, useDeleteReservationMutation,
  useGetReservationCapacitiesQuery,
  useGetReservationsByUserAndDateQuery,
  useUpdateReservationMutation
} from "../../../services/mealReservation/reservationService";

export type MealCalendarProps = {
  rows?: number;
  view?: "week" | "day";
};



const maxDaysForReservation = 15;

const useGetReservations = (from: Date, to: Date) => {
  const {data: currentUser} = useCurrentUserQuery();
  const {data: reservations} = useGetReservationsByUserAndDateQuery({
    userId: currentUser?.id!, from: toLocalDate(from), to: toLocalDate(to)
  }, {skip: !currentUser});
  return new Map(reservations?.map(r => [r.date, r]) ?? [])
};

const useGetConfigurations = (from: Date, to: Date) => {
  const {data: configurations} = useGetConfigurationsBetweenDatesQuery({
    from: toLocalDate(from), to: toLocalDate(to)
  });
  return new Map<string, Configuration>(Object.entries(configurations ?? {}));
}

const useGetCapacities = (date: Date) => {
  const {data: capacities} = useGetReservationCapacitiesQuery(toLocalDate(date));
  return new Map<string, number>(Object.entries(capacities ?? {}));
}

const useUpdateReservation = (reservation: Reservation) => {
  const [updateReservation] = useUpdateReservationMutation();
  const dispatch = useAppDispatch();

  return (values: ReservationFormValues) =>
    updateReservation({
      ...reservation,
      reservationSlot: values.timeSlot,
      seats: values.guests + 1
    }).unwrap()
      .then(() => dispatch(alertActions.successAlert('Prenotazione modificata')))
      .catch(error => dispatch(alertActions.errorAlert(`Errore durante la cancellazione della prenotazione: ${error.data.message}`)));
};

const useDeleteReservation = (reservation: Reservation) => {
  const [deleteReservation] = useDeleteReservationMutation();
  const dispatch = useAppDispatch();

  return () => reservation.id && deleteReservation(reservation.id).unwrap()
      .then(() => dispatch(alertActions.successAlert('Prenotazione cancellata')))
      .catch(error => dispatch(alertActions.errorAlert(`Errore durante la modifica della prenotazione: ${error.data.message}`)));
};

const useCreateReservation = (selectedDate: Date) => {
  const [createReservation] = useCreateReservationMutation();
  const dispatch = useAppDispatch();

  return (values: ReservationFormValues) =>
    createReservation({
      reservationSlot: values.timeSlot,
      date: toLocalDate(selectedDate),
      seats: values.guests + 1
    }).unwrap()
      .then(() => dispatch(alertActions.successAlert('Prenotazione effettuata')))
      .catch(error => dispatch(alertActions.errorAlert(`Errore durante la prenotazione: ${error.data.message}`)));
};

export const MealReservation: React.FC<MealCalendarProps> = ({rows = 3, view = 'week'}) => {
  const daysPerRow = view === "week" ? 7 : 1;
  const today = new Date();

  const [startingDate, setStartingDate] = useState(addDays(today, 1));

  const fromDate = firstDayOfWeek(startingDate);
  const toDate = lastDayOfWeek(addDays(fromDate, (rows! - 1) * daysPerRow!));

  const [selectedDate, setSelectedDate] = useState(
    addDays(today, today.getDay() < 5 ? 1 : 8 - today.getDay())
  );

  const configurations = useGetConfigurations(fromDate, toDate);
  const reservations = useGetReservations(fromDate, toDate);
  const capacities = useGetCapacities(selectedDate);

  const configuration = configurations?.get(toLocalDate(selectedDate));
  const reservation = reservations?.get(toLocalDate(selectedDate));

  const createReservation = useCreateReservation(selectedDate);
  const updateReservation = useUpdateReservation(reservation ?? {date: '', seats: 0, reservationSlot: ''});
  const deleteReservation = useDeleteReservation(reservation ?? {date: '', seats: 0, reservationSlot: ''} );

  const moveCalendar = (offset: number) => {
    setStartingDate(addDays(startingDate, offset));
  };

  const disableCell = (day: Date) =>
    toLocalDate(day) < toLocalDate(today) || // no prenotazioni per il giorno stesso o giorni precedenti
    isWeekend(day) || // no sabato o domenica
    toLocalDate(day) >= toLocalDate(addDays(today, maxDaysForReservation));
  // no a prenotazioni successive a maxDaysForReservation giorni

  return (
    <>
      <Card body>
        <PageTitle>I miei pasti</PageTitle>
        <Row>
          <Col>
            <p>
              Seleziona la data desiderata per prenotare un pasto in mensa,
              compilando i dati sottostanti.
            </p>
          </Col>
          <Col className="text-right mb-4">
            Oggi:{" "}
            <FontAwesomeIcon
              icon={faSquareFull}
              className="text-primary border"
            />
            <br/>
            Selezionato:{" "}
            <FontAwesomeIcon
              icon={faSquareFull}
              className="text-secondary border"
            />
            <br/>
            Prenotabile:{" "}
            <FontAwesomeIcon
              icon={faSquareFull}
              className="text-white border"
            />
            <br/>
            Non prenotabile:{" "}
            <FontAwesomeIcon icon={faSquareFull} className="text-gray border"/>
            <br/>
            Pasto prenotato: <FontAwesomeIcon icon={faUtensils}/>
          </Col>
        </Row>
        <Calendar
          from={startingDate}
          data={reservations}
          rows={rows}
          daysPerRow={daysPerRow}
          onSelect={setSelectedDate}
          selectedDate={selectedDate}
          previous={() => moveCalendar(-7)}
          next={() => moveCalendar(7)}
          cell={ReservationCell}
          disableCell={disableCell}
        />
        <ReservationForm
          reservation={reservation}
          handleCreate={createReservation}
          handleUpdate={updateReservation}
          handleDelete={deleteReservation}
          date={selectedDate}
          capacities={capacities}
          configuration={configuration}
          enableDeleteReservation={toLocalDate(selectedDate) !== toLocalDate(today)}
          enableReservation={toLocalDate(selectedDate) !== toLocalDate(today)}
          enableModifyReservation={toLocalDate(selectedDate) !== toLocalDate(today)}
        />
      </Card>
    </>
  );
};
