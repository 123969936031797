import {faMinusCircle, faPlusCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Button, Col, Form, FormGroup, Row} from "reactstrap";
import {FieldArray, Formik, FormikProps} from "formik";
import {FormActions, TextInputRow} from "../../../ui/form/fields/input/Input";
import {ResourcePermanentUnavailability} from "../../../../services/resources/resource-permanent-unavailability";
import {InputRow} from "../../../ui/form/fields/input/input-row";
import {Dropdown} from "primereact/dropdown";
import csx from "classnames";
import styles from "../../../meal-reservation/configuration/MealConfiguration.module.scss";

export type PermanentUnavailabilitiesFormValues = {
  unavailabilities: Pick<ResourcePermanentUnavailability, 'from' | 'to' | 'dayOfTheWeek'>[];
}

export type PermanentUnavailabilitiesFormProps = {
  unavailabilities: ResourcePermanentUnavailability[];
  onSubmit: (values: PermanentUnavailabilitiesFormValues) => void;
  handleDelete?: React.MouseEventHandler<any>;
};

const days = [
  {number: 0, day: 'Lunedì'},
  {number: 1, day: 'Martedì'},
  {number: 2, day: 'Mercoledì'},
  {number: 3, day: 'Giovedì'},
  {number: 4, day: 'Venerdì'},
  {number: 5, day: 'Sabato'},
  {number: 6, day: 'Domenica'}
];

export const PermanentUnavailabilitiesForm: React.FC<PermanentUnavailabilitiesFormProps> =
  ({onSubmit, unavailabilities, handleDelete}) => {

    const defaultValues: PermanentUnavailabilitiesFormValues = {unavailabilities: unavailabilities};
    return (
      <Formik
        initialValues={defaultValues}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<PermanentUnavailabilitiesFormValues>) =>
          <Form onSubmit={props.handleSubmit}>
            <FieldArray name="unavailabilities"
                        render={helper =>
                          <>
                            <Row>
                              <Col sm={12}>
                                {props.values.unavailabilities
                                  .map((u, index) =>
                                    <FormGroup row key={index}>
                                      <Col sm={11}>
                                        <Row>
                                          <InputRow colWidth={4} label="Giorno:">
                                            <Dropdown optionLabel="day" optionValue="number" options={days}
                                                      name={`unavailabilities.${index}.dayOfTheWeek`}
                                                      required={true} onChange={props.handleChange}
                                                      value={props.values.unavailabilities[index].dayOfTheWeek}
                                            />
                                          </InputRow>
                                          <TextInputRow colWidth={4} label="Da:" type="time"
                                                        name={`unavailabilities.${index}.from`}
                                                        required={true}/>
                                          <TextInputRow colWidth={4} label="A:" type="time"
                                                        name={`unavailabilities.${index}.to`}
                                                        required={true}/>
                                        </Row>
                                      </Col>
                                      <Col>
                                          <Button
                                            color={"danger"}
                                            type={"button"}
                                            onClick={() => {
                                              helper.remove(index);
                                              props.setFieldTouched(`unavailabilities.${index}`, true)
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faMinusCircle}/>
                                          </Button>
                                      </Col>
                                    </FormGroup>)}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className={csx("justify-content-center", styles.createButton)}>
                                  <Button
                                    onClick={() => helper.push({
                                      dayOfTheWeek: 0,
                                      from: '',
                                      to: ''
                                    })}
                                    color={"primary"}
                                  >
                                    <FontAwesomeIcon icon={faPlusCircle}/> Nuova indisponibilità permanente
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </>
                        }/>
            <FormActions submitLabel={'Salva indisponibilità permanenti'}/>
          </Form>
        }
      </Formik>
    );
  }
;
