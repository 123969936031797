import {Configuration} from "./configuration";
import {api, Tags} from "../api";


const configurationApi = api.injectEndpoints({

  endpoints: (build) => ({

    getDefaultConfiguration: build.query<Configuration, void>({
      query: () => ({
        url: `/mealReservations/configurations/default`,
        method: 'GET'
      }),
      providesTags: (configuration) => [
        {type: Tags.mealConfiguration, id: configuration?.id},
      ],
    }),

    getConfigurations: build.query<Configuration[], void>({
      query: () => ({
        url: `/mealReservations/configurations`,
        method: 'GET'
      }),
      providesTags: (configuration) => [
        {type: Tags.mealConfiguration, id: 'LIST'},
      ],
    }),

    getConfigurationsBetweenDates: build.query<{[key: string]: Configuration}, { from: string, to: string }>({
      query: ({from, to}) => ({
        url: `/mealReservations/configurations/calendar?from=${from}&to=${to}`,
        method: 'GET'
      }),
      providesTags: (configuration) => [
        {type: Tags.mealConfiguration, id: 'LIST'},
      ],
    }),

    updateConfiguration: build.mutation<void, Configuration>({
      query: (configuration) => ({
        url: configuration.default
          ? '/mealReservations/configurations/default'
          : `/mealReservations/configurations/${configuration.id}`,
        body: configuration,
        method: 'PUT'
      }),
      invalidatesTags: (_response, _error, configuration) => [
        {type: Tags.mealConfiguration, id: configuration.id}
      ]
    }),

    createConfiguration: build.mutation<number, Configuration>({
      query: (configuration) => ({
        url: `/mealReservations/configurations`,
        body: configuration,
        method: 'POST'
      }),
      invalidatesTags: (id) => [
        {type: Tags.mealConfiguration, id: id},
        {type: Tags.mealConfiguration, id: 'LIST'}
      ]
    }),

    deleteConfiguration: build.mutation<void, number>({
      query: (id) => ({
        url: `/mealReservations/configurations/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_response, _errors, id) => [
        {type: Tags.mealConfiguration, id: id},
        {type: Tags.mealConfiguration, id: 'LIST'}
      ]
    }),

  })

});

export const {
  useGetDefaultConfigurationQuery,
  useGetConfigurationsQuery,
  useGetConfigurationsBetweenDatesQuery,
  useCreateConfigurationMutation,
  useUpdateConfigurationMutation,
  useDeleteConfigurationMutation
} = configurationApi;
