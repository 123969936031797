import {faMinusCircle, faPlusCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Button, Col, Form, FormGroup, InputGroup, Row} from "reactstrap";
import {FieldArray, Formik, FormikProps} from "formik";
import {FormActions, TextInputRow} from "../../../ui/form/fields/input/Input";
import {
  HydratedResourceTemporaryUnavailability,
  hydrateTemporaryUnavailability,
  ResourceTemporaryUnavailability
} from "../../../../services/resources/resource-permanent-unavailability";
import csx from "classnames";
import styles from "../../../meal-reservation/configuration/MealConfiguration.module.scss";
import {Calendar} from "primereact/calendar";
import {InputRow} from "../../../ui/form/fields/input/input-row";

export type TemporaryUnavailabilitiesFormValues = {
  unavailabilities: HydratedResourceTemporaryUnavailability[];
}

export type PermanentUnavailabilitiesFormProps = {
  unavailabilities: ResourceTemporaryUnavailability[];
  onSubmit: (values: TemporaryUnavailabilitiesFormValues) => void;
  handleDelete?: React.MouseEventHandler<any>;
};

export const TemporaryUnavailabilitiesForm: React.FC<PermanentUnavailabilitiesFormProps> =
  ({onSubmit, unavailabilities, handleDelete}) => {

    const defaultValues: TemporaryUnavailabilitiesFormValues = {
      unavailabilities: unavailabilities.map(hydrateTemporaryUnavailability)
    };
    return (
      <Formik
        initialValues={defaultValues}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<TemporaryUnavailabilitiesFormValues>) =>
          <Form onSubmit={props.handleSubmit}>
            <FieldArray name="unavailabilities"
                        render={helper =>
                          <>
                            <Row>
                              <Col sm={12}>
                                {props.values.unavailabilities
                                  .map((u, index) =>
                                    <FormGroup row key={index}>
                                      <Col sm={11}>
                                        <Row>
                                          <InputRow colWidth={6}  label="Da:" >
                                            <Calendar
                                                required={true}
                                                showTime={true}
                                                showIcon={true}
                                                stepMinute={15}
                                                value={u.from}
                                                onChange={(v) => {
                                                  props.setFieldValue(`unavailabilities.${index}.from`, v.value);
                                                }}
                                                name={`unavailabilities.${index}.from`}
                                            />
                                          </InputRow>
                                          <InputRow colWidth={6}  label="A:" >
                                            <Calendar
                                              required={true}
                                              showTime={true}
                                              showIcon={true}
                                              stepMinute={15}
                                              value={u.to}
                                              onChange={(v) => {
                                                props.setFieldValue(`unavailabilities.${index}.to`, v.value);
                                              }}
                                              name={`unavailabilities.${index}.from`}
                                            />
                                          </InputRow>
                                        </Row>
                                      </Col>
                                      <Col>
                                          <Button
                                            color={"danger"}
                                            type={"button"}
                                            onClick={() => {
                                              helper.remove(index);
                                              props.setFieldTouched(`unavailabilities.${index}`, true)
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faMinusCircle}/>
                                          </Button>
                                      </Col>
                                    </FormGroup>)}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className={csx("justify-content-center", styles.createButton)}>
                                  <Button
                                    onClick={() => helper.push({
                                      from: new Date(),
                                      to: new Date()
                                    } as HydratedResourceTemporaryUnavailability)}
                                    color={"primary"}
                                  >
                                    <FontAwesomeIcon icon={faPlusCircle}/> Nuova indisponibilità temporanea
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </>
                        }/>
            <FormActions submitLabel={'Salva indisponibilità temporanee'}/>
          </Form>
        }
      </Formik>
    );
  }
;
