import {Role, getAllRoles} from "../../../services/authentication/userAuthentication";
import {useSelector} from "react-redux";
import {isAuthenticated} from "../../login/selectors";

export const useAuthorized = (requiredRoles?: Role[], ownedRoles?: Role[]) => {
  const authenticated = useSelector(isAuthenticated);
  if (!authenticated) return false;

  if (!requiredRoles || requiredRoles.length === 0) return true;

  return requiredRoles.filter((role) => getAllRoles(ownedRoles)?.includes(role)).length > 0
}
