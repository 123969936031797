import {Formik, FormikProps} from "formik";
import React, {useState} from "react";
import {Button, Form, FormGroup} from "reactstrap";
import * as yup from "yup";
import {
  Reservation,
  ReservationId,
  RESERVATIONS_MINIMUM_MINUTES
} from "../../../../services/resources/reservation";

import {
  Resource,
  ResourceCategory,
  translateCategory, translateOptional,
} from "../../../../services/resources/resource";
import {
  CheckboxInputRow, DateInput,
  FormActions,
  SelectInputRow,
  TextInputRow,
} from "../../../ui/form/fields/input/Input";
import {useOptionals} from "../../resources/forms/resource-form";
import {InputRow} from "../../../ui/form/fields/input/input-row";
import {useGetCostCentersQuery} from "../../../../services/accounting/cost-center-api";
import {add, differenceInMinutes, isBefore} from "date-fns";
import {CostCentersInput} from "./CostCentersInput";
import {CostClass} from "../../../../services/accounting/cost-center";
import {CalendarChangeParams} from "primereact/calendar";

export type ReservationFormValues = Exclude<Reservation,
  "id" | "resourceId"> & {
  resource?: Pick<Resource, "id">;
};

const useCostCenters = () => {
  const {data: costCenters} = useGetCostCentersQuery({activeOnly: true, costClass: CostClass.C});
  return costCenters;
}


const schemaValidation = yup
  .object({
    category: yup.mixed().oneOf(Object.values(ResourceCategory)),
    costCenter: yup.mixed().required(),

    beginReservation: yup.date().required().min(new Date())
    // .test(v => !!v && toISO(new Date().toISOString()) < v)
    ,
    endReservation: yup.date().required(),

    travelledDistance: yup.number().min(0),
    optionals: yup.array().ensure(),
  });

export type ReservationFormProps = {
  reservation?: Reservation;
  onSubmit?: (values: ReservationFormValues) => void;
  onDelete?: (id: ReservationId) => void;
};

const postponeEndDate = (beginDate: Date | undefined, props: FormikProps<ReservationFormValues>) => {
    if (beginDate) {
      props.setFieldValue('beginReservation', beginDate);

      const difference = differenceInMinutes(props.values.endReservation, beginDate);
      if(difference  < RESERVATIONS_MINIMUM_MINUTES)
        props.setFieldValue('endReservation', add(beginDate, {minutes: RESERVATIONS_MINIMUM_MINUTES}));
    }
}

const maximumDate = (date1: Date, date2: Date) => isBefore(date1, date2) ? date2 : date1;

export const ResourceReservationForm: React.VFC<ReservationFormProps> =
  ({reservation, onSubmit, onDelete}) => {

    const [category, setCategory] = useState<ResourceCategory>(
      ResourceCategory.CAR
    );

    const costCenters = useCostCenters();

    const optionals = useOptionals(category);

    const today = new Date();
    const minBeginReservation = today;
    const minEndReservation = add(minBeginReservation, {minutes: RESERVATIONS_MINIMUM_MINUTES});
    const maxBeginReservation = add(minBeginReservation, {months: 2});
    const maxEndReservation = add(maxBeginReservation, {minutes: RESERVATIONS_MINIMUM_MINUTES});

    const initialValues = (
      reservation
        ? {...reservation}
        : {
          beginReservation: minBeginReservation,
          endReservation: minEndReservation,
          costCenter: undefined,
          category: ResourceCategory.CAR,
          optionals: [],
          travelledDistance: 0,
        }
    ) as ReservationFormValues;

    return (
      <Formik
        enableReinitialize={true}
        onSubmit={(values) => {
          onSubmit?.(schemaValidation.cast(values) as ReservationFormValues);
        }}
        initialValues={initialValues}
        validationSchema={schemaValidation}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <FormGroup row>
              <SelectInputRow
                label="Categoria"
                name={"category"}
                type="select"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCategory(e.target.value as ResourceCategory);
                  props.handleChange(e);
                }}
              >
                {Object.values(ResourceCategory).map((category) => (
                  <option key={category} value={category}>
                    {translateCategory(category)}
                  </option>
                ))}
              </SelectInputRow>
              <TextInputRow
                label={"Risorsa assegnata"}
                name={"resource.name"}
                disabled={true}
              />
            </FormGroup>

            <FormGroup row>
              <InputRow label={"Dal"} forField={"beginReservation"}>
                <DateInput minDate={minBeginReservation}
                           maxDate={maxBeginReservation}
                           onChange={(e: CalendarChangeParams) =>
                             postponeEndDate(Array.isArray(e.value) ? e.value[0] : e.value, props)
                           }
                           stepMinute={15} name={"beginReservation"} showTime showIcon/>
              </InputRow>
              <InputRow label={"Al"} forField={"endReservation"}>
                <DateInput minDate={maximumDate(minEndReservation, add(props.values.beginReservation, {minutes: RESERVATIONS_MINIMUM_MINUTES}))}
                           maxDate={maxEndReservation}
                           stepMinute={15} name={"endReservation"} showTime showIcon/>
              </InputRow>
            </FormGroup>

            <FormGroup row>
              <InputRow label={"Cdc"} forField={"costCenter"}>
                <CostCentersInput name={"costCenter.id"} costCenters={costCenters}/>
              </InputRow>
              {/*<TextInputRow label={"kilometraggio"} name={"travelledDistance"} type={"number"}/>*/}
            </FormGroup>

            <FormGroup row>
              <CheckboxInputRow
                label={"Optional"}
                name={"optionals"}
                values={
                  optionals?.map((optional) => ({
                    value: optional,
                    label: translateOptional(optional),
                  })) ?? []
                }
              />
            </FormGroup>
            <FormActions
              defaultAction={!reservation}
              submitLabel={
                reservation ? "Modifica prenotazione" : "Prenota risorsa"
              }
            >
              {reservation && reservation.id && !reservation.startedAt && onDelete
                ? <Button type="button" color="danger" className="mr-2" onClick={() => onDelete(reservation.id!)}>
                  Cancella prenotazione
                </Button>
                : null}
            </FormActions>
          </Form>
        )}
      </Formik>
    );
  };

