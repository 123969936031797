import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import {Formik} from "formik";
import React from "react";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardFooter, Col, Form, FormFeedback, FormGroup, Input, NavLink} from "reactstrap";
import {useQuery} from "../../../helpers/routeConfig";
import {routeConfig} from "../../../helpers/routes";
import logo from "../../../img/logo-white.png";
import {useRequestResetPasswordMutation} from "../../../services/authentication/authentication";
import styles from "./ResetPassword.module.scss";
import {TextInputGroup} from "../../ui/form/fields/input/Input";

interface RequestResetPasswordValues {
  email: string;
}

export const RequestResetPassword: React.VFC = () => {
  const [resetPassword, {originalArgs, isError, error, isSuccess}] = useRequestResetPasswordMutation();

  const {login} = routeConfig;

  return (<div className={styles.resetPasswordPage}>
    <div className={"col-12 col-sm-8 col-md-8 col-lg-4 col-xl-3"}>
      <img className="mb-5 img-fluid" src={logo} alt="Il Calabrone"/>
      <h2 className="mb-3 text-center text-light">Gestionale</h2>
      {
        <Formik
          initialValues={{email: ""}}
          onSubmit={({email}) => resetPassword(email)}
        >
          {(formikProps) => (
        isSuccess ?
          <Card>
            <CardBody>
              <p>Riceverai una mail all'indirizzo <b>{formikProps.values.email}</b> con le istruzioni per effettuare il reset della
                password.</p>
              <p><Link to={login.route()}>Torna alla pagina di login</Link></p>
            </CardBody>
          </Card> :
          <Form onSubmit={formikProps.handleSubmit}>
            <Card className={"form-groups"}>
              <CardBody>
                <p>Richiedi il reset della password</p>
                <FormGroup row>
                  <Col>
                    <TextInputGroup
                      type="email"
                      placeholder="Indirizzo e-mail"
                      name="email"
                    ></TextInputGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col>
                <Button
                  color="primary"
                  type="submit"
                  block
                  disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}
                >
                  Reset
                </Button>
                  </Col>
                </FormGroup>

                {isError ? <FormGroup row>
                  <Col>
                    {(error as FetchBaseQueryError)?.status === 404
                      ? <FormFeedback>Non è stato trovato alcun utente corrispondente alla mail {originalArgs}</FormFeedback>
                      : <FormFeedback>Il servizio ha riscontrato un errore. Riprovare più tardi o contattare l'amministratore</FormFeedback>
                    }
                  </Col>
                </FormGroup> : null}
              </CardBody>
              <CardFooter>
                <p className={styles.resetPassword}><NavLink
                  tag={Link}
                  to={login.route()}
                >
                  Ritorna alla pagina di login
                </NavLink></p>
              </CardFooter>
            </Card>
          </Form>)}
              </Formik>
      }
    </div>
  </div>);
};
