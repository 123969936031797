import {
  hydrateReservation,
  Reservation,
  ResourceReservationState,
} from "../../../../services/resources/reservation";
import React, {useState} from "react";
import {DataTable, DataTablePFSEvent} from "primereact/datatable";
import {Column} from "primereact/column";
import {
  ReservationPageRequest,
  ReservationsPage,
  useGetReservationsPageQuery,
} from "../../../../services/resources/reservations-api";
import {format, isAfter} from "date-fns";
import {it} from "date-fns/locale";
import {useHistory} from "react-router-dom";
import {Button} from "primereact/button";
import {routeConfig} from "../../../../helpers/routes";
import {translateCategory} from "../../../../services/resources/resource";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/pro-solid-svg-icons";
import {Alert} from "reactstrap";
import {Tag} from "primereact/tag";
import {ReleaseText} from "./release-text";

export type ReservationsTableProps = {
  states: ResourceReservationState[];
  reservationActions?: boolean;
  startReservation?: (reservation: Reservation) => void;
  endReservation?: (reservation: Reservation) => void;
};

const useReservationsList = (request: ReservationPageRequest) => {
  const {data: page, isLoading} = useGetReservationsPageQuery(request);
  return [
    {
      ...page,
      reservations: page?.reservations?.map(hydrateReservation) ?? [],
    },
    isLoading,
  ] as [
    Exclude<ReservationsPage, "reservations"> & {reservations: Reservation[]},
    boolean
  ];
};

export const formatDate = (date: Date) => format(date, "PPp", {locale: it});

export const reservationState = (reservation: Reservation) =>
  reservation.endedAt ? (
    <Tag value="Corsa chiusa" severity={"info"} />
  ) : reservation.startedAt &&
    isAfter(new Date(), reservation.endReservation) ? (
    <Tag value="Corsa in ritardo" severity={"warning"} />
  ) : reservation.startedAt ? (
    <Tag value="Corsa iniziata" severity={"success"} />
  ) : reservation.state === ResourceReservationState.Past ? (
    <Tag value="Corsa non effettuata" severity={"danger"} />
  ) : (
    <Tag value="In attesa" />
  );

export const reservationSeverity = (reservation: Reservation) =>
  reservation.endedAt
    ? "info"
    : reservation.startedAt && isAfter(new Date(), reservation.endReservation)
    ? "warning"
    : reservation.startedAt
    ? "success"
    : reservation.state === ResourceReservationState.Past
    ? "danger"
    : "blue";

export const ReservationsTable: React.VFC<ReservationsTableProps> = ({
  states,
  startReservation,
  endReservation,
  reservationActions = false,
}) => {
  const history = useHistory();

  const {resourceReservationDetails} = routeConfig;

  const [tableParams, setTableParams] = useState<DataTablePFSEvent>({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "name",
    sortOrder: 1,
    filters: {},
    multiSortMeta: [],
  });

  const [reservationPage, isLoading] = useReservationsList({
    states,
    pageNumber: tableParams.page ?? 0,
    pageSize: tableParams.rows,
    sort: [],
  });

  const actionBody = (reservation: Reservation) => (
    <>
      <Button
        className="p-button-text p-button-info"
        label={"Visualizza"}
        icon="pi pi-search"
        onClick={() =>
          history.push(resourceReservationDetails.route({id: reservation.id}))
        }
      />
      {reservationActions ? (
        <>
          {reservation.startedAt && !reservation.endedAt ? (
            <Button
              className="p-button-text ml-2 p-button-danger"
              label={"Chiudi corsa"}
              icon="pi pi-times"
              onClick={() => endReservation && endReservation(reservation)}
            />
          ) : null}
          {!reservation.startedAt ? (
            <Button
              className="p-button-text ml-2 p-button-success"
              label={"Apri corsa"}
              icon="pi pi-check"
              onClick={() => startReservation && startReservation(reservation)}
            />
          ) : null}
        </>
      ) : null}
      <ReleaseText reservation={reservation} />
    </>
  );

  return (
    <DataTable
      value={reservationPage.reservations ?? []}
      lazy
      responsiveLayout="stack"
      dataKey="id"
      paginator
      first={tableParams.first}
      rows={tableParams.rows}
      totalRecords={reservationPage.total}
      onPage={setTableParams}
      onSort={setTableParams}
      sortField={tableParams.sortField}
      sortOrder={tableParams.sortOrder}
      loading={isLoading}
      emptyMessage={
        <Alert color="info">
          <FontAwesomeIcon icon={faInfoCircle} /> Nessuna prenotazione
        </Alert>
      }
    >
      <Column
        field="category"
        header="Categoria"

        body={(reservation: Reservation) =>
          translateCategory(reservation.resource.category)
        }
      />
      <Column field="resource.name" sortable header="Nome" />
      <Column
        field="beginReservation"
        sortable
        header="Dal"
        body={(reservation: Reservation) =>
          formatDate(reservation.beginReservation)
        }
      />
      <Column
        field="endReservation"
        sortable
        header="Al"
        body={(reservation: Reservation) =>
          formatDate(reservation.endReservation)
        }
      />
      <Column field="costCenter.name" sortable header="Centro di costo" />
      <Column header="Stato" body={reservationState} />
      <Column header="Azioni" body={actionBody} />
    </DataTable>
  );
};
