import {useMediaQuery} from "beautiful-react-hooks";

type BootstrapBreakpoints = "xs" | "sm" | "md" | "lg" | "xl";
const mediaQueries: { [breakpoint in BootstrapBreakpoints]: string } = {
  xs: "(min-width: 0px)",
  sm: "(min-width: 576px)",
  md: "(min-width: 768px)",
  lg: "(min-width: 992px)",
  xl: "(min-width: 1200px)",
};

export function useMediaUp(breakpoint: BootstrapBreakpoints): boolean {
  return useMediaQuery(mediaQueries[breakpoint]);
}
