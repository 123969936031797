import {api, Page, PageRequest, ServerPage, Tags} from "../api";
import {Optional, Resource, ResourceCategory, ResourceId} from "./resource";
import {formatISO} from "date-fns";
import {ResourcePermanentUnavailability, ResourceTemporaryUnavailability} from "./resource-permanent-unavailability";

export type SearchResourceRequest = {
  category: ResourceCategory;
  from: Date;
  to: Date;
  distance?: number;
  optionals?: string[];
};


export type ResourcesPage = {
  resources: Resource[];
} & Page;

const resourcesApi = api.injectEndpoints({

  endpoints: (build) => ({

    getAllResources: build.query<Resource[], void>({
        query: () => ({
          url: '/resources',
          method: 'GET'
        }),
        providesTags: [{type: Tags.resources, id: 'LIST'}],
      },
    ),

    getResourcesPage: build.query<ResourcesPage, PageRequest>({
        query: ({pageNumber, pageSize, sort}) => ({
          url: `/resources/page?page=${pageNumber - 1}&size=${pageSize}&` +
            sort.map(s => `sort=${s.field},${s.order}`).join('&'),
          method: 'GET'
        }),
        providesTags: [{type: Tags.resources, id: 'LIST'}],
        transformResponse: (response: ServerPage<Resource>) => ({
          resources: response.content,
          pageNumber: response.number,
          pageSize: response.size,
          total: response.totalElements,
        } as ResourcesPage)
      },
    ),

    getResourceOptionals: build.query<Optional[], ResourceCategory>({
        query: (category) => ({
          url: `/resources/optionals?category=${category}`,
          method: 'GET'
        }),
        providesTags: [{type: Tags.optionals, id: 'LIST'}],
      },
    ),

    getResource: build.query<Resource, ResourceId>({
        query: (id) => ({
          url: `/resources/${id}`,
          method: 'GET'
        }),
        providesTags: (_response, _error, id) => [{type: Tags.resources, id}],
      },
    ),

    createResource: build.mutation<ResourceId, Resource>({
      query: (resource) => ({
        url: `/resources`,
        method: 'POST',
        body: resource
      }),
      invalidatesTags: [{type: Tags.resources, id: 'LIST'}],
    }),

    updateResource: build.mutation<void, Resource>({
      query: (resource) => ({
        url: `/resources/${resource.id}`,
        method: 'PUT',
        body: resource
      }),
      invalidatesTags: (_response, _error, {id}) => [
        {type: Tags.resources, id}, {type: Tags.resources, id: 'LIST'}
      ],
    }),

    searchResource: build.query<Resource[], SearchResourceRequest>({
        query: (request) => {
          return {
            url: `/resources/search`,
            method: 'POST',
            body: {
              category: request.category,
              beginReservation: formatISO(request.from),
              endReservation: formatISO(request.to),
              travelledDistance: request.distance ?? 0,
              requiredOptionals: request.optionals ?? []
            }
          };
        }
      },
    ),

    updatePermanentUnavailabilities: build.mutation<void, { resource: Resource, unavailabilities: ResourcePermanentUnavailability[] }>({
      query: ({resource, unavailabilities}) => ({
        url: `/resources/${resource.id}/permanentUnavailabilities`,
        method: 'PUT',
        body: unavailabilities
      }),
      invalidatesTags: (_response, _error, {resource: {id}}) => [
        {type: Tags.resources, id}, {type: Tags.resources, id: 'LIST'}
      ],
    }),

    updateTemporaryUnavailabilities: build.mutation<void, { resource: Resource, unavailabilities: ResourceTemporaryUnavailability[] }>({
      query: ({resource, unavailabilities}) => ({
        url: `/resources/${resource.id}/temporaryUnavailabilities`,
        method: 'PUT',
        body: unavailabilities
      }),
      invalidatesTags: (_response, _error, {resource: {id}}) => [
        {type: Tags.resources, id}, {type: Tags.resources, id: 'LIST'}
      ],
    }),

  }),

});

export const {
  useGetAllResourcesQuery,
  useGetResourcesPageQuery,
  useGetResourceQuery,
  useGetResourceOptionalsQuery,
  useLazySearchResourceQuery,
  useCreateResourceMutation,
  useUpdateResourceMutation,
  useUpdatePermanentUnavailabilitiesMutation,
  useUpdateTemporaryUnavailabilitiesMutation,
} = resourcesApi;
