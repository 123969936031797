import {faPlusCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import csx from "classnames";
import React, {Fragment, useEffect, useState} from "react";
import {Button, Card, Col, Row} from "reactstrap";
import {useAppDispatch} from "../../../helpers/store";
import {Configuration} from "../../../services/mealReservation";
import {actions as alertActions} from "../../ui/alerts";
import {PageTitle} from "../../ui/layout/PageTitle";
import {ConfigurationForm} from "./forms/ConfigurationForm";
import styles from "./MealConfiguration.module.scss";
import {
  useCreateConfigurationMutation,
  useDeleteConfigurationMutation,
  useGetConfigurationsQuery,
  useUpdateConfigurationMutation,
} from "../../../services/mealReservation/configurationService";

export const ReservationConfiguration: React.VFC = () => {
  const dispatch = useAppDispatch();

  const {data: configurations} = useGetConfigurationsQuery();
  const [updateConfiguration] = useUpdateConfigurationMutation();
  const [createConfiguration] = useCreateConfigurationMutation();
  const [deleteConfiguration] = useDeleteConfigurationMutation();

  const [defaultConfiguration, setDefaultConf] = useState<Configuration>();
  const [temporaryConfigurations, setTemporaryConfigurations] = useState<Configuration[]>([]);

  const [newConf, setNewConf] = useState<Configuration>();

  const updateAction = (id: number) => (c: Configuration) => updateConfiguration({id, ...c}).unwrap()
    .then(() => dispatch(alertActions.successAlert("Configurazione modificata correttamente.")))
    .catch(error => dispatch(alertActions.errorAlert(`Errore durante la modifica della configurazione pasto: ${error}`)));

  const createOnSubmit = (c: Configuration) =>
    createConfiguration(c).unwrap()
      .then(() => dispatch(alertActions.successAlert("Configurazione creata correttamente.")))
      .then(() => setNewConf(undefined))
      .catch(error => dispatch(alertActions.errorAlert(`Errore durante la creazione della configurazione pasto: ${error}`)));

  const deleteAction = (id: number) => deleteConfiguration(id).unwrap()
    .then(() => dispatch(alertActions.successAlert("Configurazione cancellata correttamente.")))
    .catch(error => dispatch(alertActions.errorAlert(`Errore durante la cancellazione di una configurazione pasto: ${error}`)));

  const addNewConf = () => newConf || setNewConf({slots: [], maxGuests: 0});

  useEffect(() => {
    if (configurations) {
      setDefaultConf(configurations.find(conf => conf.default));
      setTemporaryConfigurations(configurations.filter(conf => !conf.default));
    }
  }, [configurations, setDefaultConf, setTemporaryConfigurations]);

  return (
    <Card body>
      <Row className="mb-4">
        <Col>
          <PageTitle>Configurazione standard</PageTitle>
          {defaultConfiguration
            ? <ConfigurationForm onSubmit={updateAction(defaultConfiguration.id!)}
                                 configuration={defaultConfiguration}/>
            : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <PageTitle>Configurazioni temporanee</PageTitle>
          {temporaryConfigurations.map((conf, index) => (
            <Fragment key={index}>
              <ConfigurationForm
                configuration={conf}
                onSubmit={updateAction(conf.id!)}
                handleDelete={() => deleteAction(conf.id!)}
              />
              <h3 key={`sep${conf.id}`} className={csx("border-bottom", styles.formHeader)}>
                &nbsp;
              </h3>
            </Fragment>
          ))}
          {newConf ? <ConfigurationForm configuration={newConf} onSubmit={createOnSubmit}/> : null}
        </Col>
      </Row>
      <div className={csx("justify-content-center", styles.createButton)}>
        <Button
          disabled={
            temporaryConfigurations.length > 0 &&
            temporaryConfigurations[temporaryConfigurations.length - 1].id === undefined
          }
          onClick={addNewConf}
          color={"primary"}
        >
          <FontAwesomeIcon icon={faPlusCircle}/> Nuova configurazione
        </Button>
      </div>
    </Card>
  );
};
