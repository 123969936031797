import React, {useState} from "react";
import {Link} from "react-router-dom"; // TODO: usare il custom Link
import {Card} from "reactstrap";
import {useQuery} from "../../../helpers/routeConfig";
import {routeConfig} from "../../../helpers/routes";
import {translateRole} from "../../../services/authentication/userAuthentication";
import {User} from "../../../services/users";
import {PageTitle} from "../../ui/layout/PageTitle";
import {PagedTable} from "../../ui/table/PagedTable";
import {SortEntry} from "../../ui/table/Table";
import {useUsersPageQuery} from "../../../services/users/userService";


export type UsersListProps = {
  pageSize?: number;
};

export const UsersList: React.VFC<UsersListProps> = ({pageSize = 10}) => {
  const pageQueryValue = Number.parseInt(useQuery().get("page") ?? "1");
  const selectedPage = Number.isNaN(pageQueryValue) ? 1 : pageQueryValue;

  const [settings, setSettings] = useState({
    sort: [{field: "surname", order: "asc"} as SortEntry],
  });

  const {usersList, userPage} = routeConfig;

  const {data: pageContent} = useUsersPageQuery({pageNumber: selectedPage, pageSize: pageSize, sort: settings.sort});

  const columns = [
    {key: "surname", label: "Cognome"},
    {key: "name", label: "Nome"},
    {key: "email", label: "Email"},
    {key: "telephone", label: "Telefono"},
    {
      key: "roles",
      label: "Ruolo",
      sortable: false,
      render: (user: User) => (
        <td key={"roles"}>
          {user.roles ? user.roles.map(translateRole).join(", ") : "-"}
        </td>
      ),
    },
    {
      key: "actions",
      label: "Azioni",
      sortable: false,
      render: (user: User) => (
        <td key={"actions"}>
          <Link to={userPage.route({id: user.id})}>Visualizza</Link>
        </td>
      ),
    },
  ];

  const sort = (s: any) => setSettings({sort: s});

  return (
    <Card body>
      <PageTitle>Lista utenti</PageTitle>
      <PagedTable
        columns={columns}
        sort={settings.sort}
        onSort={sort}
        rows={pageContent?.users ?? []}
        size={pageSize}
        total={pageContent?.total ?? 0}
        baseRoute={usersList}
        page={selectedPage}
      />
    </Card>
  );
};
