import React, {useState} from 'react';
import {Card} from "reactstrap";
import {PageTitle} from "../../ui/layout/PageTitle";
import {PagedTable} from "../../ui/table/PagedTable";
import {useQuery} from "../../../helpers/routeConfig";
import {SortEntry} from "../../ui/table/Table";
import {routeConfig} from "../../../helpers/routes";
import {Link} from "react-router-dom";
import {useGetResourcesPageQuery} from "../../../services/resources/resources-api";
import {PageRequest} from "../../../services/api";
import {Resource, translateCategory, translatePriority, translateState} from "../../../services/resources/resource";
import {useSupervisors} from "./forms/resource-form";

export type ResourcesListProps = {
  pageSize?: number;
};

const useResourcesList = (request: PageRequest) => {
  const {data: resources} = useGetResourcesPageQuery(request);
  return resources;
}

export const ResourcesList: React.VFC<ResourcesListProps> = ({pageSize = 10}) => {

  const supervisors = useSupervisors();

  const supervisorsMap = new Map(supervisors?.map(s => [s.id, s]));

  const pageQueryValue = Number.parseInt(useQuery().get("page") ?? "1");
  const selectedPage = Number.isNaN(pageQueryValue) ? 1 : pageQueryValue;

  const [settings, setSettings] = useState({
    sort: [{field: "name", order: "asc"} as SortEntry],
  });

  const {resourcesList, resourceDetails} = routeConfig;

  const resourcesPage = useResourcesList({
    pageNumber: selectedPage, pageSize, sort: settings.sort
  });

  const columns = [
    {key: "category", label: "Categoria", sortable: false, render: (resource: Resource) =>
        <td key={"state"}>
          {resource.category ? translateCategory(resource.category) : "-"}
        </td>},
    {key: "name", label: "Nome"},
    {key: "description", label: "Descrizione"},
    {
      key: "priority", label: "Priorità", sortable: false, render: (resource: Resource) =>
        <td key={"state"}>
          {resource.priority ? translatePriority(resource.priority) : "-"}
        </td>
    },
    {
      key: "state", label: "Stato attuale", sortable: false, render: (resource: Resource) =>
        <td key={"state"}>
          {resource.state ? translateState(resource.state) : "-"}
        </td>
    },
    {
      key: "supervisor", label: "Responsabile", sortable: false, render: (resource: Resource) =>
        <td key={"state"}>
          {supervisorsMap.get(resource.supervisorId)?.name}&nbsp;
          {supervisorsMap.get(resource.supervisorId)?.surname}
        </td>
    },
    {
      key: "actions",
      label: "Azioni",
      sortable: false,
      render: (resource: Resource) => (
        <td key={"actions"}>
          <Link to={resourceDetails.route({id: resource.id})}>Visualizza</Link>
        </td>
      ),
    },
  ];

  const sort = (s: any) => setSettings({sort: s});

  return <Card body>
    <PageTitle>Lista risorse</PageTitle>
    <PagedTable
      columns={columns}
      sort={settings.sort}
      onSort={sort}
      rows={resourcesPage?.resources ?? [] as Resource[]}
      size={pageSize}
      total={resourcesPage?.total ?? 0}
      baseRoute={resourcesList}
      page={selectedPage}
    />
  </Card>;
};
