import {faClock} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {VFC} from "react";
import {Alert, Card} from "reactstrap";
import {PageTitle} from "../ui/layout/PageTitle";
import {useGetAllNewsQuery} from "../../services/news";

const dateFormatter = new Intl.DateTimeFormat("it-IT", {
  year: "numeric",
  month: "long",
  day: "numeric",
});

export const NewsList: VFC = () => {

  const {data: news} = useGetAllNewsQuery();

  return (
    <Card body>
      <PageTitle>Avvisi</PageTitle>
      {
        (news ?? []).map(n => (<Alert key={n.id} color={n.level} fade={false}>
          <h4 className="d-flex">
            <span>{n.title}</span>
            <small className="ml-auto">
              <FontAwesomeIcon icon={faClock}/>
              {dateFormatter.format(new Date(n.date))}
            </small>
          </h4>
          <p className="mb-0">{n.description}</p>
        </Alert>))
      }
    </Card>
  );
};
