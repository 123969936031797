import {IconDefinition} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, {VFC} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink as RRNavLink, useRouteMatch} from "react-router-dom";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {useMediaUp} from "../../../../helpers/useMediaUp";
import {Role} from "../../../../services/authentication/userAuthentication";
import {getRoles} from "../../../login/selectors";
import styles from "../side-nav/SideNav.module.scss";
import {actions, selectors} from "../slice";
import {SubmenuKey} from "../types";
import {isAuthorized} from "./isAuthorized";

interface SubMenuProps {
  name: SubmenuKey;
  label: string;
  icon?: IconDefinition;
  routes: Array<{
    path: string;
    label: string;
    icon?: IconDefinition;
    roles: Role[];
  }>;
}

export const SubMenu: VFC<SubMenuProps> = ({label, icon, name, routes}) => {
  const dispatch = useDispatch();

  const userRoles = useSelector(getRoles);
  const submenuOpen = useSelector(selectors.selectSubmenuOpen);

  const isXs = !useMediaUp("sm");
  const isLgUp = useMediaUp("lg");
  const routeMatch = useRouteMatch({
    path: routes.reduce((previous, route) => {
      return [...previous, route.path];
    }, [] as string[]),
    exact: true,
  });

  const toggle = () => {
    if (!isLgUp && submenuOpen === name) {
      dispatch(actions.closeSubmenu());
    } else if (!isLgUp || !routeMatch) {
      dispatch(actions.openSubmenu({key: name}));
    }
  };

  const handleMouseEnter = () => {
    if (isLgUp && !routeMatch) {
      dispatch(actions.openSubmenu({key: name}));
    }
  };

  const handleMouseOut = () => {
    if (isLgUp) {
      dispatch(actions.closeSubmenu());
    }
  };

  if (!routes.some((route) => isAuthorized(route.roles, userRoles))) {
    return null;
  }

  return (
    <Dropdown
      nav
      inNavbar={isXs}
      className={styles.navItem}
      direction={isXs ? "down" : "right"}
      isOpen={submenuOpen === name}
      toggle={toggle}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseOut}
    >
      <DropdownToggle nav caret className={classNames({active: !!routeMatch})}>
        {icon && <FontAwesomeIcon fixedWidth size="lg" icon={icon}/>}
        {label}
      </DropdownToggle>
      <DropdownMenu>
        {routes.map((route) => {
          if (!isAuthorized(route.roles, userRoles)) {
            return null;
          }
          return (
            <DropdownItem
              key={route.label}
              tag={RRNavLink}
              to={route.path}
              exact
            >
              {route.icon && <FontAwesomeIcon fixedWidth icon={route.icon}/>}
              {route.label}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};
