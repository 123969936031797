import {faSortDown, faSortUp} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import get from "lodash/get";
import React from "react";
import {Table as BSTable} from "reactstrap";

type SortOrder = "asc" | "desc";

export type SortEntry = {field: string; order: "asc" | "desc"};

type TableHeaderProps = {
  onClick?: React.MouseEventHandler<any>;
  isSorted?: false | SortOrder;
};

const TableHeader: React.FC<TableHeaderProps> = ({
  children,
  onClick,
  isSorted,
}) => {
  return (
    <th onClick={onClick}>
      {children}&nbsp;
      {isSorted ? (
        <FontAwesomeIcon icon={isSorted === "asc" ? faSortUp : faSortDown} />
      ) : null}
    </th>
  );
};

TableHeader.defaultProps = {
  isSorted: false,
};

export type TableProps<Entity extends Record<string, any>> = {
  columns: {
    key: string;
    label: string;
    sortable?: boolean;
    render?: (e: Entity) => React.ReactNode;
  }[];
  rows?: Entity[];
  onSort?: (sort: SortEntry[]) => void;
  sort: SortEntry[];
};

export class Table<Entity extends {[key: string]: any}> extends React.Component<
  TableProps<Entity>
> {
  static defaultProps = {
    rows: [],
    sort: [],
  };

  sortBy(field: string) {
    const {sort} = this.props;
    const currentKeySort = sort.find((s) => s.field === field);
    const newFirstSort = {
      field: field,
      order:
        !currentKeySort || currentKeySort.order === "desc" ? "asc" : "desc",
    } as SortEntry;

    this.props.onSort?.(
      [newFirstSort].concat(sort.filter((s) => s.field !== field))
    );
  }

  render() {
    const {rows, columns, sort} = this.props;

    const sortedField: SortEntry =
      (sort?.length ?? 0) > 0
        ? sort![0]
        : {field: columns[0].key, order: "asc"};

    return (
      <BSTable>
        <thead>
          <tr>
            {columns.map((h) => (
              <TableHeader
                key={`${h.key}`}
                onClick={() => h.sortable ?? this.sortBy(h.key)}
                isSorted={
                  sortedField?.field === h.key ? sortedField?.order : false
                }
              >
                {h.label}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((r, row_index) => (
            <tr key={row_index}>
              {columns.map((c, col_index) =>
                c.render ? (
                  c.render(r)
                ) : (
                  <td key={col_index}>{get(r, c.key)}</td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </BSTable>
    );
  }
}
