import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {App} from "./App";
import "./bootstrap.global.scss";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";  //theme
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.min.css";
import "primeicons/primeicons.css";
import {AlertList} from "./components/ui/alerts/AlertList";
import {LoadingOverlay} from "./components/ui/loading-overlay";
import {GuardedRoute} from "./components/ui/router";
import {routeConfig} from "./helpers/routes";
import {store} from "./helpers/store";
import "./style.global.scss";

import {locale, addLocale} from 'primereact/api';
import itLocale from './locale/it.json';
addLocale('it', itLocale);
locale('it');

const {login, resetPassword, requestResetPassword} = routeConfig;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router basename={process.env.PUBLIC_URL}>
        <AlertList/>
        <LoadingOverlay />
        <Switch>
          <Route
            path={login.path}
            exact
            component={login.component}
          />
          <Route
            path={requestResetPassword.path}
            exact
            component={requestResetPassword.component}
          />
          <Route
            path={resetPassword.path}
            exact
            component={resetPassword.component}
          />
          <GuardedRoute path={"/"} component={App}/>
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
