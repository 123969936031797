import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type LoadingState = {
  loading: number;
};

const loadingSlice = createSlice({
  name: "loading",
  initialState: {loading: 0},
  reducers: {
    startLoading: (state: LoadingState, action: PayloadAction) => ({
      loading: state.loading + 1,
    }),
    endLoading: (state: LoadingState, action: PayloadAction) => ({
      loading: state.loading > 0 ? state.loading - 1 : 0,
    }),
  },
});

export const {actions, reducer} = loadingSlice;
export const {startLoading, endLoading} = actions;
