export type UserId = number;

export interface UserAuthentication {
  id?: UserId;
  email: string;
  password?: string;
  roles: Role[];
  dbRole: DbRole;
}

export enum Role {
  ADMIN = "ROLE_ADMIN",
  RESOURCE_SUPERVISOR = "ROLE_RESOURCE_SUPERVISOR",
  COOK = "ROLE_COOK",
  USER_RESOURCE_RESERVATION = "ROLE_USER_RESOURCE",
  USER_MEAL_RESERVATION = "ROLE_USER_MEAL",
  USER = "ROLE_USER"
}

export enum DbRole {
  NoRole = 'NoRole',
  Extern= "Extern",
  Management = "Management",
  Operator = "Operator"
}

const DbRole_IT: { [k in DbRole]: string } = {
  [DbRole.NoRole]: "Nessun accesso",
  [DbRole.Extern]: "Esterni",
  [DbRole.Management]: "Gestionali",
  [DbRole.Operator]: "Operatori",
};

const Role_IT: { [k in Role]: string } = {
  [Role.ADMIN]: "Amministrazione",
  [Role.RESOURCE_SUPERVISOR]: "Supervisore risorse",
  [Role.COOK]: "Gestione cucina",
  [Role.USER_RESOURCE_RESERVATION]: "Prenotazione risorse",
  [Role.USER_MEAL_RESERVATION]: "Prenotazione pasti",
  [Role.USER]: "Utente",
};

type RoleHierarchy = {
  [k in Role]?: Role[];
};

const roleHierarchy: RoleHierarchy = {
  [Role.ADMIN]: [Role.USER, Role.COOK, Role.USER_RESOURCE_RESERVATION, Role.USER_MEAL_RESERVATION, Role.RESOURCE_SUPERVISOR],
  [Role.USER_MEAL_RESERVATION]: [Role.USER],
  [Role.USER_RESOURCE_RESERVATION]: [Role.USER],
  [Role.RESOURCE_SUPERVISOR]: [Role.USER, Role.USER_RESOURCE_RESERVATION],
  [Role.COOK]: [Role.USER_MEAL_RESERVATION, Role.USER],
};

export const getAllRoles = (roles?: Role[]): Role[] | undefined => {
  if (roles) {
    for (let role of roles)
      if (role in roleHierarchy) roles = roles.concat(...roleHierarchy[role]!);
  }
  return roles;
};

export const translateRole = (role: Role) => {
  return Role_IT[role];
};

export const translateDbRole = (role: DbRole) => {
  return DbRole_IT[role];
};
