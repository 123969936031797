import {BaseQueryFn} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {FetchArgs, FetchBaseQueryError,} from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import {createApi, fetchBaseQuery, FetchBaseQueryMeta} from "@reduxjs/toolkit/query/react";
import {authorized, unauthorized} from "../components/login/reducer";
import {endLoading, startLoading} from "../components/ui/loading-overlay/reducer";
import {getRefreshToken} from "../helpers/local-storage";
import {RootState} from "../helpers/store";
import {AuthenticationTokens} from "./authentication/authentication";

const baseUrl = `${process.env.REACT_APP_CALABRONE_API_HOST ?? process.env.PUBLIC_URL}/api`;

const baseQuery = fetchBaseQuery({
  baseUrl,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
});

const addTokenToHeaders =
  (headers: Headers | string[][] | Record<string, string | undefined> | undefined, token?: string) =>
    token ? {...(headers ?? {}), 'Authorization': `Bearer ${token}`} : headers;

const baseQueryWithRefresh: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta> =
  async (args, api, extraOptions) => {
    api.dispatch(startLoading());
    args.headers = addTokenToHeaders(args.headers, (api.getState() as RootState).authentication.token);

    let result = await baseQuery(args, api, extraOptions)


    if (result.error && result.error.status === 401 && args.url !== '/login') {
      const refreshToken = getRefreshToken();

      const refreshResult = await baseQuery({
        url: '/refresh', method: 'POST', body: {token: `Bearer ${refreshToken}`}
      }, api, extraOptions);
      if (!refreshResult.error) {
        const data = refreshResult.data as AuthenticationTokens;
        api.dispatch(authorized(data));
        args.headers = addTokenToHeaders(args.headers, data.token);
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(unauthorized());
      }
    }

    api.dispatch(endLoading());
    return result;
  }


export enum Tags {
  currentUser = 'Login',
  authenticated = 'Authenticated',
  unauthorized = 'Unauthorized',
  error = 'Error',
  user = 'User',
  account = 'Account',
  news = 'News',
  mealConfiguration = 'MealConfiguration',
  mealReservation = 'MealReservation',
  resources = 'Resources',
  optionals = 'ResourceOptionals',
  reservations = 'Reservations',
  costCenters = 'CostCenters'
}


export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithRefresh,
  endpoints: () => ({}),
  tagTypes: Object.values(Tags)
});

export type Page = {
  pageNumber: number;
  total: number;
  pageSize: number;
};

export type PageRequest = { pageNumber: number, pageSize: number, sort: { field: string; order: "asc" | "desc" }[] };

export type ServerPage<T> = {
  content: T[];
  number: number;
  totalElements: number;
  size: number;
};
