import React from "react";
import styles from "./Layout.module.scss";
import {NavBar} from "./NavBar";
import {SideNav} from "./SideNav";

export const Layout: React.FC = ({children}) => {
  return (
    <>
      <header>
        <NavBar/>
      </header>
      <main className={styles.mainContent}>
        <SideNav/>
        <div className={styles.contentWrapper}>{children}</div>
      </main>
    </>
  );
};
