export type ResourceUnavailabilityId = number;

export interface ResourcePermanentUnavailability {
  id?: ResourceUnavailabilityId;
  dayOfTheWeek: number;
  from: string;
  to: string;
}

export interface ResourceTemporaryUnavailability {
  id?: ResourceUnavailabilityId;
  from: string;
  to: string;
}

export interface HydratedResourceTemporaryUnavailability {
  id?: ResourceUnavailabilityId;
  from: Date;
  to: Date;
}

export function hydrateTemporaryUnavailability(unavailability: ResourceTemporaryUnavailability) {
  return {
    id: unavailability.id,
    from: new Date(unavailability.from),
    to: new Date(unavailability.to),
  };
}
