import {IconDefinition} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {VFC} from "react";
import {useSelector} from "react-redux";
import {NavLink as RRNavLink} from "react-router-dom";
import {NavItem, NavLink} from "reactstrap";
import {Role} from "../../../../services/authentication/userAuthentication";
import {getRoles} from "../../../login/selectors";
import styles from "../side-nav/SideNav.module.scss";
import {isAuthorized} from "./isAuthorized";

interface MenuLinkProps {
  route: { path: string; label: string; icon?: IconDefinition; roles: Role[] };
  className?: string
}

export const MenuLink: VFC<MenuLinkProps> = ({route, className}) => {
  const userRoles = useSelector(getRoles);

  if (!isAuthorized(route.roles, userRoles)) {
    return null;
  }

  return (
    <NavItem className={styles.navItem}>
      <NavLink to={route.path} tag={RRNavLink} className={className}>
        {route.icon && (
          <FontAwesomeIcon fixedWidth size="lg" icon={route.icon}/>
        )}
        {route.label}
      </NavLink>
    </NavItem>
  );
};
