import {api, Tags} from "../api";


export type AuthenticationTokens = {
  token: string;
  refreshToken: string;
};

export interface Credentials {
  email: string;
  password: string;
}

const authenticationApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<AuthenticationTokens, Credentials>({
      query: (credentials) => ({
        url: `/login`,
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: [Tags.currentUser],
    }),

    refresh: build.mutation<AuthenticationTokens, string>({
      query: (token) => ({
        url: '/refresh',
        method: 'POST',
        body: token
      }),
      invalidatesTags: [Tags.currentUser]
    }),

    logout: build.mutation<void, void>({
      query: () => ({
        url: '/logout',
        method: 'POST'
      }),
      invalidatesTags: [Tags.currentUser]
    }),

    requestResetPassword: build.mutation<void, string>({
      query: (email) => ({
        url: `/reset?email=${email}`,
        method: 'GET'
      })
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useRefreshMutation,
  useRequestResetPasswordMutation,
  useLogoutMutation
} = authenticationApi;
