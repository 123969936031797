import {faMinusCircle, faPlusCircle, faTrash,} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {ChangeEvent} from "react";
import {Button, Col, Form, FormGroup, Row} from "reactstrap";
import {Configuration} from "../../../../services/mealReservation";
import {Slot} from "../../../../services/mealReservation/configuration";
import styles from "../MealConfiguration.module.scss";
import {FieldArray, Formik, FormikProps} from "formik";
import {TextInputRow} from "../../../ui/form/fields/input/Input";

export type ConfigurationFormValues = Pick<Configuration, 'beginDate' | 'endDate' | 'slots' | 'maxGuests'>;

export type ConfigurationFormProps = {
  configuration: Configuration;
  onSubmit: (values: ConfigurationFormValues) => void;
  handleDelete?: React.MouseEventHandler<any>;
};

const positiveNormalize = (field: string, props: FormikProps<ConfigurationFormValues>) =>
  (event: ChangeEvent<any>) => {
    const number = Number(event.target.value);
    if (!isNaN(number) && number < 0)
      props.setFieldValue(field, 0);
    else
      props.setFieldValue(field, number);
  };

export const ConfigurationForm: React.FC<ConfigurationFormProps> =
  ({onSubmit, configuration, handleDelete}) => {

    if (configuration.slots === undefined || configuration.slots.length === 0)
      configuration.slots = [{slot: "", capacity: 0} as Slot];

    const isDefault = configuration?.default ?? false;

    const defaultValues: ConfigurationFormValues = configuration || {
      beginDate: "",
      endDate: "",
      slots: [{slot: "", capacity: 0}],
      maxGuests: 0
    };

    return (
      <Formik
        initialValues={defaultValues}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<ConfigurationFormValues>) =>
          <Form onSubmit={props.handleSubmit}>
            <Row>
              <Col sm={10}>
                {
                  isDefault ? null :
                    <FormGroup row>
                      <Col sm={10}><Row>
                        <TextInputRow label="Dal giorno:" type="date" name="beginDate" required={true}/>
                        <TextInputRow label="Al giorno:" type="date" name="endDate" required={true}/>
                      </Row></Col>
                    </FormGroup>
                }
                <FormGroup row>
                  <Col sm={10}><Row>
                    <TextInputRow label="Max ospiti:" type="number" name="maxGuests" required={true}
                                  onChange={positiveNormalize('maxGuests', props)}
                    />
                  </Row>
                  </Col>
                </FormGroup>

                <FieldArray name="slots"
                            render={helper =>
                              props.values.slots
                                ? props.values.slots.map((slot, index) =>
                                  <FormGroup row key={index}>
                                    <Col sm={10}>
                                      <Row>
                                        <TextInputRow label="Orario pasto:" type="time" name={`slots.${index}.slot`}
                                                      required={true}/>
                                        <TextInputRow label="Capacità:" type="number" name={`slots.${index}.capacity`}
                                                      required={true}
                                                      onChange={positiveNormalize(`slots.${index}.capacity`, props)}
                                        />
                                      </Row>
                                    </Col>
                                    <Col>
                                      {index === 0 ?
                                        <Button
                                          color={"primary"}
                                          type={"button"}
                                          onClick={() => helper.push('')}
                                        >
                                          <FontAwesomeIcon icon={faPlusCircle}/>
                                        </Button>
                                        :
                                        <Button
                                          color={"danger"}
                                          type={"button"}
                                          onClick={() => helper.remove(index)}
                                        >
                                          <FontAwesomeIcon icon={faMinusCircle}/>
                                        </Button>
                                      }
                                    </Col>
                                  </FormGroup>)
                                : null
                            }
                />

              </Col>

              <Col key={"col3"} sm={2} md={2} lg={2}>
                <Button
                  disabled={
                    props.isSubmitting || !props.isValid || !props.dirty
                  }
                  color={"primary"}
                  type={"submit"}
                >
                  Salva
                </Button>
                {isDefault || !handleDelete ? null : (
                  <Button
                    className={styles.deleteButton}
                    color={"danger"}
                    onClick={handleDelete}
                    type={"button"}
                  >
                    <FontAwesomeIcon icon={faTrash}/>
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        }
      </Formik>
    );
  }
;
