import React from "react";
import {Dialog} from "primereact/dialog";
import {Reservation} from "../../../services/resources/reservation";
import {Button} from "primereact/button";

export type ConfirmLongDistanceReservationDialogProps = {
  reservation: Reservation;
  distance: number;
  onCancel: () => void;
  onConfirm: () => void;
};

const resourceReservationDistanceAlertThreshold = 500;
export function isReservationDistanceOverAlertThreshold(reservation: Reservation, distance: number) {
  return (distance - reservation.resource.km) >= resourceReservationDistanceAlertThreshold;
}

export const ConfirmLongDistanceReservationDialog: React.VFC<ConfirmLongDistanceReservationDialogProps> =
  ({reservation, distance, onCancel, onConfirm}) => {

    const travelledDistance = distance - reservation.resource.km;

    const Footer = () => <div>
      <Button label="Annulla" icon="pi pi-times" onClick={onCancel} className="p-button-danger"/>
      <Button label="Conferma distanza percorsa" icon="pi pi-check" onClick={() => onConfirm()}
              className="p-button-success"/>
    </div>;
 
    return <Dialog header="Conferma chilometraggio" visible={true} onHide={onCancel} footer={Footer} className={"text-center"}>
      <p>
        Il chilometraggio totale inserito per il veicolo {reservation.resource.name} è di {distance}km.
        <br/>
        Risulta che tu abbia viaggiato per {travelledDistance}km.
        <br/>
        <br/>
        Sei sicuro di aver viaggiato per più di {resourceReservationDistanceAlertThreshold}km?
      </p>
    </Dialog>;
  };
