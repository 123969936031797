import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {Role} from "../../../../../services/authentication/userAuthentication";
import React from "react";
import {NavItem, NavLink} from "reactstrap";
import csx from "classnames";
import styles from "./Menu.module.scss";
import {NavLink as RouterLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RootState} from "../../../../../helpers/store";
import {makeIsAuthorizedSelector} from "../../../../login/selectors";
import {connect} from "react-redux";

type MenuLinkProps = {
  icon?: IconProp;
  to: string;
  label: string;
  roles: Role[];
  isAuthorized?: boolean;
};

const _MenuLink: React.FC<MenuLinkProps> = ({
                                              to,
                                              isAuthorized,
                                              icon,
                                              label,
                                            }) => {
  return isAuthorized?.valueOf() ? (
    <NavItem>
      <NavLink
        to={to}
        className={csx(styles.menuItem)}
        activeClassName={styles.active}
        tag={RouterLink}
        exact
      >
        <span className={styles.menuItemText}> {label}</span>
        <span className={styles.subMenuIcon}>
          {icon ? <FontAwesomeIcon icon={icon}/> : <>&nbsp;</>}
        </span>
      </NavLink>
    </NavItem>
  ) : null;
};

const makeMenuLinkStateToProps = () => {
  return (state: RootState, props: MenuLinkProps) => ({
    isAuthorized: makeIsAuthorizedSelector(props.roles)(state),
  });
};

export const MenuLink = connect(makeMenuLinkStateToProps)(_MenuLink);
