import {
  faBell,
  faCalendarAlt,
  faCalendarEdit,
  faCalendarPlus,
  faCalendarStar,
  faCar,
  faHome,
  faIdBadge,
  faSignInAlt,
  faUser,
  faUserCog,
  faUserPlus,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import {NewsList} from "../components/news/NewsList";
import {Home} from "../components/home/Home";
import {ManageWork} from "../components/manage-work/ManageWork";
import {ReservationView} from "../components/reserve-resources/reservations/ReservationView";
import {createRouteConfig} from "./routeConfig";
import {Login} from "../components/login/login";
import {Role} from "../services/authentication/userAuthentication";
import {
  MealReservation,
  ReservationConfiguration,
  ReservationsView,
} from "../components/meal-reservation";
import {ResetPassword} from "../components/login/change-password/ResetPassword";
import {RequestResetPassword} from "../components/login/change-password/RequestResetPassword";
import {UserSettings} from "../components/user-settings/UserSettings";
import {
  NewUserPage,
  UserPage,
  UserPersonalPage,
  UsersList,
} from "../components/users";
import {
  NewResource,
  ResourcesList,
  ResourceDetails,
} from "../components/reserve-resources";
import {NewResourceReservation} from "../components/reserve-resources/reservations/new-resource-reservation";
import {ResourceReservationsList} from "../components/reserve-resources/reservations/ResourceReservationsList";
import {ResourceReservationsScheduler} from "../components/reserve-resources/reservations/ResourceReservationsScheduler";

export const routeConfig = createRouteConfig({
  home: {
    path: "/",
    label: "Home",
    icon: faHome,
    roles: [],
    component: Home,
  },
  login: {
    path: "/login",
    label: "Login",
    icon: faSignInAlt,
    roles: [],
    component: Login,
  },
  requestResetPassword: {
    path: "/requestReset",
    label: "Richiesta reset password",
    roles: [],
    component: RequestResetPassword,
  },
  resetPassword: {
    path: "/resetPassword",
    label: "Reset password",
    roles: [],
    component: ResetPassword,
  },
  news: {
    path: "/avvisi",
    label: "Avvisi",
    icon: faBell,
    roles: [Role.USER],
    component: NewsList,
  },
  manageWork: {
    path: "/ore-lavoro",
    label: "Ore lavoro",
    icon: faCalendarAlt,
    roles: [Role.USER],
    component: ManageWork,
  },
  resourcesList: {
    path: "/risorse",
    label: "Risorse",
    icon: faCar,
    roles: [Role.ADMIN, Role.RESOURCE_SUPERVISOR],
    component: ResourcesList,
  },
  reserveResource: {
    path: "/risorse/nuovaPrenotazione",
    label: "Prenotazione risorse",
    icon: faCar,
    roles: [Role.USER_RESOURCE_RESERVATION],
    component: NewResourceReservation,
  },
  resourceReservationDetails: {
    path: "/risorse/prenotazioni/:id",
    label: "Dettaglio prenotazione",
    icon: faCar,
    roles: [Role.USER_RESOURCE_RESERVATION],
    component: ReservationView,
  },
  reservationsList: {
    path: "/risorse/prenotazioni",
    label: "Le mie prenotazioni",
    roles: [Role.USER_RESOURCE_RESERVATION],
    icon: faCar,
    component: ResourceReservationsList,
  },
  reservationsScheduler: {
    path: "/risorse/scheduler",
    label: "Panoramica risorse",
    roles: [Role.USER_RESOURCE_RESERVATION],
    icon: faCar,
    defaultQuery: {page: 1},
    component: ResourceReservationsScheduler,
  },
  newResource: {
    path: "/risorse/nuova",
    label: "Nuova risorsa",
    icon: faCar,
    roles: [Role.ADMIN, Role.RESOURCE_SUPERVISOR],
    component: NewResource,
  },
  resourceDetails: {
    path: "/risorse/:id",
    label: "Dettaglio risorsa",
    icon: faCar,
    roles: [Role.ADMIN, Role.RESOURCE_SUPERVISOR],
    component: ResourceDetails,
  },
  usersList: {
    path: "/anagrafica",
    label: "Lista utenti",
    roles: [Role.ADMIN],
    icon: faUsers,
    defaultQuery: {page: 1},
    component: UsersList,
  },
  userPersonalPage: {
    label: "Pagina personale",
    path: "/anagrafica/paginaPersonale",
    icon: faIdBadge,
    roles: [Role.USER],
    component: UserPersonalPage,
  },
  userSettings: {
    label: "Impostazioni",
    path: "/anagrafica/impostazioni-utente",
    icon: faUserCog,
    roles: [],
    component: UserSettings,
  },
  newUser: {
    path: "/anagrafica/nuovoUtente",
    label: "Nuovo utente",
    icon: faUserPlus,
    roles: [Role.ADMIN],
    component: NewUserPage,
  },
  userPage: {
    path: "/anagrafica/:id",
    label: "Pagina utente",
    icon: faUser,
    roles: [Role.ADMIN],
    component: UserPage,
  },
  mealReservation: {
    path: "/prenotazionePasti/prenotazione",
    label: "Le mie prenotazioni",
    icon: faCalendarPlus,
    roles: [Role.USER_MEAL_RESERVATION],
    component: MealReservation,
  },
  viewMealReservations: {
    path: "/prenotazionePasti/visualizza",
    label: "Prenotazioni mensa",
    icon: faCalendarStar,
    roles: [Role.COOK, Role.USER_MEAL_RESERVATION],
    component: ReservationsView,
  },
  mealConfiguration: {
    path: "/prenotazionePasti/configurazione",
    label: "Configurazione",
    icon: faCalendarEdit,
    roles: [Role.ADMIN],
    component: ReservationConfiguration,
  },
});

export type RouteConfig = typeof routeConfig;
