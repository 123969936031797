import React from "react";
import {Switch} from "react-router-dom";
import {Layout} from "./components/ui/layout";
import {NotFoundPage} from "./components/ui/not-found/NotFoundPage";
import {GuardedRoute} from "./components/ui/router";
import {routeConfig} from "./helpers/routes";

export const App: React.FC = () => {
  return (
    <Layout>
      <Switch>
        {Object.entries(routeConfig).map(([key, route]) => (
          <GuardedRoute
            key={route.path}
            path={route.path}
            requiredRoles={route.roles}
            component={route.component}
            exact
          />
        ))}
        <GuardedRoute requiredRoles={[]} component={NotFoundPage}/>
      </Switch>
    </Layout>
  );
};
