import React from "react";
import {Dropdown} from "primereact/dropdown";
import {CostCenter} from "../../../../services/accounting/cost-center";
import {FieldHookConfig, FormikProps, useField, useFormik, useFormikContext} from "formik";
import {sortBy} from "lodash";

export type CostCentersInputProps = {
  costCenters?: CostCenter[];
};

export const CostCentersInput: React.VFC<FieldHookConfig<string> & CostCentersInputProps> = ({
                                                                                               costCenters,
                                                                                               ...props
                                                                                             }) => {
  const [field, meta, helper] = useField(props.name);

  const isValid = meta.touched && !meta.error;
  const showError = !!(meta.touched && meta.error);

  if (field.value === null) field.value = undefined;

  const mappedOptions = sortBy(costCenters, ["code"])
    .map(o => ({
      name: `${o.code} - ${o.name}`,
      id: o.id
    })) ?? [];

  return <Dropdown
    {...field}
    onChange={(e) => {
      helper.setValue(e.value);
    }}
    options={mappedOptions}
    optionLabel="name" optionValue="id" filter showClear filterBy="name"
  />
};
