import React, {FC} from "react";
import layoutStyles from "./Layout.module.scss";
import classNames from "classnames";

interface PageTitleProps {
  mt?: boolean;
}

export const PageTitle: FC<PageTitleProps> = ({children, mt}) => {
  return (
    <h3 className={classNames(layoutStyles.pageTitle, {"mt-5": mt})}>
      {children}
    </h3>
  );
};
