import React from "react";
import {ConfigRoute} from "../../../helpers/routeConfig";
import {Pagination} from "../pagination/Pagination";
import {Table, TableProps} from "./Table";

export type PagedTableProps<Entity> = {
  page?: number;
  size?: number;
  baseRoute?: ConfigRoute;
  total?: number;
} & TableProps<Entity>;

export class PagedTable<Entity> extends React.Component<PagedTableProps<Entity>> {
  render() {
    const {
      page = 1,
      size = 10,
      columns,
      baseRoute,
      onSort,
      rows,
      sort,
      total = 0,
    } = this.props;

    return (
      <>
        <Table columns={columns} onSort={onSort} sort={sort} rows={rows}/>
        <Pagination
          baseRoute={baseRoute}
          currentPage={page}
          displayPages={5}
          totalPages={Math.ceil(total / size)}
        />
      </>
    );
  }
}
