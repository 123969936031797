export const isRequired = (
  o: Record<string, any>,
  field: string,
  errors: Record<string, string>
) => {
  if (!o[field]) errors[field] = "Il campo è obbligatorio.";
};

export const minLength = (
  o: Record<string, any>,
  field: string,
  length: number,
  errors: Record<string, string>
) => {
  if (!(!o[field] || `${o[field]}`.length >= length))
    errors[
      field
      ] = `Il campo deve avere una lunghezza minima di ${length} caratteri.`;
};

export const maxLength = (
  o: Record<string, any>,
  field: string,
  length: number,
  errors: Record<string, string>
) => {
  if (!(!o[field] || `${o[field]}`.length <= length))
    errors[
      field
      ] = `Il campo deve avere una lunghezza massima di ${length} caratteri.`;
};

export const pattern = (
  o: Record<string, any>,
  field: string,
  pattern: RegExp,
  errors: Record<string, string>
) => {
  if (!(!o[field] || pattern.test(`${o[field]}`)))
    errors[field] = `Il campo deve rispettare il seguente formato ${pattern}.`;
};

export const allDigits = (
  o: Record<string, any>,
  field: string,
  errors: Record<string, string>
) => {
  if (!(!o[field] || /^[0-9]+$/.test(`${o[field]}`)))
    errors[field] = `Il campo deve contenere solo cifre senza spazi.`;
};

export const allLetters = (
  o: Record<string, any>,
  field: string,
  errors: Record<string, string>
) => {
  if (!(!o[field] || /^[a-zA-Z]+$/.test(`${o[field]}`)))
    errors[field] = `Il campo deve contenere solo lettere senza spazi.`;
};

export const isEmail = (
  o: Record<string, any>,
  field: string,
  errors: Record<string, string>
) => {
  const regexp = new RegExp(
    /^([a-zA-Z0-9._-]+)@(([a-zA-Z\-0-9._]+\.)+[a-zA-Z]{2,})$/
  );
  if (!(!o[field] || regexp.test(`${o[field]}`)))
    errors[field] = `Il campo deve contenere un indirizzo mail valido.`;
};

export const validPassword = (
  o: Record<string, any>,
  field: string,
  errors: Record<string, string>
) => {
  const regexp = new RegExp(
    /^[\w!?@#.,]*$/
  );
  if (!(!o[field] || regexp.test(`${o[field]}`)))
    errors[field] = `La password può contenere lettere, cifre, e i caratteri speciali !?@#.,.`;
}
