import React, {useRef, useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Reservation} from "../../../../services/resources/reservation";
import {formatDate} from "../../../../helpers/datetime";
import {faFileSignature, faPrint} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useReactToPrint} from "react-to-print";
import './release-text.module.scss';

export type ReleaseTextProps = {
  reservation: Reservation
};

export const ReleaseText: React.VFC<ReleaseTextProps> = ({reservation}) => {

  const [showRelease, setShowRelease] = useState(false);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const footer = <Button
      onClick={() => handlePrint()}
      label='Stampa liberatoria'
      icon={<FontAwesomeIcon className='p-button-icon-left' icon={faPrint} />}
    />;

  return (
    <>
      <Button
        onClick={() => setShowRelease(true)}
        icon={<FontAwesomeIcon className='p-button-icon-left' icon={faFileSignature} />}
        className='p-button-text ml-2 p-button-info'
        label='Liberatoria'
      />

      <Dialog
              header={'Liberatoria ' + reservation.resource.name}
              visible={showRelease}
              style={{'max-width': '680px'}}
              onHide={() => setShowRelease(false)}
              footer={footer}
      >
        <div ref={componentRef}>
          <p className='text-justify'>
          Io sottoscritto Alessandro Augelli, in qualità di Legale rappresentante della
          Cooperativa IL CALABRONE - ETS, con sede legale in Viale Duca degli Abruzzi n° 10 Brescia (BS)
          </p>

          <p className='text-center'><strong>certifico che</strong></p>

          <p className='text-justify'>
          {reservation.user.name} {reservation.user.surname} nato
            il {formatDate(reservation.user.birthday)} collabora con la suddetta cooperativa ed è pertanto
            autorizzato dalla stessa all’utilizzo
          dell’automezzo targato {reservation.resource.licensePlate}.
          </p>

          <p className='text-justify'>È responsabilità del conducente garantire il possesso di valida patente di guida.</p>

          <p className='text-justify'>La società si ritiene in ogni caso totalmente esonerata da qualsivoglia responsabilità
            per fatti e/o danni a cose e persone conseguenti al mancato rispetto delle norme di legge e
            ai comportamenti contrari all'ordinaria diligenza posti in essere dal conducente del veicolo.
          </p>
        </div>
      </Dialog>
    </>
  );
}
