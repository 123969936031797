import React, {VFC} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Card} from "reactstrap";
import {
  useDeleteResourceReservationMutation,
  useGetResourceReservationQuery
} from "../../../services/resources/reservations-api";
import {PageTitle} from "../../ui/layout/PageTitle";
import {ResourceReservationForm} from "./forms/reservation-form";
import {hydrateReservation, ReservationId} from "../../../services/resources/reservation";
import {useAppDispatch} from "../../../helpers/store";
import {actions as alertActions} from "../../ui/alerts";
import {routeConfig} from "../../../helpers/routes";

interface ReservationViewProps {}

const useGetReservation = (id: number) => {
  const {data: reservation} = useGetResourceReservationQuery(id);

  return hydrateReservation(reservation);
};

const useDeleteReservation = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {reservationsList} = routeConfig;

  const [deleteReservation] = useDeleteResourceReservationMutation();
  return (id: ReservationId) => deleteReservation(id).unwrap()
    .then(() => {
      dispatch(alertActions.successAlert("Prenotazione cancellata con successo"));
      history.push(reservationsList.route());
    })
    .catch(e => dispatch(alertActions.errorAlert(`Errore durante la cancellazione della prenotazione: {e}`)))
};

export const ReservationView: VFC<ReservationViewProps> = ({}) => {
  const {id} = useParams<{id: string}>();

  const reservation = useGetReservation(parseInt(id, 10));
  const deleteReservation = useDeleteReservation();
  return (
    <Card body>
      <PageTitle>Dettaglio prenotazione risorsa</PageTitle>
      <ResourceReservationForm
        onSubmit={() => {}}
        onDelete={deleteReservation}
        reservation={reservation}
      />
    </Card>
  );
};
