import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch} from "../../../helpers/store";

export enum AlertType {
  INFO = "info",
  SUCCESS = "success",
  ERROR = "danger",
  WARNING = "warning",
}

export type Alert = {
  id?: string;
  message: string;
  type: AlertType;
};

export type AlertsState = {
  alerts: Alert[];
};

const getId = (type: AlertType) => {

  return `${type}-${new Date().valueOf()}`;
};

const alertsSlice = createSlice({
  name: "alerts",
  initialState: {alerts: []} as AlertsState,
  reducers: {
    newAlert: (state: AlertsState, action: PayloadAction<Alert>) => {
      return {
        alerts: state.alerts.concat({
          ...action.payload,
          id: getId(action.payload.type),
        }),
      };
    },
    successAlert: (state: AlertsState, action: PayloadAction<string>) => ({
      alerts: state.alerts.concat({
        message: action.payload,
        type: AlertType.INFO,
        id: getId(AlertType.INFO),
      }),
    }),
    errorAlert: (state: AlertsState, action: PayloadAction<string>) => ({
      alerts: state.alerts.concat({
        message: action.payload,
        type: AlertType.ERROR,
        id: getId(AlertType.ERROR),
      }),
    }),
    warningAlert: (state: AlertsState, action: PayloadAction<string>) => ({
      alerts: state.alerts.concat({
        message: action.payload,
        type: AlertType.WARNING,
        id: getId(AlertType.WARNING),
      }),
    }),
    closeAlert: (
      state: AlertsState,
      action: PayloadAction<string | undefined>
    ) =>
      action.payload
        ? {alerts: state.alerts.filter((alert) => alert.id !== action.payload)}
        : {alerts: []},
  },
});

export const {reducer} = alertsSlice;

const alertTimeout = 5000;

const alertWithTimeout = (type: AlertType) => {
  return (message: string) => {
    const id = getId(type);
    const alert = {id, message, type} as Alert;

    return (dispatch: AppDispatch) => {
      dispatch(alertsSlice.actions.newAlert(alert));

      setTimeout(() => {
        dispatch(alertsSlice.actions.closeAlert(id));
      }, alertTimeout);
    };
  };
};

export const actions = {
  successAlert: alertWithTimeout(AlertType.SUCCESS),
  errorAlert: alertWithTimeout(AlertType.ERROR),
  warningAlert: alertWithTimeout(AlertType.WARNING),
  infoAlert: alertWithTimeout(AlertType.INFO),
  closeAlert: alertsSlice.actions.closeAlert
};

export const {
  successAlert,
  errorAlert,
  warningAlert,
  closeAlert,
} = actions;
