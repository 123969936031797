import React, {useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {Card} from "reactstrap";
import {useAppDispatch} from "../../../helpers/store";
import {Resource, ResourceId} from "../../../services/resources/resource";
import {
  useGetResourceQuery, useUpdatePermanentUnavailabilitiesMutation,
  useUpdateResourceMutation, useUpdateTemporaryUnavailabilitiesMutation,
} from "../../../services/resources/resources-api";
import {errorAlert, successAlert} from "../../ui/alerts/reducer";
import {PageTitle} from "../../ui/layout/PageTitle";
import {ResourceForm, ResourceFormValues} from "./forms/resource-form";
import ReactToPrint, {useReactToPrint} from "react-to-print";
import QRCode from "qrcode.react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {routeConfig} from "../../../helpers/routes";
import {PermanentUnavailabilitiesForm, PermanentUnavailabilitiesFormValues} from "./forms/permanent-unavailabilities-form";
import {
  TemporaryUnavailabilitiesForm,
  TemporaryUnavailabilitiesFormValues
} from "./forms/temporary-unavailabilities-form";
import {ResourceTemporaryUnavailability} from "../../../services/resources/resource-permanent-unavailability";

const useUpdateResource = (id: ResourceId) => {
  const [create] = useUpdateResourceMutation();
  const dispatch = useAppDispatch();

  return (resource: ResourceFormValues) => {
    create({...resource, id}).unwrap()
      .then(() => dispatch(successAlert('Risorsa modificata con successo')))
      .catch(error => dispatch(errorAlert(`Errore durante la modifica della risorsa: ${error.data.message}`)));
  }
};

const useSetPermanentUnavailabilities = () => {
  const [setUnavailabilities] = useUpdatePermanentUnavailabilitiesMutation();
  const dispatch = useAppDispatch();

  return (resource: Resource, {unavailabilities}: PermanentUnavailabilitiesFormValues) => {
    setUnavailabilities({resource, unavailabilities}).unwrap()
      .then(() => dispatch(successAlert('Risorsa modificata con successo')))
      .catch(error => dispatch(errorAlert(`Errore durante la modifica delle indisponibilità: ${error.data?.message}`)));
  }
};


const useSetTemporaryUnavailabilities = () => {
  const [setUnavailabilities] = useUpdateTemporaryUnavailabilitiesMutation();
  const dispatch = useAppDispatch();

  return (resource: Resource, {unavailabilities}: TemporaryUnavailabilitiesFormValues) => {
    const dehydratedUnavailabilities = unavailabilities.map(u => ({
      from: u.from.toISOString(),
      to: u.to.toISOString(),
    }) as ResourceTemporaryUnavailability);
    setUnavailabilities({resource, unavailabilities: dehydratedUnavailabilities}).unwrap()
      .then(() => dispatch(successAlert('Risorsa modificata con successo')))
      .catch(error => dispatch(errorAlert(`Errore durante la modifica delle indisponibilità: ${error.data?.message}`)));
  }
};

const useGetResource = (id: ResourceId) => {
  const {data: resource} = useGetResourceQuery(id);
  return resource;
};


export const ResourceDetails: React.VFC = () => {
  const params = useParams<{ id: string }>();
  const resourceId: ResourceId = Number(params.id);
  const [printQRCode, setPrintQRCode] = useState(false);
  const resource = useGetResource(resourceId);

  const updateResource = useUpdateResource(resourceId);
  const setPermanentUnavailabilities = useSetPermanentUnavailabilities();
  const setTemporaryUnavailabilities = useSetTemporaryUnavailabilities();

  const {reservationsList} = routeConfig;

  const url = window.location.origin + reservationsList.route(undefined, {
    resource: resource?.id
  });

  return (
    <Card body>
      <PageTitle>Dettaglio risorsa {resource?.name}
        <Button style={{
          position: "absolute",
          right: "2rem"
        }} className="p-button-sm" onClick={() => setPrintQRCode(true)}>Stampa QR code</Button>
      </PageTitle>
      <ResourceForm onSubmit={updateResource} resource={resource}/>

      <PageTitle>Indisponibilità permanenti</PageTitle>
      { resource?.permanentUnavailabilities
        ? <PermanentUnavailabilitiesForm unavailabilities={resource.permanentUnavailabilities}
                                         onSubmit={values => setPermanentUnavailabilities(resource, values)} />
        : null
      }
      <PageTitle>Indisponibilità temporanee</PageTitle>
      { resource?.temporaryUnavailabilities
        ? <TemporaryUnavailabilitiesForm unavailabilities={resource.temporaryUnavailabilities}
                                         onSubmit={values => setTemporaryUnavailabilities(resource, values)} />
        : null
      }
      <QRCodeDialog visible={printQRCode}
                    url={url}
                    onHide={() => setPrintQRCode(false)} />
    </Card>
  );
};

type QRCodeDialogProps = {
  visible: boolean;
  onHide: () => void;
  url: string;
};

const QRCodeDialog: React.VFC<QRCodeDialogProps> = ({visible, onHide, url}) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const Footer = <div><Button onClick={handlePrint}>Stampa QR code</Button></div>;

  return <Dialog onHide={onHide} visible={visible} footer={Footer}>
    <div ref={componentRef}>
      <QRCode level="M" size={512} includeMargin={true} value={url} />,
    </div>
  </Dialog>;
};
