import {faArrowLeft} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Link} from "react-router-dom";
import {Button, Col, Container, Row} from "reactstrap";
import Logo from "../../../img/logo-full.png";

export const NotFoundPage = () => (
  <Container className="d-flex h-100 flex-column justify-content-center align-items-center">
    <img
      src={Logo}
      alt="Il Calabrone"
      style={{width: "200px", height: "auto"}}
    />
    <Row>
      <Col className="text-center">
        <h3 className="mb-4">Pagina non trovata ☹</h3>
        <p>
          <Link to="/">
            <Button color="primary">
              <FontAwesomeIcon icon={faArrowLeft}/> Torna alla pagina
              principale
            </Button>
          </Link>
        </p>
      </Col>
    </Row>
  </Container>
);
