import React from "react";
import {Redirect, Route, RouteProps,} from "react-router-dom";
import {Role} from "../../../services/authentication/userAuthentication";
import {routeConfig} from "../../../helpers/routes";
import {useAuthorized} from "./logic";
import {useSelector} from "react-redux";
import {currentUser} from "../../login/selectors";

type GuardedRouteProps = RouteProps & {
  requiredRoles?: Role[];
};

export const GuardedRoute: React.FC<GuardedRouteProps> = ({requiredRoles, ...rest}) => {
  const user = useSelector(currentUser);

  return useAuthorized(requiredRoles, user?.roles)
    ? <Route {...rest} />
    : <Redirect to={{
      pathname: routeConfig.login.route(),
      state: {from: rest.location}
    }}/>;
};
