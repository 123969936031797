import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserAuthentication} from "../../services/authentication/userAuthentication";
import {clear, getUser, storeRefreshToken, storeUser} from "../../helpers/local-storage";
import {AuthenticationTokens} from "../../services/authentication/authentication";

export type AuthenticationState = {
  loggedUser?: UserAuthentication;
  token?: string;
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    loggedUser: getUser(),
  } as AuthenticationState,
  reducers: {
    authorized: (state: AuthenticationState, action: PayloadAction<AuthenticationTokens>) => {
      storeRefreshToken(action.payload.refreshToken);
      return {...state, token: action.payload.token};
    },
    currentUser: (state: AuthenticationState, action: PayloadAction<UserAuthentication>) => {
      storeUser(action.payload);
      return {
        ...state,
        loggedUser: action.payload,
      };
    },
    unauthorized: (state: AuthenticationState, action: PayloadAction) => {
      clear();
      return {};
    }
  }});

export const {actions, reducer} = authenticationSlice;
export const {authorized, unauthorized, currentUser} = actions;
