// Returns valid datetime-local date from a javascript Date object
export function datetimeLocal(_date?: Date) {
  const date = _date?.getDate() ? new Date(_date) : new Date();
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

  return date.toISOString().slice(0, 16);
}

export function formatDate(_date?: Date | string) {
  if (!_date) {
    return _date;
  }

  if (_date instanceof Date) {
    return _date.toLocaleDateString();
  }
  return new Date(Date.parse(_date)).toLocaleDateString();
}
