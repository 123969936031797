import {FieldConfig, FieldHookConfig, useField, useFormikContext} from "formik";
import React from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  InputProps,
  Label,
} from "reactstrap";
import {InputRow, InputRowProps} from "./input-row";
import {AutoComplete, AutoCompleteProps} from "primereact/autocomplete";
import {Calendar, CalendarProps} from "primereact/calendar";

export const TextInput: React.VFC<FieldHookConfig<string> & InputProps> = (
  props
) => {
  const [field, meta] = useField(props.name);

  const isValid = meta.touched && !meta.error;
  const showError = !!(meta.touched && meta.error);

  if (field.value === null) field.value = undefined;

  return (
    <Input
      {...field}
      {...(props as InputProps)}
      invalid={showError}
      valid={isValid}
      title={meta.error}
    />
  );
};


export const DateInput: React.VFC<FieldConfig<string> & CalendarProps> = (
  props
) => {
  const [field, meta] = useField(props.name);

  // const isValid = meta.touched && !meta.error;
  // const showError = !!(meta.touched && meta.error);

  if (field.value === null) field.value = undefined;

  return (
    <Calendar
      {...field}
      {...(props as CalendarProps)}
      />
  );
};

interface TextInputGroupProps extends InputProps {
  name: string;
  label?: string;
}

export const TextInputGroup: React.VFC<TextInputGroupProps> = ({
                                                                 label,
                                                                 ...inputProps
                                                               }) => {
  const [field, meta] = useField(inputProps.name);

  const isValid = meta.touched && !meta.error;
  const showError = !!(meta.touched && meta.error);

  if (field.value === null) {
    field.value = undefined;
  }
  if (inputProps.placeholder === undefined) {
    inputProps.placeholder = label;
  }

  return (
    <FormGroup>
      {label && <Label for={inputProps.id}>{label}</Label>}
      <Input {...field} {...inputProps} invalid={showError} valid={isValid}/>
      <FormFeedback>{meta.error}</FormFeedback>
    </FormGroup>
  );
};

export const SelectInput: React.FC<FieldHookConfig<string> & InputProps> = ({
                                                                              children,
                                                                              ...props
                                                                            }) => {
  const [field, meta] = useField(props.name);

  const isValid = meta.touched && !meta.error;
  const showError = !!(meta.touched && meta.error);

  if (field.value === null) field.value = undefined;

  return (
    <Input
      {...field}
      {...(props as InputProps)}
      invalid={showError}
      valid={isValid}
      title={meta.error}
    >
      {children}
    </Input>
  );
};

export const TextInputRow: React.VFC<InputRowProps & FieldHookConfig<string> & InputProps> =
  ({
     label,
     forField,
     colWidth,
     labelWidth,
     ...props
   }) =>
  <InputRow label={label} colWidth={colWidth} labelWidth={labelWidth}
            forField={forField ?? props.name}><TextInput {...props} /></InputRow>;

export const SelectInputRow: React.VFC<InputRowProps & FieldHookConfig<string> & InputProps> = ({
                                                                                                  label,
                                                                                                  forField,
                                                                                                  colWidth,
                                                                                                  ...props
                                                                                                }) =>
  <InputRow label={label} colWidth={colWidth} forField={forField ?? props.name}><SelectInput {...props} /></InputRow>;

export const RadioInputRow: React.VFC<FieldHookConfig<string> & InputRowProps & { values: { value: string; label: string }[] }> = ({
                                                                                                                                     label,
                                                                                                                                     values,
                                                                                                                                     colWidth,
                                                                                                                                     ...props
                                                                                                                                   }) => {
  const [field] = useField(props.name);

  return <InputRow label={label} {...props}>
    <FormGroup row check>
      {values.map(({value, label}) => (
        <Label sm={"auto"} check key={value}>
          <Input
            {...field}
            name={props.name}
            type={"radio"}
            value={value}
            checked={field.value === value}
          />{" "}
          {label}
        </Label>
      ))}
    </FormGroup>
  </InputRow>;
};

export const CheckboxInputRow: React.VFC<FieldHookConfig<string> &
  InputProps & InputRowProps & { values: { value: any; label: string }[] }> = ({label, forField, colWidth, values, ...props}) => {
  const [field] = useField(props.name);
  const selectedValues: string[] = field.value;

  return <InputRow forField={forField ?? props.name} label={label} {...props}>
          {values.map(({value, label}) => (
            <FormGroup row check key={value}>
              <Label sm={"auto"} check key={value}>
                <Input
                  {...field}
                  name={props.name}
                  type={"checkbox"}
                  value={value}
                  checked={selectedValues?.includes(value)}
                />{" "}
                {label}
              </Label>
            </FormGroup>
          ))}
  </InputRow>;
};

export type FormActionsProps = {
  submitLabel?: string;
  defaultAction?: boolean;
};

export const FormActions: React.FC<FormActionsProps> = ({
                                                          defaultAction = true,
                                                          submitLabel,
                                                          children,
                                                        }) => {
  const formik = useFormikContext();

  return (
    <FormGroup className={"d-flex justify-content-end"}>
      <Col sm={"auto"} className={"p-0"}>
        {children}
        {defaultAction
          ? <Button
            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            color={"primary"}
            type={"submit"}
          >
            {submitLabel ? submitLabel : "Invia"}
          </Button>
          : null}
      </Col>
    </FormGroup>
  );
};

export const AutoCompleteInput: React.VFC<FieldHookConfig<string> & AutoCompleteProps> = ({
                                                                                            ...props
                                                                                          }) => {
  const [field, meta, helper] = useField(props.name);
  const {value, onChange, ...restField} = field;
  // const isValid = meta.touched && !meta.error;
  const showError = !!(meta.touched && meta.error);

  const {suggestions} = props;

  if (field.value === null) field.value = undefined;
  console.log(field, value);
  const selected = suggestions?.filter(s => s.id == value?.id)[0];
  console.log(selected);

  console.log(showError, meta.error);

  return (
    <AutoComplete
      forceSelection={true}
      dropdown={true}
      minLength={3}
      dropdownMode={"current"}
      value={value}
      onChange={e => {
        helper.setValue(e.value);
        // onChange({...e, target: {...e.target, value: e.target.value?.id}, value: e.value?.id});
      }}
      {...restField}
      {...(props as AutoCompleteProps)}
    />
  );
};
