import classNames from "classnames";
import {format} from "date-fns";
import add from "date-fns/add";
import it from "date-fns/locale/it";
import React, {RefObject, useRef, VFC} from "react";
import styles from "./Scheduler.module.scss";
import {
  isNowCell,
  titleFormat,
  titleFormatShort,
  unitDuration,
  unitFormat,
  unitFormatShort,
} from "./shared";
import {Scale} from "./types";

interface SchedulerHeadingProps {
  startDate: Date;
  endDate: Date;
  scale: Scale;
  mainGridRef: RefObject<HTMLDivElement>;
}

interface HeadingLabelProps {
  weight: number;
  labels: string | {length: number; label: string}[];
  mainGridRef: RefObject<HTMLDivElement>;
  className?: string;
}
const HeadingLabel: VFC<HeadingLabelProps> = ({
  weight,
  labels,
  mainGridRef,
  className,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const deltaLeft = Math.max(
    -1,
    (mainGridRef.current?.getBoundingClientRect().left ?? 0) -
      (ref.current?.getBoundingClientRect().left ?? 0)
  );
  const deltaRight = Math.max(
    0,
    (ref.current?.getBoundingClientRect().right ?? 0) -
      (mainGridRef.current?.getBoundingClientRect().right ?? 0)
  );
  const realWidth =
    (ref.current?.getBoundingClientRect().width ?? 0) - deltaLeft - deltaRight;

  const realLabel =
    typeof labels === "string"
      ? labels
      : labels.find((label) => realWidth < label.length)?.label;

  return (
    <div
      className={classNames(styles.title, className)}
      style={{gridColumn: `span ${weight}`}}
      ref={ref}
    >
      <div
        style={{
          inset: `0 ${deltaRight}px 0 ${deltaLeft}px`,
        }}
      >
        <strong>{realLabel}</strong>
      </div>
    </div>
  );
};

export const SchedulerHeading: VFC<SchedulerHeadingProps> = ({
  startDate,
  endDate,
  scale,
  mainGridRef,
}) => {
  const timeSpanLabels: {
    label: string;
    labels: {length: number; label: string}[];
    isToday: boolean;
    weight: number;
  }[] = [];
  const headings: {
    label: string;
    labels: {length: number; label: string}[];
    weight: number;
  }[] = [];

  for (
    let date = startDate;
    date < endDate;
    date = add(date, {[unitDuration[scale]]: 1})
  ) {
    const timeSpanLabel = format(date, unitFormat[scale], {locale: it});
    const timeSpanShortLabel = format(date, unitFormatShort[scale], {
      locale: it,
    });

    const currentTimeSpan = headings.find(
      (heading) => heading.label === timeSpanLabel
    );
    if (currentTimeSpan === undefined) {
      timeSpanLabels.push({
        label: timeSpanLabel,
        labels: [
          {length: 120, label: timeSpanShortLabel},
          {length: Infinity, label: timeSpanLabel},
        ],
        isToday: isNowCell[scale](date),
        weight: 1,
      });
    } else {
      currentTimeSpan.weight++;
    }

    const headingLabel = format(date, titleFormat[scale], {locale: it});
    const headingShortLabel = format(date, titleFormatShort[scale], {
      locale: it,
    });

    const currentHeading = headings.find(
      (heading) => heading.label === headingLabel
    );
    if (currentHeading === undefined) {
      headings.push({
        label: headingLabel,
        labels: [
          {length: 200, label: headingShortLabel},
          {length: Infinity, label: headingLabel},
        ],
        weight: 1,
      });
    } else {
      currentHeading.weight++;
    }
  }

  return (
    <>
      {headings.map(({label, weight, labels}) => (
        <HeadingLabel
          key={label}
          weight={weight}
          labels={labels}
          mainGridRef={mainGridRef}
        />
      ))}
      {timeSpanLabels.map(({label, weight, labels, isToday}) => (
        <HeadingLabel
          key={label}
          weight={weight}
          labels={labels}
          mainGridRef={mainGridRef}
          className={isToday ? styles.today : undefined}
        />
      ))}
    </>
  );
};
