import {Resource, ResourceCategory, ResourceId} from "./resource";
import {formatISO, parseISO} from "date-fns";
import {CostCenter} from "../accounting/cost-center";
import {User} from "../users";

export type ReservationId = number;

export interface Reservation {
  id?: ReservationId;
  resourceId?: ResourceId;
  resource: Resource;
  category: ResourceCategory;
  costCenter: CostCenter;

  beginReservation: Date;
  endReservation: Date;

  startedAt?: Date;
  endedAt?: Date;

  state: ResourceReservationState;

  travelledDistance: number;
  optionals: string[];

  user: User;
}

export interface ReservationPayload {
  id?: ReservationId;
  resourceId?: ResourceId;
  resource: Resource;
  category: ResourceCategory;
  costCenter: CostCenter;

  beginReservation: string;
  endReservation: string;

  startedAt?: string;
  endedAt?: string;

  state: ResourceReservationState;

  travelledDistance: number;
  optionals: string[];

  user: User;
}

export const hydrateReservation = (payload?: ReservationPayload) =>
  payload
    ? {
        ...payload,
        beginReservation: parseISO(payload.beginReservation),
        endReservation: parseISO(payload.endReservation),
        startedAt: payload.startedAt ? parseISO(payload.startedAt) : undefined,
        endedAt: payload.endedAt ? parseISO(payload.endedAt) : undefined,
      }
    : ({} as Reservation);

export const dehydrateReservation = (payload: Reservation) =>
  ({
    ...payload,
    beginReservation: formatISO(payload.beginReservation),
    endReservation: formatISO(payload.endReservation),
    startedAt: payload.startedAt ? formatISO(payload.startedAt) : undefined,
    endedAt: payload.endedAt ? formatISO(payload.endedAt) : undefined,
  } as ReservationPayload);

export enum ResourceReservationState {
  InProgress = "IN_PROGRESS",
  Present = "PRESENT",
  Future = "FUTURE",
  Past = "PAST",
  Any = "ANY",
}

export const MINUTES_BETWEEN_RESERVATIONS = 15;
export const RESERVATIONS_MINIMUM_MINUTES = 30;
