import React from "react";
import {FieldHookConfig} from "formik";
import {Col, FormGroup, InputProps, Label} from "reactstrap";

export type InputRowProps = {label: string, forField?: string, colWidth?: number, labelWidth?: number};

export const InputRow: React.FC<InputRowProps> =
  ({label, forField, colWidth= 6, labelWidth = 4, children, ...props}) => {

  return (
    <Col md={colWidth}>
      <FormGroup row className={"field"}>
        <Label sm={labelWidth} for={forField}>
          {label}
        </Label>
        <Col className={"p-fluid"}>
          {children}
        </Col>
      </FormGroup>
    </Col>
  );
};
