import React from "react";
import {useHistory} from "react-router-dom";
import {Card} from "reactstrap";
import {routeConfig} from "../../../helpers/routes";
import {useAppDispatch} from "../../../helpers/store";
import {User} from "../../../services/users";
import {actions as alertActions} from "../../ui/alerts";
import {UserDataForm, UserDataFormValues} from "./forms/user-data-form";
import {useCreateUserMutation} from "../../../services/users/userService";

export const NewUserPage: React.VFC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const {userPage} = routeConfig;

  const [createUser] = useCreateUserMutation();

  const newUser = (values: UserDataFormValues) => createUser(values as User).unwrap()
    .then(id => {
      dispatch(alertActions.infoAlert("Utente creato correttamente correttamente."));
      history.push(userPage.route({id}));
    })
    .catch(error => dispatch(alertActions.errorAlert(`Errore durante la creazione dell'utente: ${error}`)));

  return (
    <Card body>
      <UserDataForm onSubmit={newUser}/>
    </Card>
  );
};
