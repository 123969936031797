import {News} from "./news";
import {api, Tags} from "../api";

const newsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllNews: build.query<News[], void>({
      query: () => ({
        url: `/news`,
        method: 'GET'
      }),
      providesTags: [{type: Tags.news, id: 'LIST'}]
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllNewsQuery
} = newsApi;
