import {
  faBell,
  faCalendarAlt,
  faCar,
  faClock,
  faCoffee,
  faIdBadge,
  faIdCard,
  faLink,
  faUtensils,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {Alert, Button, Card} from "reactstrap";
import {routeConfig} from "../../helpers/routes";
import {getRoles} from "../login/selectors";
import {isAuthorized} from "../ui/layout/shared/isAuthorized";
import styles from "./Home.module.scss";

export const Home: React.FC = () => {
  const userRoles = useSelector(getRoles);

  return (
    <div className={styles.home}>
      <Card body className={styles.alert}>
        <h3 className="mb-3">
          <FontAwesomeIcon icon={faBell}/> Avvisi
        </h3>
        <div className={styles.alertsWrapper}>
          <Alert color="info" fade={false}>
            <h4 className="d-flex">
              <span>Benvenut*!</span>
              <small className="ml-auto">
                <FontAwesomeIcon icon={faClock}/> 1 febbraio 2021
              </small>
            </h4>
            <p className="mb-0">
              Il nuovo gestionale è online!
              Ti ricordiamo di cambiare la tua password personale al primo accesso
              (Menu in alto a destra, Sez. Profilo - Cambio Password).
              Con questa applicazione potrai prenotare i pasti in mensa presso il Calabrone.
              Buona navigazione
            </p>
          </Alert>
        </div>
        <div className="mt-3">
          <NavLink to={routeConfig["news"].path}>Vedi tutti</NavLink>
        </div>
      </Card>
      {isAuthorized(routeConfig["usersList"].roles, userRoles) ? (
        <Button
          tag={NavLink}
          to={routeConfig["usersList"].path}
          color="dark"
          outline
          className={styles.user}
        >
          <h3 className="mb-3">Utenti</h3>
          <FontAwesomeIcon size="4x" icon={faIdCard}/>
        </Button>
      ) : (
        <Button
          tag={NavLink}
          to={routeConfig["userPersonalPage"].path}
          color="dark"
          outline
          className={styles.user}
        >
          <h3 className="mb-3">Profilo</h3>
          <FontAwesomeIcon size="4x" icon={faIdBadge}/>
        </Button>
      )}
      <Button
        tag={NavLink}
        to={routeConfig["mealReservation"].path}
        color="dark"
        outline
        className={styles.meals}
      >
        <h3 className="mb-3">Pasti</h3>
        <FontAwesomeIcon size="4x" icon={faUtensils}/>
      </Button>
      <Button
        tag={NavLink}
        to={routeConfig["manageWork"].path}
        color="dark"
        outline
        className={styles.manWork}
        disabled
      >
        <h3 className="mb-3">Ore lavoro</h3>
        <FontAwesomeIcon size="4x" icon={faCalendarAlt}/>
      </Button>
      <Button
        tag={NavLink}
        to={routeConfig.reserveResource.route()}
        color="dark"
        outline
        className={styles.resources}
      >
        <h3 className="mb-3">Risorse</h3>
        <FontAwesomeIcon size="4x" icon={faCar}/>
      </Button>
      <Card body className={styles.link}>
        <h3 className="mb-4">
          <FontAwesomeIcon icon={faLink} className="mr-2"/> Collegamenti utili
        </h3>
        <div className="w-100">
          <br/><br/><br/>
          {/*  <Row>*/}
          {/*    <Col xs="auto">*/}
          {/*      <span>*/}
          {/*        <FontAwesomeIcon fixedWidth icon={faPhone} className="mr-2" />*/}
          {/*        <strong>3281743792</strong> Tecnico*/}
          {/*      </span>*/}
          {/*      <br />*/}
          {/*      <span>*/}
          {/*        <FontAwesomeIcon fixedWidth icon={faPhone} className="mr-2" />*/}
          {/*        <strong>3204852837</strong> Consulente personale*/}
          {/*      </span>*/}
          {/*      <br />*/}
          {/*      <span>*/}
          {/*        <FontAwesomeIcon fixedWidth icon={faPhone} className="mr-2" />*/}
          {/*        <strong>3374637283</strong> Dottore*/}
          {/*      </span>*/}
          {/*    </Col>*/}
          {/*    <Col xs="auto">*/}
          {/*      <span>*/}
          {/*        <FontAwesomeIcon*/}
          {/*          fixedWidth*/}
          {/*          icon={faEnvelope}*/}
          {/*          className="mr-2"*/}
          {/*        />*/}
          {/*        <strong>email@comune.brescia.it</strong> contatto Comune Bs*/}
          {/*      </span>*/}
          {/*      <br />*/}
          {/*      <span>*/}
          {/*        <FontAwesomeIcon*/}
          {/*          fixedWidth*/}
          {/*          icon={faEnvelope}*/}
          {/*          className="mr-2"*/}
          {/*        />*/}
          {/*        <strong>amministrazione@calabrone.org</strong> amministrazione*/}
          {/*      </span>*/}
          {/*      <br />*/}
          {/*      <span>*/}
          {/*        <FontAwesomeIcon*/}
          {/*          fixedWidth*/}
          {/*          icon={faEnvelope}*/}
          {/*          className="mr-2"*/}
          {/*        />*/}
          {/*        <strong>info@jeflab.it</strong> Consulenti informatici*/}
          {/*      </span>*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</div>*/}
          {/*<div className="mt-auto">*/}
          {/*  <NavLink to={routeConfig["userSettings"].path}>Vedi tutti</NavLink>*/}
        </div>
      </Card>
      <Card body className={styles.notes}>
        <h3 className="mb-4">
          <FontAwesomeIcon icon={faCoffee} className="mr-2"/> Note personali
        </h3>
        <div className="w-100">
          <p>
            <br/><br/><br/>
            {/*    <FontAwesomeIcon fixedWidth icon={faStickyNote} /> Ricordarsi le*/}
            {/*    chiavi Piastra Pendolina*/}
            {/*  </p>*/}
            {/*  <p>*/}
            {/*    <FontAwesomeIcon fixedWidth icon={faStickyNote} /> Ogni Martedì*/}
            {/*    mattina h 11:00 riunione equipe - stanza don piero*/}
          </p>
        </div>
        {/*<div className="mt-auto">*/}
        {/*  <NavLink to={routeConfig["userSettings"].path}>Vedi tutti</NavLink>*/}
        {/*</div>*/}
      </Card>
    </div>
  );
};
