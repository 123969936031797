import {faOutdent as closeSidebarIcon} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import csx from "classnames";
import React from "react";
import {connect, ConnectedProps} from "react-redux";
import {Button} from "reactstrap";
import {AppDispatch, RootState} from "../../../../helpers/store";
import navbarStyles from "../nav-bar/NavBar.module.scss";
import styles from "./SidePanel.module.scss";

const stateToProps = (state: RootState, props: any) => ({
  isOpen: false, // makeIsOpenSidePanelSelector()(state, props),
});

const dispatchToProps = (dispatch: AppDispatch) => ({
  closeSidePanel: () => {
    // dispatch(sidePanelActions.close());
    // dispatch(menuActions.closeAll());
  },
});

const connector = connect(stateToProps, dispatchToProps);

export type SidePanelProps = ConnectedProps<typeof connector>;

const _SidePanel: React.FunctionComponent<SidePanelProps> = ({
                                                               isOpen,
                                                               closeSidePanel,
                                                               children,
                                                             }) => {
  return (
    <div
      className={csx("bg-dark text-light h-100", styles.sidePanel, {
        [styles.sidePanelOpen]: isOpen,
        [styles.sidePanelClose]: !isOpen,
      })}
    >
      <div className={styles.innerCloseButton}>
        <Button
          color={"primary"}
          className={navbarStyles.toggleSidePanelButton}
          onClick={closeSidePanel}
        >
          <FontAwesomeIcon icon={closeSidebarIcon}/>
        </Button>
      </div>
      {children}
    </div>
  );
};

export const SidePanel = connector(_SidePanel);
