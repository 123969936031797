import {useResizeObserver} from "beautiful-react-hooks";
import {differenceInMinutes, endOfDay} from "date-fns";
import React, {
  CSSProperties,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./SchedulerEvent.module.scss";
import {minuteInAScale} from "./shared";
import {Event, Scale} from "./types";
import {Resource} from "../../../services/resources/resource";

interface EventsProps<E> {
  cellWidth: number;
  event: Event<E>;
  onEventClick?: (event: Event<E>) => void;
  resources: Resource[];
  schedulerWidth: number;
  startDate: Date;
  endDate: Date;
  scale: Scale;
}

const useIsEllipseActive: <T extends HTMLElement>(
  elementRef: RefObject<T>,
  debounceTimeout?: number
) => boolean = (elementRef, debounceTimeout) => {
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const DOMRect = useResizeObserver(elementRef);

  useEffect(() => {
    setIsEllipsisActive(
      !!elementRef.current &&
        elementRef.current.offsetWidth < elementRef.current.scrollWidth
    );
  }, [DOMRect?.width, elementRef]);

  return isEllipsisActive;
};

export const SchedulerEvent = <E,>({
  cellWidth,
  event,
  onEventClick,
  resources,
  scale,
  schedulerWidth,
  startDate,
  endDate
}: EventsProps<E>) => {
  const labelRef = useRef<HTMLDivElement>(null);
  const [, setNaturalWidth] = useState<number>();

  const eventLeft =
    (differenceInMinutes(event.start, startDate) / minuteInAScale[scale]) *
    cellWidth;
  const eventWidth = Math.min(
    (differenceInMinutes(event.end, event.start) / minuteInAScale[scale]) * cellWidth,
           (differenceInMinutes(endDate, event.start) / minuteInAScale[scale]) * cellWidth,
    );
  const eventTop =
    70 +
    resources.findIndex((resource) => resource.id === event.resourceId) * 35;

  const labelNaturalWidth = (labelRef.current?.scrollWidth ?? 0) + 14;
  const spaceNeeded = (labelNaturalWidth - 4 - eventWidth) / 2; // -4 per aggiustare il left
  const translateLeft = Math.max(0, spaceNeeded - eventLeft);
  const translateRight = Math.min(
    0,
    schedulerWidth - eventLeft - eventWidth - Math.max(0, spaceNeeded) - 1 // -1 per aggiustare il right
  );

  const eventStyle = {
    width: eventWidth,
    top: eventTop,
    left: eventLeft,
    "--translate-needed": `${translateLeft + translateRight}px`,
    "--label-natural-width": `${labelNaturalWidth}px`,
    "--event-color": event.color,
  } as CSSProperties;

  useEffect(() => {
    // Anti-pattern!
    // serve a garantire un secondo render con il ref non a null
    // non ho trovato un metodo migliore
    setNaturalWidth(labelRef.current?.scrollWidth);
  }, []);

  return (
    <button className={styles.event} key={event.id} style={eventStyle}>
      <div id={`event-${event.id}`} onClick={() => onEventClick?.(event)}>
        <div ref={labelRef}>{event.label}</div>
      </div>
    </button>
  );
};
