import {api, Tags} from "../api";
import {CostCenter, CostClass} from "./cost-center";

const costCenterApi = api.injectEndpoints({

  endpoints: (build) => ({

    getCostCenters: build.query<CostCenter[], {activeOnly: boolean, costClass: CostClass}>({
        query: ({activeOnly, costClass}) => ({
          url: `/accounting/costCenters?activeOnly=${activeOnly}&costClass=${costClass}`,
          method: 'GET'
        }),
        providesTags: [{type: Tags.costCenters, id: 'LIST'}]
      },
    ),

  })

});

export const {
  useGetCostCentersQuery, useLazyGetCostCentersQuery
} = costCenterApi;
