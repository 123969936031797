import React from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Card} from "reactstrap";
import {useAppDispatch} from "../../../helpers/store";
import {DbRole, Role} from "../../../services/authentication/userAuthentication";
import {User} from "../../../services/users";
import {actions as alertActions} from "../../ui/alerts";
import {
  useCreateUserAccountMutation,
  useGetUserQuery, useUpdateDbRoleMutation,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation, useUpdateUserRolesMutation
} from "../../../services/users/userService";
import {canChangeRolesSelector} from "../../login/selectors";
import {
  UserAccountForm,
  NewAccountFormValues,
  UserUpdatePasswordForm,
  UserUpdateRolesForm, RolesFormValues, PasswordFormValues
} from "./forms/user-account-form";
import {UserDataForm, UserDataFormValues} from "./forms/user-data-form";
import {DbRoleForm, DbRoleFormValues} from "./forms/user-mysql-role-form";

export type UserPageProps = {};

export const UserPage: React.VFC<UserPageProps> = () => {
  const {id} = useParams<{ id: string }>();
  const idLoad = Number(id);

  const dispatch = useAppDispatch();

  const canModifyRoles = useSelector(canChangeRolesSelector);

  const {data: user} = useGetUserQuery(idLoad);

  const [updateUser] = useUpdateUserMutation();
  const [updatePassword] = useUpdateUserPasswordMutation();
  const [createAccount] = useCreateUserAccountMutation();
  const [updateRoles] = useUpdateUserRolesMutation();
  const [updateDbRole] = useUpdateDbRoleMutation();

  const fromStringToRoles = (stringRoles: string[]) => stringRoles
    .map(stringRole => Object.values(Role).find(role => role === stringRole) as Role);

  const fromStringToDbRole = (stringDbRole: string) => Object.values(DbRole).find(role => role === stringDbRole) as DbRole;

  const updateUserData = (values: UserDataFormValues) => user && updateUser({...values, id: user.id} as User).unwrap()
      .then(() => dispatch(alertActions.successAlert("Dati utente modificati con successo")))
      .catch(error => dispatch(alertActions.errorAlert(`Errore durante la modifica dei dati utente: ${error}`)));

  const createUserAccount = ({password, roles}: NewAccountFormValues) =>
    user?.id && createAccount({password, roles: fromStringToRoles(roles), id: user.id!}).unwrap()
      .then(() => dispatch(alertActions.successAlert("Account creato con successo")))
      .catch(error => dispatch(alertActions.errorAlert(`Errore durante la creazione dell'account: ${error.data.message}`)));

  const changePassword = ({password}: PasswordFormValues) =>
    user && updatePassword({password, id: user.id!}).unwrap()
      .then(() => dispatch(alertActions.successAlert("Password modificata con successo")))
      .catch(error => dispatch(alertActions.errorAlert(`Errore durante la modifica della password: ${error.data.message}`)));

  const changeRoles = ({roles}: RolesFormValues) => {
    user && updateRoles({roles: fromStringToRoles(roles), id: user.id!}).unwrap()
      .then(() => dispatch(alertActions.successAlert("Ruolo utente con successo")))
      .catch(error => dispatch(alertActions.errorAlert(`Errore durante la modifica del ruolo utente: ${error.data.message}`)));
  }

  const changeDbRole = ({role}: DbRoleFormValues) => {
    user && updateDbRole({role: fromStringToDbRole(role), id: user.id!}).unwrap()
      .then(() => dispatch(alertActions.successAlert("Ruolo in CalabroneDati impostato con successo")))
      .catch(error => dispatch(alertActions.errorAlert(`Errore durante la modifica del ruolo in CalabroneDati: ${error.data.message}`)));
  }

  return (
    <Card body>
      {user ? <UserDataForm onSubmit={updateUserData} user={user}/> : null}
      {!!(user?.roles)
        ? <>
            <UserUpdatePasswordForm onSubmit={changePassword}/>
            {canModifyRoles ? <UserUpdateRolesForm onSubmit={changeRoles} roles={user.roles}/> : null}
            {canModifyRoles ? <DbRoleForm onSubmit={changeDbRole} role={user.dbRole}/> : null}
          </>
        : <UserAccountForm onSubmit={createUserAccount}/>
      }
    </Card>
  );
};
