import {api, Page, PageRequest, ServerPage, Tags} from "../api";
import {
  dehydrateReservation,
  Reservation,
  ReservationId,
  ReservationPayload,
  ResourceReservationState,
} from "./reservation";
import {ResourceId} from "./resource";

export type ReservationsPage = {
  reservations: ReservationPayload[];
} & Page;

export type ReservationPageRequest = PageRequest & {
  states: ResourceReservationState[];
};

export const isoWithMilliseconds = (date: string) => {
  const parts = date.split("+");
  return `${parts[0]}.000+${parts[1]}Z`;
};

const reservationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getReservationsPage: build.query<ReservationsPage, ReservationPageRequest>({
      query: ({pageNumber, pageSize, sort, states}) => ({
        url:
          `/resourceReservations/page?${states
            .map((s) => `states=${s}`)
            .join("&")}&page=${pageNumber}&size=${pageSize}&` +
          sort.map((s) => `sort=${s.field},${s.order}`).join("&"),
        method: "GET",
      }),
      providesTags: [{type: Tags.reservations, id: "LIST"}],
      transformResponse: (response: ServerPage<ReservationPayload>) =>
        ({
          reservations: response.content,
          pageNumber: response.number,
          pageSize: response.size,
          total: response.totalElements,
        } as ReservationsPage),
    }),

    getCalendar: build.query<
      ReservationPayload[],
      {from?: string; to?: string}
    >({
      query: ({from, to}) => {
        const params = new URLSearchParams();
        if (from) params.append("from", from);
        if (to) params.append("to", to);

        return {
          url: `/resourceReservations/calendar?${params}`,
          method: "GET",
        };
      },
      providesTags: [{type: Tags.reservations, id: "LIST"}],
    }),

    createResourceReservation: build.mutation<ReservationId, Reservation>({
      query: (reservation) => ({
        url: `/resourceReservations/`,
        method: "POST",
        body: dehydrateReservation(reservation),
      }),
      invalidatesTags: [{type: Tags.reservations, id: "LIST"}],
    }),

    startResourceReservation: build.mutation<
      void,
      {reservation: Reservation; distance: number}
    >({
      query: ({reservation, distance}) => ({
        url: `/resourceReservations/${reservation.id}/start`,
        method: "PUT",
        body: {distance},
      }),
      invalidatesTags: (_result, _error, request) => [
        {type: Tags.reservations, id: request.reservation.id},
        {type: Tags.resources, id: request.reservation.resource.id},
        {type: Tags.reservations, id: "LIST"},
        {type: Tags.resources, id: "LIST"},
      ],
    }),

    endResourceReservation: build.mutation<
      void,
      {reservation: Reservation; distance: number; message?: string}
    >({
      query: ({reservation, distance, message}) => ({
        url: `/resourceReservations/${reservation.id}/end`,
        method: "PUT",
        body: {distance, message},
      }),
      invalidatesTags: (_result, _error, request) => [
        {type: Tags.reservations, id: request.reservation.id},
        {type: Tags.resources, id: request.reservation.resource.id},
        {type: Tags.reservations, id: "LIST"},
        {type: Tags.resources, id: "LIST"},
      ],
    }),

    getResourceReservation: build.query<ReservationPayload, ReservationId>({
      query: (id) => ({
        url: `/resourceReservations/${id}`,
        method: "GET",
      }),
      providesTags: (_response, _error, id) => [{type: Tags.reservations, id}],
    }),

    getActiveResourceReservation: build.query<ReservationPayload, ResourceId>({
      query: (resourceId) => ({
        url: `/resourceReservations/active?resourceId=${resourceId}`,
        method: "GET",
      }),
      providesTags: (response, _error, _resourceId) =>
        response ? [{type: Tags.reservations, id: response?.id}] : [],
    }),

    deleteResourceReservation: build.mutation<void, ReservationId>({
      query: (id) => ({
        url: `/resourceReservations/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, id) => [
        {type: Tags.reservations, id},
        {type: Tags.reservations, id: "LIST"},
      ],
    }),
  }),
});

export const {
  useGetReservationsPageQuery,
  useCreateResourceReservationMutation,
  useGetResourceReservationQuery,
  useDeleteResourceReservationMutation,
  useStartResourceReservationMutation,
  useEndResourceReservationMutation,
  useGetActiveResourceReservationQuery,
  useGetCalendarQuery,
  useLazyGetCalendarQuery,
} = reservationsApi;
