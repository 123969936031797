import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../../helpers/store";
import {SubmenuKey} from "./types";

interface LayoutState {
  isNavOpen: boolean;
  submenuOpen: SubmenuKey | null;
}

const layoutSlice = createSlice({
  name: "layout",
  initialState: {isNavOpen: false, submenuOpen: null} as LayoutState,
  reducers: {
    openNav: (state) => {
      state.isNavOpen = true;
    },
    closeNav: (state) => {
      state.isNavOpen = false;
    },
    toggleNav: (state) => {
      state.isNavOpen = !state.isNavOpen;
    },
    openSubmenu: (state, action: PayloadAction<{ key: SubmenuKey }>) => {
      state.submenuOpen = action.payload.key;
    },
    closeSubmenu: (state) => {
      state.submenuOpen = null;
    },
  },
});

export const reducer = layoutSlice.reducer;
export const actions = layoutSlice.actions;
export const selectors = {
  selectIsNavOpen: (state: RootState) => state.layout.isNavOpen,
  selectSubmenuOpen: (state: RootState) => state.layout.submenuOpen,
};
