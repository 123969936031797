import React, {ChangeEvent, useEffect, useState} from "react";
import {Button, Card, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {PageTitle} from "../../ui/layout/PageTitle";
import {ReservationFormValues, ResourceReservationForm} from "./forms/reservation-form";
import {useLazySearchResourceQuery,} from "../../../services/resources/resources-api";
import {Resource} from "../../../services/resources/resource";
import {useCreateResourceReservationMutation} from "../../../services/resources/reservations-api";
import {useAppDispatch} from "../../../helpers/store";
import {errorAlert, successAlert} from "../../ui/alerts/reducer";
import {useHistory} from "react-router-dom";
import {routeConfig} from "../../../helpers/routes";


const useConfirmReservation = () => {
  const [create] = useCreateResourceReservationMutation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {resourceReservationDetails} = routeConfig;

  return (reservation: ReservationFormValues, resource: Resource) =>
    create({...reservation, resource}).unwrap()
      .then(id => {
        dispatch(successAlert('Prenotazione completata con successo'));
        history.push(resourceReservationDetails.route({id}));
      })
      .catch(error => dispatch(errorAlert(`Non è stato possibile completare la prenotazione: ${error.message}`)));
};


export const NewResourceReservation: React.VFC = () => {

  const [search, {data: resources}] = useLazySearchResourceQuery();
  const [modal, setModal] = useState(false);
  const [selectedResource, setSelectedresource] = useState<Resource>();
  const [reservation, setReservation] = useState<ReservationFormValues>();

  const toggle = () => setModal(!modal);

  const reserveResource = useConfirmReservation();

  const searchResource = (values: ReservationFormValues) => {
    setReservation(values);
    search({
      from: values.beginReservation,
      to: values.endReservation,
      category: values.category,
      distance: values.travelledDistance,
      optionals: values.optionals
    });
    setModal(true);
  };

  useEffect(() => {
    resources && resources.length > 0 && setSelectedresource(resources[0])
  }, [resources, setSelectedresource]);

  return (
    <>
      <Card body>
        <PageTitle>Nuova prenotazione</PageTitle>
        <ResourceReservationForm onSubmit={searchResource}/>
      </Card>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Conferma prenotazione</ModalHeader>
        <ModalBody>
          {resources && resources.length > 0
            ? <><p>La risorsa che hai selezionato è la seguente:</p>
              <Input type={'select'} name={'resource'}
                     value={selectedResource?.id}
                     onChange={
                       (e: ChangeEvent<HTMLInputElement>) =>
                         setSelectedresource(resources?.filter(r => r.id === Number(e.target.value))[0])
                     }>
                {resources.map((resource: Resource) => <option key={resource.id} value={resource.id}>
                  {resource.name} ({resource.licensePlate}) - {resource.powerSource}
                </option>)}
              </Input></>
            : <div>
              Nessuna risorsa disponibile corrisponde ai criteri di ricerca.
            </div>}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>Annulla</Button>{' '}
          <Button color="primary" onClick={() => reserveResource(reservation!, selectedResource!)}>Prenota</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
