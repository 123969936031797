import {Role} from "../../../services/authentication/userAuthentication";
import {useSelector} from "react-redux";
import {currentUser} from "../../login/selectors";
import {useAuthorized} from "./logic";

export type RestrictProps = {
  requiredRoles?: Role[];
};

export const Guard: React.FC<RestrictProps> =
  ({requiredRoles, children}) => {
    const user = useSelector(currentUser);
    return (<>{useAuthorized(requiredRoles, user?.roles) ? children : null}</>);
  }
