import React, {FC} from "react";
import {Card} from "reactstrap";
import {PageTitle} from "../ui/layout/PageTitle";

export const ManageWork: FC = () => {
  return (
    <Card body style={{height: "100%"}}>
      <PageTitle>Ore lavoro</PageTitle>
      <p className="card-text">
        Sed pharetra quis lacus et maximus. Suspendisse nulla sem, tempor sit
        amet dolor quis, dapibus faucibus orci. Quisque sodales nulla mauris,
        sit amet semper ex rutrum id. Sed quis imperdiet risus. Maecenas
        placerat malesuada vehicula. Cras pharetra vel purus in imperdiet.
        Integer nibh neque, ultrices eget nisi sed, dignissim euismod magna.
      </p>
    </Card>
  );
};
