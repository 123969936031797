import {UserAuthentication} from "../services/authentication/userAuthentication";

export const storeRefreshToken = (refreshToken: string) => {
  document.cookie = `refreshToken=${refreshToken}`;
};

export const getRefreshToken = () => {
  return (document.cookie.match(/refreshToken=([^;]*)/) ?? ['', ''])[1] ?? '';
};

export const clearRefreshToken = () => {
  document.cookie = `refreshToken=`;
};

export const storeUser = (user: UserAuthentication) => {
  localStorage.setItem('loggedUser', JSON.stringify(user));
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem('loggedUser') ?? '{}') as UserAuthentication;
};

export const clear = () => {
  localStorage.clear();
  clearRefreshToken();
}
