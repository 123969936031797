import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import React, {useState} from "react";
import {Reservation} from "../../../services/resources/reservation";

export type StartReservationDialogProps = {
  reservation: Reservation;
  onCancel: () => void;
  onConfirm: (distance: number) => void;
};

export const StartReservationDialog: React.VFC<StartReservationDialogProps> = ({
  reservation,
  onCancel,
  onConfirm,
}) => {
  const [distance, setDistance] = useState<number>(reservation.resource.km);
  const Footer = () => (
    <div>
      <Button
        label="Annulla"
        icon="pi pi-times"
        onClick={onCancel}
        className="p-button-danger"
      />
      <Button
        label="Conferma"
        icon="pi pi-check"
        onClick={() => onConfirm(distance)}
        className="p-button-success"
      />
    </div>
  );

  return (
    <Dialog
      header="Apertura della corsa"
      visible={true}
      onHide={onCancel}
      footer={Footer}
    >
      <p>Conferma i Km del veicolo {reservation.resource.name}:</p>
      <InputText
        type={"number"}
        value={distance}
        onChange={(e) => setDistance(Number(e.target.value))}
      />
    </Dialog>
  );
};
