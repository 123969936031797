import classNames from "classnames";
import React, {VFC} from "react";
import styles from "./NavbarToggler.module.scss";

interface NavbarTogglerProps {
  isOpen: boolean;
  onClick: () => void;
}

export const NavbarToggler: VFC<NavbarTogglerProps> = ({isOpen, onClick}) => {
  return (
    <button
      aria-label="Toggle navigation"
      className={classNames(styles.navToggler, {[styles.isOpen]: isOpen})}
      onClick={onClick}
      type="button"
    >
      Menu
      <span/>
    </button>
  );
};
