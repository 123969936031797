import React, {FC} from "react";
import layoutStyles from "./Layout.module.scss";
import classNames from "classnames";

interface SectionTitleProps {
  mt?: boolean;
}

export const SectionTitle: FC<SectionTitleProps> = ({children, mt}) => {
  return (
    <h4 className={classNames(layoutStyles.pageTitle, {"mt-4": mt})}>
      {children}
    </h4>
  );
};
